import {createAsyncThunk} from '@reduxjs/toolkit';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {CreateLocationDTO, LocationClient, LocationDTO} from '../../../api/api';
import {locationClient} from '../../api-clients';

export const getLocations = createAsyncThunk('locations/getLocations', (organizationId: number) => {
  return AsyncOperationHandler(
    (client) => (client as LocationClient).getByOrganizationId(organizationId),
    locationClient,
  );
});

export const updateLocation = createAsyncThunk(
  'locations/updateLocation',
  (payload: {location: LocationDTO; callbackOnSuccess?: (result: LocationDTO) => void}) => {
    return AsyncOperationHandler((client) => (client as LocationClient).put(payload.location), locationClient, {
      showResponseMessage: true,
      callbackOnSuccess: payload.callbackOnSuccess,
    });
  },
);

export const saveLocation = createAsyncThunk(
  'locations/saveLocations',
  (payload: {location: CreateLocationDTO; callbackOnSuccess?: (result: LocationDTO) => void}) => {
    return AsyncOperationHandler((client) => (client as LocationClient).post(payload.location), locationClient, {
      showResponseMessage: true,
      callbackOnSuccess: payload.callbackOnSuccess,
    });
  },
);

export const deleteLocation = createAsyncThunk(
  'locations/deleteLocation',
  (payload: {location: LocationDTO; callbackOnSuccess?: () => void}) => {
    return AsyncOperationHandler((client) => (client as LocationClient).delete(payload.location), locationClient, {
      showResponseMessage: true,
      callbackOnSuccess: payload.callbackOnSuccess,
    });
  },
);
