import React, {FC, memo, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ApartmentDTO, IApartmentDTO} from 'src/api/api';
import {updateApartment} from 'src/state/features/apartment/operation';
import {AppState} from 'src/state/store';
import {secondaryColor, whiteColor} from 'src/styles/color-constants';
import {DropdownOption} from 'src/view/components/default-components/baisc-dropdown/option-dropdown';
import {TxBasicSelect} from 'src/view/components/default-components/baisc-dropdown/tx-basic-select';

interface Props {
  apartment: IApartmentDTO;
}

const inputContainer = {
  color: secondaryColor,
  width: '150px',
  padding: 1.5,
  boxShadow: '0px 3px 15px #0000001A',
  fontWeight: 'bold',
  backgroundColor: whiteColor,
};

export const ApartmentSelectNotificationSetting: FC<Props> = memo((props) => {
  const dispatch = useDispatch();
  const notificationSettings = useSelector((store: AppState) => store.notificationSettingReducer.notificationSettings);
  const [selected, setSelected] = useState<number | undefined>(props.apartment.notificationSettingId);
  const options = useMemo(() => {
    let o = notificationSettings.map((ns, index) => new DropdownOption(ns.name ?? index.toString(), ns.id));
    o.push(new DropdownOption(' ', null));
    return o;
  }, [notificationSettings]);

  useEffect(() => {
    setSelected(props.apartment.notificationSettingId);
  }, [props.apartment]);

  const setNotificationSetting = (value: number) => {
    setSelected(value);
    dispatch(updateApartment({apartment: {...props.apartment, notificationSettingId: value} as ApartmentDTO}));
  };

  return notificationSettings.length !== 0 ? (
    <TxBasicSelect onChange={setNotificationSetting} value={selected} sxContainer={inputContainer} options={options} />
  ) : (
    <></>
  );
});
