import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {INotificationRangeDTO, NotificationRangeDTO} from 'src/api/api';
import {localized, localizedInterpolation} from 'src/i18n/i18n';
import {createNotificationRange} from 'src/state/features/notification-setting/operation';
import {setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {AppState} from 'src/state/store';
import {theme} from 'src/styles/theme';
import {getMeasureTypeOptions} from 'src/utilities/platform-helpers/enum-helper';
import {nameof} from 'src/utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'src/utilities/platform-helpers/validator-helper';
import {Form} from 'src/view/components/default-components/form/form';
import {SidePanelButton} from 'src/view/components/default-components/side-panel/side-panel-button';
import {SidePanelSelectInput} from 'src/view/components/default-components/side-panel/side-panel-select-input';
import {SidePanelTitel} from 'src/view/components/default-components/side-panel/side-panel-titel';

interface PropsFromParent {
  notificationSettingId: number;
}

export const CreateNotificationRange: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();

  const savePending = useSelector((store: AppState) => store.notificationSettingReducer.pending);
  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);
  const save = useCallback(
    (range: NotificationRangeDTO) => {
      dispatch(createNotificationRange({notificationRange: range, callbackOnSuccess: closeSidePanel}));
    },
    [closeSidePanel, dispatch],
  );
  return (
    <>
      <SidePanelTitel
        title={localizedInterpolation('CreateNewInt', {area: localized('NotificationProfile').toLowerCase()})}
      />
      <Form
        onSubmit={save}
        sx={{maxWidth: 400, marginTop: theme.spacing(3)}}
        defaultValues={{notificationSettingId: props.notificationSettingId}}>
        <SidePanelSelectInput
          name={nameof<INotificationRangeDTO>('measureType')}
          noDefault
          options={getMeasureTypeOptions()}
          validators={requiredValidator}
          label={localized('ChooseMeasureType')}
        />

        <SidePanelButton
          type="submit"
          buttonColor={'primary'}
          disabled={savePending}
          text={localized('SaveAndClose')}
        />
      </Form>
    </>
  );
});
