import {Theme} from '@emotion/react';
import {Typography} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import React, {FC, MouseEvent, useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ApartmentDTO} from 'src/api/api';
import {useAuth} from 'src/auth/auth-provider';
import {localized, localizedInterpolation} from 'src/i18n/i18n';
import {selectApartmentById} from 'src/state/features/apartment/apartment-slice';
import {deleteApartment} from 'src/state/features/apartment/operation';
import {selectThermexUnitsByApartmentId} from 'src/state/features/thermex-unit/thermex-unit-slice';
import {applySidePanelContent, setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {FlexColumn} from 'src/view/components/default-components/flex-column';
import {StyledPopoverItem, TxStyledPopover} from 'src/view/components/popover/tx-styled-popover';
import {ReactComponent as WrenchIcon} from '../../../../../assets/wrench_logo.svg';
import {selectAlarmsByApartmentId} from '../../../../../state/features/alarms/alarms-slice';
import {routes} from '../../../../../state/routes';
import {theme} from '../../../../../styles/theme';
import {ReactComponent as MoreDetails} from '../../../../../assets/more_details_dots.svg';
import {BasicSvgIcon} from '../../../../components/default-components/basic-svg-icon';
import {FlexRow} from '../../../../components/default-components/flex-row';
import {ListTableItem} from '../../../../components/default-components/list-table/list-table-item';
import {StatusIconBySeverity} from '../../../../components/icons/status-icon';
import {ApartmentEdit} from '../apartment-edit/apartment-edit';

interface PropsFromParent {
  apartmentId: number;
  isSelected?: boolean;
  columnSizes?: number[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unitTypes: {
      marginLeft: 10,
    },
    noDevices: {
      opacity: 0.5,
    },
  }),
);

export const ApartmentListItem: FC<PropsFromParent> = React.memo(({apartmentId, isSelected, ...props}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const item = useSelector(selectApartmentById(apartmentId));
  const thermexUnits = useSelector(selectThermexUnitsByApartmentId(apartmentId));
  const alarms = useSelector(selectAlarmsByApartmentId(apartmentId));
  const classes = useStyles();
  const {isAdmin} = useAuth();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorElRef = useRef<Element | null>();

  const goToLocationDetail = useCallback(
    () => {
      if (item) {
        navigate(`${routes.locations}/${item.locationId}/detail/${item.id}`);
      }
    }, // eslint-disable-next-line
    [item],
  );

  const editApartment = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsPopoverOpen(false);
      anchorElRef.current = e.currentTarget;

      // Display the location editor in a side bar
      if (item) {
        dispatch(applySidePanelContent(<ApartmentEdit aparmentId={item.id} />));
        dispatch(setSidePanelOpen(true));
      }
    },
    [dispatch, item],
  );

  const deleteApartmentPressed = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (
        (await confirm(
          localizedInterpolation('AreYouSureYouWantToDelete', {
            area: localized('TheApartment').toLowerCase(),
          }),
        )) &&
        item
      ) {
        setIsPopoverOpen(false);
        anchorElRef.current = e.currentTarget;
        dispatch(deleteApartment({apartment: item as ApartmentDTO}));
      }
    },
    [dispatch, item],
  );

  const togglePopover = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      anchorElRef.current = e.currentTarget;

      setIsPopoverOpen(!isPopoverOpen);
    },
    [isPopoverOpen],
  );

  const popoverItems = useMemo(
    () => (
      <FlexColumn>
        <StyledPopoverItem onClick={editApartment}>{localized('Edit')}</StyledPopoverItem>
        <StyledPopoverItem onClick={deleteApartmentPressed}>{localized('Delete')}</StyledPopoverItem>
      </FlexColumn>
    ),
    [deleteApartmentPressed, editApartment],
  );

  const unitTypes = useMemo(() => {
    return thermexUnits
      ?.map((unit) => unit.name!)
      .sort((a, b) => a.localeCompare(b))
      .join(', ');
  }, [thermexUnits]);

  // If unable to find element, don't show anything
  if (!item) {
    return null;
  }

  return (
    <>
      <ListTableItem
        {...props}
        maxWidth={[undefined, undefined, undefined, 20]}
        itemSelected={goToLocationDetail}
        contentItem={item}
        showSelected={isSelected}
        objectValues={[
          <StatusIconBySeverity severityLevel={item.severityLevel} />,
          <Typography fontWeight="bold" fontSize={theme.typography.body2.fontSize}>
            {item.name}
          </Typography>,
          thermexUnits.length > 0 ? (
            <FlexRow alignItems="center">
              <BasicSvgIcon component={WrenchIcon} />
              <span className={classes.unitTypes}>{unitTypes}</span>
            </FlexRow>
          ) : (
            <span className={classes.noDevices}>{localized('NoDevices')}</span>
          ),
          isAdmin && <BasicSvgIcon stroke={''} component={MoreDetails} onClick={togglePopover} />,
        ]}
      />
      <TxStyledPopover popoverStatus={isPopoverOpen} togglePopover={togglePopover} anchorEl={anchorElRef}>
        {popoverItems}
      </TxStyledPopover>
    </>
  );
});
