import {createSelector, createSlice} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import {deleteOrganizationUser, deleteUser, getUserByAuthId, getUsers, saveUser, updateUser} from './operation';
import {UsersState} from './types';

const initialState: UsersState = {
  users: [] as any,
  activeUser: undefined,
  pending: false,
  isSuccess: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.pending = false;
      state.users = action.payload;
    });

    builder.addCase(getUsers.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getUsers.rejected, (state, action) => {
      state.pending = false;
    });
    // Get by auth id
    builder.addCase(getUserByAuthId.fulfilled, (state, action) => {
      state.pending = false;
      state.activeUser = action.payload;
    });

    builder.addCase(getUserByAuthId.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getUserByAuthId.rejected, (state, action) => {
      state.pending = false;
    });
    // Save
    builder.addCase(saveUser.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      state.users.push(action.payload);
    });

    builder.addCase(saveUser.pending, (state, action) => {
      state.isSuccess = false;
      state.pending = true;
    });

    builder.addCase(saveUser.rejected, (state, action) => {
      state.isSuccess = false;
      state.pending = false;
    });

    // Update
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      if (index > -1) {
        state.users[index] = action.payload;
      }
    });

    builder.addCase(updateUser.pending, (state, action) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(updateUser.rejected, (state, action) => {
      state.pending = false;
      state.isSuccess = false;
    });

    // Delete
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.pending = false;
      const index = state.users.findIndex((user) => user.id === action.meta.arg.id);
      if (index > -1) {
        state.users.splice(index, 1);
      }
    });

    builder.addCase(deleteUser.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(deleteUser.rejected, (state, action) => {
      state.pending = false;
    });
    // Delete organization user
    builder.addCase(deleteOrganizationUser.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      if (index > -1) {
        state.users.splice(index, 1);
      }
    });

    builder.addCase(deleteOrganizationUser.pending, (state, action) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(deleteOrganizationUser.rejected, (state, action) => {
      state.pending = false;
      state.isSuccess = false;
    });
  },
});

export const selectUsers = (store: AppState) => store.usersReducer.users;

export const selectUserById = (id: number) =>
  createSelector(selectUsers, (users) => {
    return users.find((user) => user.id === id);
  });

export const selectUserByAuthId = (authId: string) =>
  createSelector(selectUsers, (users) => {
    return users.find((user) => user.authId === authId);
  });

export const selectOrganizationUserByUserId = (orgId: number | undefined, userId: number | undefined) =>
  createSelector(selectUsers, (users) => {
    const user = users.find((user) => user.id === userId);
    return user?.organizationUser?.find((ou) => ou.organizationId === orgId);
  });

// eslint-disable-next-line
export const {} = usersSlice.actions;

export default usersSlice.reducer;
