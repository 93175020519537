import {SortDirection} from '@mui/material';

export interface PageHeaderSettingState {
  searchString?: string;
  pageSettings: PageSetting[];
}

export enum ViewType {
  ListView = 'ListView',
  BoxView = 'BoxView',
}

interface PageSetting {
  key: string;
  sort: SortDirection;
  itemView: ViewType;
  tabIndex: any;
}

export const defaultPageSetting = (pageSettingKey: string) => {
  return {
    key: pageSettingKey,
    sort: 'asc' as SortDirection,
    itemView: ViewType.ListView,
    tabIndex: false,
  };
};
