import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React, {FC, memo, ReactNode, useCallback, useMemo} from 'react';

import {KeyboardArrowDown} from '@mui/icons-material';
import {theme} from '../../../../styles/theme';
import {DropdownOption} from './basic-dropdown';

interface Props {
  label?: string;
  options: DropdownOption[];
  noDefault?: boolean;
  disabled?: boolean;

  onChange: (item: DropdownOption) => void;
  value: any;
}

export const StyleableDropdown: FC<Props> = memo((props) => {
  const mapOptionsToItems = useMemo(
    () =>
      props.options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      )),
    [props.options],
  );

  const onChangeLocal = useCallback(
    (event: SelectChangeEvent<number>, newValue: ReactNode) => {
      const option = props.options.find((option) => option.value === event.target.value);
      if (option) {
        props.onChange(option);
      }
    },
    [props],
  );

  return props.options.length !== 0 ? (
    <FormControl sx={{marginTop: 1, marginBottom: 2, width: 400}}>
      <InputLabel
        shrink={true}
        sx={{
          backgroundColor: theme.palette.background.default,
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          marginLeft: '-6px',
        }}>
        {props.label}
      </InputLabel>
      <Select
        value={props.value ?? 0}
        onChange={onChangeLocal}
        disabled={props.disabled}
        IconComponent={KeyboardArrowDown}>
        {mapOptionsToItems}
      </Select>
    </FormControl>
  ) : (
    <></>
  );
});
