import {Box, Button, SvgIconTypeMap, SxProps, Theme, ButtonProps} from '@mui/material';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import React, {FC, useCallback} from 'react';

export interface BasicButtonProps {
  text: string;
  onClick?: (e: any) => void;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  containerClasses?: any;
  disabled?: boolean;
  type?: ButtonProps['type'];
  buttonColor?: ButtonProps['color'];
  variant?: ButtonProps['variant'];
  sx?: SxProps<Theme>;
}

type Props = BasicButtonProps;

export const BasicButton: FC<Props> = React.memo(
  ({icon, onClick, text, containerClasses, disabled, type, buttonColor = 'primary', sx, variant}) => {
    const onClickLocal = useCallback(
      (e) => {
        if (onClick) {
          onClick(e);
        }
      },
      [onClick],
    );

    return (
      <Box className={containerClasses}>
        <Button
          sx={sx}
          type={type}
          disabled={disabled}
          onClick={onClickLocal}
          variant={variant}
          color={buttonColor}
          startIcon={icon && React.createElement(icon)}>
          {text}
        </Button>
      </Box>
    );
  },
);

BasicButton.defaultProps = {
  variant: 'contained',
};
