import {Box, Typography} from '@mui/material';
import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {INotificationSettingDTO, NotificationSettingDTO} from 'src/api/api';
import {localized, localizedInterpolation} from 'src/i18n/i18n';
import {deleteNotificationSetting, updateNotificationSetting} from 'src/state/features/notification-setting/operation';
import {whiteColor} from 'src/styles/color-constants';
import {theme} from 'src/styles/theme';
import {BasicIcon} from 'src/view/components/default-components/basic-icon';
import {FlexRow} from 'src/view/components/default-components/flex-row';
import {PlainInputText} from 'src/view/components/default-components/form/plain-text-input';
import { SidePanelRouteButton } from 'src/view/components/default-components/side-panel/side-panel-route-button';
import { CreateNotificationRange } from '../create-notification-range';
import {NotificationRangeSetup} from './notification-range-setup';

interface PropsFromParent {
  notificationSetting: INotificationSettingDTO | undefined;
}

export const NotificationSettingSetup: FC<PropsFromParent> = React.memo(({notificationSetting}) => {
  const dispatch = useDispatch();

  const generateNotificationRanges = () => {
    let ranges: JSX.Element[] = [];
    if (notificationSetting?.notificationRanges) {
      ranges = notificationSetting?.notificationRanges.map((nr, i) => {
        return <NotificationRangeSetup key={nr.id} notificationRange={nr} />;
      });
    }

    return ranges;
  };

  const saveNotificationSetting = useCallback(
    (name: string | undefined) => {
      dispatch(updateNotificationSetting({...notificationSetting, name} as NotificationSettingDTO));
    },
    [dispatch, notificationSetting],
  );

  const deleteNotificationProfile = useCallback(() => {
    dispatch(deleteNotificationSetting(notificationSetting as NotificationSettingDTO));
  }, [dispatch, notificationSetting]);

  if (!notificationSetting || !notificationSetting.id) {
    return null;
  }

  return (
    <Box
      sx={{paddingLeft: 6, paddingRight: 6, display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4, flex: 1}}>
      <FlexRow sx={{alignItems: 'center', gap: 2}}>
        <PlainInputText
          sxContainer={{width: '250px', padding: 1.5, boxShadow: '0px 3px 15px #0000001A', backgroundColor: whiteColor}}
          value={notificationSetting.name}
          onBlur={saveNotificationSetting}
        />
        <Typography sx={{color: theme.palette.text.disabled}}>{localized('ProfileName')}</Typography>
        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginLeft: 9}}>
          <BasicIcon onClick={deleteNotificationProfile} icon="delete" title={localized('Delete')} />
          <Typography sx={{color: theme.palette.text.disabled}}>{localized('DeleteProfile')}</Typography>
        </Box>
      </FlexRow>
      {generateNotificationRanges()}
      <SidePanelRouteButton
        buttonText={localizedInterpolation('NewInt', {
          area: localized('UnitType').toLowerCase(),
        })}
        sidePanelContent={<CreateNotificationRange notificationSettingId={notificationSetting?.id} />}
      />
    </Box>
  );
});
