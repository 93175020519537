import {KeyboardArrowDown} from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';
import {withStyles} from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, memo, ReactNode, useCallback, useMemo} from 'react';
import {theme} from '../../../../styles/theme';

export class DropdownOption {
  label: string;
  value: any;
  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

const useStyles = makeStyles({
  select: {},
  icon: {
    fill: 'white',
  },
  root: {
    color: 'white',
  },
  inputLabel: {
    color: 'white',
    '&.Mui-focused': {
      color: 'white',
    },
  },
});

interface Props {
  options: DropdownOption[];
  onChange: (item: DropdownOption) => void;
  value: DropdownOption | null | undefined;
  label?: string;
  sx?: SxProps<Theme>;
  isInvalid?: boolean;
  labelSx?: SxProps<Theme>;
}

const StyledOutlinedInput = withStyles((theme) => ({
  notchedOutline: {
    borderColor: 'white !important',
    borderWidth: '1px !important',
  },
}))(OutlinedInput);

export const BasicDropdown: FC<Props> = memo((props) => {
  const classes = useStyles();

  const mapOptionsToMenuItem = useMemo(() => {
    return props.options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }, [props.options]);

  const onChangeLocal = useCallback(
    (event: SelectChangeEvent<number>, newValue: ReactNode) => {
      const option = props.options.find((option) => option.value === event.target.value);
      if (option) {
        props.onChange(option);
      }
    },
    [props],
  );

  return (
    <FormControl sx={{margin: theme.spacing(1), marginBottom: theme.spacing(1)}}>
      <InputLabel
        shrink={true}
        className={classes.inputLabel}
        sx={{
          backgroundColor: theme.palette.common.white,
          paddingRight: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          color: theme.palette.text.primary,
          marginLeft: '-6px',
          ...props.labelSx,
        }}>
        {props.label}
      </InputLabel>
      <Select
        sx={props.sx}
        className={classes.root}
        value={props.value?.value ?? 0}
        onChange={onChangeLocal}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.root,
          },
        }}
        input={<StyledOutlinedInput />}
        IconComponent={KeyboardArrowDown}>
        {mapOptionsToMenuItem}
      </Select>
    </FormControl>
  );
});
