import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';
import React, {FC, memo, useMemo} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {theme} from '../../../../styles/theme';
import {DropdownOption} from './form-types';
import {KeyboardArrowDown} from '@mui/icons-material';
import { whiteColor } from 'src/styles/color-constants';

interface Props {
  name: string;
  label?: string;
  options: DropdownOption[];
  noDefault?: boolean;
  disabled?: boolean;
  initValue?: any;
  sxContainer?: any;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    disabled: boolean;
  }>;
}

export const TxSelectInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const mapOptionsToItems = useMemo(
    () =>
      props.options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      )),
    [props.options],
  );

  return props.options.length !== 0 ? (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      render={({field}) => (
        <FormControl  variant="standard">
          <Select
            {...field}
            disableUnderline
            sx={props.sxContainer}
            disabled={props.disabled}
            error={!!errors[props.name]}
            value={field.value ?? (props.noDefault ? '' : props.initValue ? props.initValue : props.options[0].value)}
            IconComponent={KeyboardArrowDown}>
            {mapOptionsToItems}
          </Select>
          {!!errors[props.name] && (
            <FormHelperText sx={{color: theme.palette.error.main}}>{errors[props.name].message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  ) : (
    <></>
  );
});
