import {Theme} from '@emotion/react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box, IconButton, Typography, useMediaQuery} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import {BarElement, CategoryScale, Chart, LinearScale} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Bar, getElementAtEvent} from 'react-chartjs-2';
import {localized} from 'src/i18n/i18n';
import {whiteColor} from 'src/styles/color-constants';
import {theme} from 'src/styles/theme';
import {UnitTypeTextFormat} from 'src/utilities/enums/UnitTypeTextFormat';
import {Dataset, IBarChartData} from '../../../utilities/helpers/bar-chart-data-helper';
import {BasicButton} from '../default-components/buttons/basic-button';
import {TextLabel} from '../default-components/text-label';

export interface IThermexUnitData {
  AddedDate: string;
  Temperature: number;
  Humidity: number;
  Voc: number;
  Bat: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartCard: {
      width: '35vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface ButtonStatus {
  forwardDisabled: boolean;
  backwardDisabled: boolean;
}

const initialButtonStatus = {
  forwardDisabled: true,
  backwardDisabled: false,
} as ButtonStatus;
const initialSelectedIndex = 0;
const initialDatasetIndex = [initialSelectedIndex];

interface PropsFromParent {
  barChartData: IBarChartData;
  unit?: UnitTypeTextFormat;
}

const weeksInSet = 5;

export const DrillDownBarChart: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();
  const chartRef = useRef();
  Chart.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);
  const [dataToShow, setDataToShow] = useState<IBarChartData>(props.barChartData);
  const [dataSeperated, setDataSeperated] = useState<IBarChartData[]>();
  const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedIndex);
  const [datasetIndex, setDatasetIndex] = useState<number[]>(initialDatasetIndex);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>(initialButtonStatus);
  const wide = useMediaQuery('(min-width:1350px)');
  const createDataSetByIndexs = useCallback(
    (start: number, end: number) => {
      return {
        backgroundColor: props.barChartData.datasets[0].backgroundColor.slice(start, end),
        borderColor: props.barChartData.datasets[0].borderColor.slice(start, end),
        borderWidth: 1,
        tickColor: props.barChartData.datasets[0].tickColor.slice(start, end),
        data: props.barChartData.datasets[0].data.slice(start, end),
      } as Dataset;
    },
    [props.barChartData.datasets],
  );
  const createBarCharDataByIndex = useCallback(
    (start: number, end: number, newDataset: Dataset, defaultSubset: number) => {
      let newBCD = {
        labels: [],
        datasets: [],
        subDatasets: [],
      } as IBarChartData;

      newBCD = {
        labels: props.barChartData.labels.slice(start, end),
        datasets: [newDataset],
        subDatasets: props.barChartData.subDatasets?.slice(start, end) ?? Array(defaultSubset).fill(newBCD),
      };

      return newBCD;
    },
    [props.barChartData.labels, props.barChartData.subDatasets],
  );

  const updateStates = useCallback((grouped: IBarChartData[]) => {
    let index = grouped.length - 1;
    setDataSeperated(grouped);
    setDatasetIndex([index]);
    setSelectedIndex(index);
    setDataToShow(grouped[index]);
  }, []);

  useEffect(() => {
    if (props.barChartData) {
      let index = props.barChartData.labels.length;
      let grouped = [] as IBarChartData[];

      while (index >= 0) {
        let tempDataSet = {
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          tickColor: [],
          data: [],
        } as Dataset;
        let newBCD = {
          labels: [],
          datasets: [],
          subDatasets: [],
        } as IBarChartData;

        if (index < weeksInSet) {
          tempDataSet = createDataSetByIndexs(0, index);
          newBCD = createBarCharDataByIndex(0, index, tempDataSet, index);
        } else {
          tempDataSet = createDataSetByIndexs(index - weeksInSet, index);
          newBCD = createBarCharDataByIndex(index - weeksInSet, index, tempDataSet, weeksInSet);
        }

        grouped.unshift(newBCD);
        index -= weeksInSet;
      }
      updateStates(grouped);
    }
  }, [createBarCharDataByIndex, createDataSetByIndexs, props.barChartData, updateStates]);

  const getTickColor = useCallback((dt: number[], data: IBarChartData) => {
    if (dt && data) {
      return data.datasets[0].tickColor;
    }
  }, []);

  const getUnitFormatter = useCallback(
    (text: string) => {
      switch (props.unit) {
        case UnitTypeTextFormat.Percentage:
          return text + ' ' + UnitTypeTextFormat.Percentage;
        case UnitTypeTextFormat.DegreeCelsius:
          return text + ' ' + UnitTypeTextFormat.DegreeCelsius;

        default:
          break;
      }
    },
    [props.unit],
  );

  const getDatalabelRotation = useMemo(() => {
    return datasetIndex.length > 2 ? -90 : 0;
  }, [datasetIndex]);

  const getDatalabelFontSize = useMemo(() => {
    return wide ? 12 : 10;
  }, [wide]);

  let options = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: whiteColor,
          rotation: getDatalabelRotation,
          formatter: getUnitFormatter,
          // anchor: 'start',
          font: {
            size: getDatalabelFontSize,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: getTickColor(datasetIndex, dataToShow) ?? 'red',
            font: {
              family: theme.typography.fontFamily,
              size: 12,
              weight: 'bold',
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 12,
            },
          },
        },
      },
    };
  }, [dataToShow, datasetIndex, getTickColor, getUnitFormatter]);

  const handleButtonStatusOnBarPressed = useCallback((index: number, numberOfElements: number) => {
    if (numberOfElements === 1) {
      setButtonStatus({
        forwardDisabled: true,
        backwardDisabled: true,
      });
    } else if (index === 0) {
      setButtonStatus({
        forwardDisabled: false,
        backwardDisabled: true,
      });
    } else if (numberOfElements === index + 1) {
      setButtonStatus({
        forwardDisabled: true,
        backwardDisabled: false,
      });
    } else {
      setButtonStatus({
        forwardDisabled: false,
        backwardDisabled: false,
      });
    }
  }, []);

  const onBarClicked = useCallback(
    (event: any) => {
      let t = getElementAtEvent(chartRef.current!, event);

      if (dataToShow.subDatasets?.length && t.length) {
        setDataToShow(dataToShow.subDatasets.slice()[t[0].index]);
        let newSubset = [...datasetIndex.slice(), t[0].index];
        setDatasetIndex(newSubset);

        setSelectedIndex(t[0].index);
        handleButtonStatusOnBarPressed(t[0].index, dataToShow.labels.length);
      }
    },
    [dataToShow, datasetIndex, handleButtonStatusOnBarPressed],
  );

  const movePeriod = useCallback(
    (value: number) => {
      let newIndex = selectedIndex + value;
      let arr = [...datasetIndex.slice(0, -1), newIndex];
      let newBarChartSet = {} as IBarChartData;

      switch (arr.length) {
        case 1:
          if (dataSeperated) {
            newBarChartSet = dataSeperated.slice()[arr[0]];
            handleButtonStatusOnBarPressed(newIndex, dataSeperated.length);
          }
          break;
        case 2:
          if (dataSeperated && dataSeperated[arr[0]].subDatasets) {
            newBarChartSet = dataSeperated[arr[0]].subDatasets?.slice()[arr[1]]!;
            handleButtonStatusOnBarPressed(newIndex, dataSeperated[arr[0]].labels.length);
          }
          break;
        case 3:
          if (dataSeperated && dataSeperated[arr[0]].subDatasets && dataSeperated[arr[0]].subDatasets![arr[1]]) {
            newBarChartSet = dataSeperated[arr[0]].subDatasets![arr[1]].subDatasets?.slice()[arr[2]]!;
            handleButtonStatusOnBarPressed(newIndex, dataSeperated[arr[0]].subDatasets![arr[1]].labels.length);
          }
          break;

        default:
          break;
      }

      setSelectedIndex(newIndex);
      setDataToShow(newBarChartSet);
      setDatasetIndex(arr);
    },
    [dataSeperated, datasetIndex, handleButtonStatusOnBarPressed, selectedIndex],
  );

  const movePeriodForward = useCallback(() => {
    movePeriod(1);
  }, [movePeriod]);

  const movePeriodBackward = useCallback(() => {
    movePeriod(-1);
  }, [movePeriod]);

  const onResetClicked = useCallback(() => {
    if (dataSeperated) {
      setDatasetIndex([datasetIndex[0]]);
      setSelectedIndex(datasetIndex[0]);
      setDataToShow(dataSeperated[datasetIndex[0]]);
      handleButtonStatusOnBarPressed(datasetIndex[0], dataSeperated.length);
    }
  }, [dataSeperated, datasetIndex, handleButtonStatusOnBarPressed]);

  // const renderParentLabel = () => {
  //   let arr = [...datasetIndex.slice(0, -1), newIndex];
  //   if (dataSeperated && dataSeperated[arr[0]].subDatasets && dataSeperated[arr[0]].subDatasets![arr[1]]) {
  //     newBarChartSet = dataSeperated[arr[0]].subDatasets![arr[1]].subDatasets?.slice()[arr[2]]!;
  //     handleButtonStatusOnBarPressed(newIndex, dataSeperated[arr[0]].subDatasets![arr[1]].labels.length);
  //   }

  //   return <div></div>;
  // };

  const label = () => {
    if (datasetIndex.length && dataSeperated) {
      let subSet: IBarChartData[] | undefined = dataSeperated;
      // Go through each layer to find parent label, of selected index
      for (var i = 0; i < datasetIndex.length - 1; i++) {
        if (i !== datasetIndex.length - 2 && subSet) {
          subSet = subSet[datasetIndex[i]].subDatasets;
        } else if (subSet) {
          return (
            <TextLabel sx={{height: 25, textAlign: 'center', fontWeight: 'bold'}}>
              {subSet[datasetIndex[i]].labels[selectedIndex]}
            </TextLabel>
          );
        }
      }
    }
    return <Box sx={{height: 25}}></Box>;
  };

  return (
    <Box sx={{width: '100%'}}>
      {label()}
      {dataToShow && (
        <Box sx={{display: 'flex', flexDirection: 'row', marginTop: theme.spacing(2)}}>
          <Box sx={{width: '100%'}}>
            <Bar
              className={classes.chartCard}
              ref={chartRef}
              options={options}
              data={dataToShow}
              onClick={onBarClicked}
              updateMode={'active'}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            {datasetIndex.length > 1 ? (
              <BasicButton
                text={localized('Back')}
                onClick={onResetClicked}
                sx={{padding: '2px 6px', fontSize: theme.typography.body3}}
              />
            ) : (
              <div style={{height: 25, width: 65}}></div>
            )}
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <IconButton
                color="primary"
                onClick={movePeriodForward}
                disabled={buttonStatus.forwardDisabled}
                sx={{margin: theme.spacing(0.5), padding: '5px'}}>
                <ArrowForwardIosIcon sx={{marginLeft: '2px'}} />
              </IconButton>
              <IconButton
                color="primary"
                disabled={buttonStatus.backwardDisabled}
                onClick={movePeriodBackward}
                sx={{margin: theme.spacing(0.5), padding: '5px'}}>
                <ArrowForwardIosIcon sx={{transform: 'rotate(180deg)', marginRight: '2px'}} />
              </IconButton>
            </Box>
            {datasetIndex.length === 1 ? (
              <Box
                sx={{
                  marginBottom: '3px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography
                  sx={{
                    typography: theme.typography.fontFamily,
                    fontSize: 12,
                  }}>
                  {localized('Week')}
                </Typography>
              </Box>
            ) : (
              <div style={{height: 18, width: 65}}></div>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
});
