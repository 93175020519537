import {Box} from '@mui/material';
import {height} from '@mui/system';
import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RoomType} from 'src/api/api';
import {
  selectPageSettingByKey,
  selectPageSettingTabIndex,
} from 'src/state/features/page-header-setting/page-header-setting-slice';
import {theme} from 'src/styles/theme';
import {Pages} from 'src/utilities/enums/pages';
import {getRoomTypeOptions} from 'src/utilities/platform-helpers/enum-helper';
import {usePrevious} from 'src/utilities/use-previous-hook';
import {FlexColumn} from 'src/view/components/default-components/flex-column';
import {FlexRow} from 'src/view/components/default-components/flex-row';
import {StyledTab, StyledTabsVertical} from 'src/view/components/default-components/page-header.tsx/page-header-tab';
import {SidePanelRouteButton} from 'src/view/components/default-components/side-panel/side-panel-route-button';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {getNotificationSettings} from '../../../state/features/notification-setting/operation';
import {getOrganizations} from '../../../state/features/organization/operation';
import {AppState} from '../../../state/store';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageHeaderTabContainer} from '../../components/default-components/page-header.tsx/page-header-tab-container';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {CreateNotificationRange} from './create-notification-range';
import {CreateProfile} from './create-profile';
import {NotificationSelectContainer} from './notification-select-container';
import {NotificationSelectItem} from './notification-select-item';
import {NotificationSettingSetup} from './notifications-list/notifications-setup';
function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
export const NotificationSettingContext = createContext({} as any);
export const useNsContext = () => useContext(NotificationSettingContext);

export const Notifications = React.memo(() => {
  const dispatch = useDispatch();
  const organizations = useSelector((store: AppState) => store.organizationReducer.organizations);
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const tabIndex = useSelector(selectPageSettingTabIndex(Pages.Notifications));
  const prevTabIndex = usePrevious(tabIndex);
  const notificationSettings = useSelector((store: AppState) => store.notificationSettingReducer.notificationSettings);

  const [selectedNotificationId, setSelectedNotificationId] = useState<number | undefined>(undefined);

  const roomTypeNotifications = useMemo(() => {
    const notificationsFiltered = notificationSettings.filter((ns) => ns.roomType === tabIndex!);

    // Select first setting when group chance
    if (prevTabIndex !== tabIndex && notificationsFiltered.length > 0) {
      setSelectedNotificationId(notificationsFiltered[0].id);
    }
    return notificationsFiltered;
  }, [notificationSettings, tabIndex, prevTabIndex]);

  const selectedNotificationSetting = useMemo(() => {
    return roomTypeNotifications.find((ns) => ns.id === selectedNotificationId);
  }, [selectedNotificationId, roomTypeNotifications]);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getNotificationSettings(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  useEffect(() => {
    if (!organizations) {
      dispatch(getOrganizations());
    }
  }, [dispatch, organizations]);

  const tabs = useMemo(() => {
    return getRoomTypeOptions().map((ns) => {
      return <StyledTab value={ns.value} key={ns.value} label={ns.label} />;
    });
  }, []);

  const notificationTabs = useMemo(() => {
    return roomTypeNotifications.map((ns) => {
      return (
        <StyledTabsVertical
          value={ns.id}
          key={ns.id}
          label={ns.name}
          sx={{alignItems: 'flex-start', paddingBottom: 2, paddingTop: 2}}
        />
      );
    });
  }, [roomTypeNotifications]);

  const createNotificationRange = () => {
    return (
      selectedNotificationSetting && (
        <SidePanelRouteButton
          buttonText={localizedInterpolation('NewInt', {
            area: localized('NotificationProfile').toLowerCase(),
          })}
          sidePanelContent={<CreateNotificationRange notificationSettingId={selectedNotificationSetting?.id} />}
        />
      )
    );
  };

  return (
    <NotificationSettingContext.Provider value={{selectedNotificationId, setSelectedNotificationId}}>
      <PageTitle title={localized('Notifications')}>
        <PageHeader
          shouldClearPageSettingOnUnmount={true}
          area={Pages.Notifications}
          title={localized('Notifications')}>
          <PageHeaderTabContainer initValue={RoomType.Standard}>{tabs}</PageHeaderTabContainer>
        </PageHeader>
        <PageContentContainer sx={{flex: 1, display: 'flex'}}>
          <FlexRow>
            <FlexColumn
              sx={{
                paddingLeft: '48px',
                borderRightColor: theme.colors.disableColor,
                borderRightWidth: 1,
                borderRightStyle: 'solid',
                justifyContent: 'space-between',
                marginTop: 6,
                marginBottom: 6,
                minWidth: 300,
              }}>
              <Box>
                {notificationTabs.length > 0 && (
                  <NotificationSelectContainer>{notificationTabs}</NotificationSelectContainer>
                )}
              </Box>
              <SidePanelRouteButton
                buttonText={localizedInterpolation('NewInt', {
                  area: localized('Profile').toLowerCase(),
                })}
                sidePanelContent={<CreateProfile />}
              />
            </FlexColumn>

            <NotificationSettingSetup notificationSetting={selectedNotificationSetting} />
          </FlexRow>
        </PageContentContainer>
      </PageTitle>
    </NotificationSettingContext.Provider>
  );
});
