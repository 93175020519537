import {TextField, Theme} from '@mui/material';
import {createStyles, makeStyles, withStyles} from '@mui/styles';
import React, {FC, memo, useCallback} from 'react';
import {Controller, ControllerProps, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {FlexColumn} from '../flex-column';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    valueAsNumber: boolean;
    valueAsDate: boolean;
    setValueAs: (value: any) => any;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.colors.sidePanel.sidePanelTextInputLabelColor,
    },
  }),
);

const CssTextField = withStyles((theme: Theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.colors.sidePanel.sidePanelTextInputLabelColor,
    },
    '& label': {
      color: theme.colors.sidePanel.sidePanelTextInputLabelColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.colors.sidePanel.sidePanelTextInputLabelColor,
    },
    '& input': {
      color: theme.colors.sidePanel.sidePanelTextInputColor,
      backgroundColor: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFF',
      },
      '&:hover fieldset': {
        // borderColor: '',
        borderRadius: 1,
      },
      '&.Mui-focused fieldset': {
        // border: 'none',
        borderWidth: 1,
        borderRadius: 1,
      },
    },
  },
}))(TextField);

export const SidePanelTextInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const classes = useStyles();

  const buildField = useCallback(
    ({field}: Parameters<ControllerProps['render']>[0]) => (
      <>
        <FlexColumn>
          <span className={classes.label}>{props.label}</span>
          <CssTextField
            {...field}
            disabled={props.disabled}
            sx={{marginTop: 1, marginBottom: 2}}
            InputLabelProps={{shrink: true}}
            value={field.value ?? ''}
            error={!!errors[props.name]}
            helperText={!!errors[props.name] && errors[props.name].message}
          />
        </FlexColumn>
      </>
    ),
    [classes.label, errors, props.disabled, props.label, props.name],
  );

  return <Controller name={props.name} control={control} rules={props.validators} render={buildField} />;
});
