import {Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {clearPageSetting} from 'src/state/features/page-header-setting/page-header-setting-slice';
import {primaryTextColor} from '../../../../styles/color-constants';
import {theme} from '../../../../styles/theme';
import {FlexColumn} from '../../../components/default-components/flex-column';
import {FlexRow} from '../flex-row';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '28px 48px 0px 48px',
      minHeight: 145,
      justifyContent: 'space-between',
      [theme.breakpoints.down(602)]: {
        paddingTop: 0,
      },
    },
    title: {
      color: primaryTextColor,
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
  }),
);

interface PropsFromParent {
  title: string;
  area: string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  shouldClearPageSettingOnUnmount?: boolean;
}

export const PageHeaderContext = React.createContext({} as any);
export const usePageHeader = () => useContext(PageHeaderContext);

export const PageHeader: FC<PropsFromParent> = React.memo(
  ({prefix, title, area, shouldClearPageSettingOnUnmount, postfix, ...props}) => {
    const classes = useStyles();
    const [areaId, setAreaId] = useState<string>();
    const dispatch = useDispatch();
    useEffect(() => {
      setAreaId(area);

      return () => {
        if (shouldClearPageSettingOnUnmount) {
          dispatch(clearPageSetting(area));
        }
      };
    }, [area, dispatch, shouldClearPageSettingOnUnmount]);

    return (
      <PageHeaderContext.Provider value={{areaId}}>
        <FlexColumn className={classes.root}>
          {prefix}
          <FlexRow sx={{alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography fontSize={theme.typography.h1.fontSize} className={classes.title}>
              {title}
            </Typography>
            {postfix}
          </FlexRow>
          {props.children}
        </FlexColumn>
      </PageHeaderContext.Provider>
    );
  },
);
