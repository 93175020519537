import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../auth/auth-provider';
import {localized} from '../../../i18n/i18n';
import {selectLocationSpinner} from '../../../state/features/location/location-slice';
import {getLocations} from '../../../state/features/location/operation';
import {ViewType} from '../../../state/features/page-header-setting/types';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {Pages} from '../../../utilities/enums/pages';
import {FlexColumn} from '../../components/default-components/flex-column';
import {FlexRow} from '../../components/default-components/flex-row';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../components/default-components/page-header.tsx/page-header-content';
import {PageHeaderSearch} from '../../components/default-components/page-header.tsx/page-header-search';
import {PageHeaderSort} from '../../components/default-components/page-header.tsx/page-header-sort';
import {StyledTab} from '../../components/default-components/page-header.tsx/page-header-tab';
import {PageHeaderTabContainer} from '../../components/default-components/page-header.tsx/page-header-tab-container';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {BasicSpinner} from '../../components/default-components/spinner/basic-spinner';
import {LocationListView} from './locations-list/location-list-view';

export const LocationsOverview: FC = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isGlobalAdmin} = useAuth();
  const pageSettings = useSelector((store: AppState) => store.pageSettingReducer.pageSettings);
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);

  const pageSetting = pageSettings.find((setting) => setting.key === Pages.Locations);
  const showSpinner = useSelector(selectLocationSpinner);

  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getLocations(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  useEffect(() => {}, [dispatch]);

  const goToCreateLocation = () => {
    navigate(routes.locationsCreate);
  };

  return (
    <PageTitle title={localized('Locations')}>
      <PageHeader area={Pages.Locations} title={localized('Locations')}>
        <PageHeaderContent>
          <FlexColumn flex={1}>
            <FlexRow flex={1} flexWrap="wrap">
              <PageHeaderTabContainer initValue={0}>
                <StyledTab label={localized('Locations')} />
                <StyledTab label={localized('Remark')} />
              </PageHeaderTabContainer>
              {isGlobalAdmin && <StyledTab onClick={goToCreateLocation} label={'+ ' + localized('CreateLocation')} />}
              {/* Implement when box view in introduced */}
              {/* <PageHeaderToggleView /> */}
              <PageHeaderSearch />
              <PageHeaderSort />
            </FlexRow>
          </FlexColumn>
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer>
        {showSpinner ? (
          <BasicSpinner />
        ) : !pageSetting || pageSetting.itemView === ViewType.BoxView ? (
          <LocationListView />
        ) : pageSetting.itemView === ViewType.ListView ? (
          <LocationListView />
        ) : null}
      </PageContentContainer>
    </PageTitle>
  );
});
