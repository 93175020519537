import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  IRawThermexData,
  ThermexUnitClient,
  ThermexUnitDTO,
  WbThermexDeviceDto,
  WbThermexUnitDto,
} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {thermexUnitClient} from '../../api-clients';

export const getThermexUnits = createAsyncThunk('thermexUnits/getThermexUnits', (apartmentId: number) => {
  return AsyncOperationHandler(
    (client) => (client as ThermexUnitClient).getByApartmentId(apartmentId),
    thermexUnitClient,
  );
});

export const getThermexUnitsByLocation = createAsyncThunk(
  'thermexUnits/getThermexUnitsByLocation',
  (locationId: number) => {
    return AsyncOperationHandler(
      (client) => (client as ThermexUnitClient).getThermexUnitsByLocationId(locationId),
      thermexUnitClient,
    );
  },
);

export const updateThermexUnit = createAsyncThunk(
  'thermexUnits/updateThermexUnit',
  (payload: {thermexUnit: ThermexUnitDTO; callbackOnSuccess?: (result: ThermexUnitDTO) => void}) => {
    return AsyncOperationHandler(
      (client) => (client as ThermexUnitClient).updateThermexUnitsForApartment(payload.thermexUnit),
      thermexUnitClient,
      {
        showResponseMessage: true,
        callbackOnSuccess: payload.callbackOnSuccess,
      },
    );
  },
);

// export const saveThermexUnit = createAsyncThunk(
//   'thermexUnits/saveThermexUnit',
//   (payload: {thermexUnit: ThermexUnitDTO; callbackOnSuccess?: (result: ThermexUnitDTO) => void}) => {
//     return AsyncOperationHandler(
//       (client) => (client as ThermexUnitClient).setupThermexUnitsForApartment(payload.thermexUnit),
//       thermexUnitClient,
//       {
//         showResponseMessage: true,
//         callbackOnSuccess: payload.callbackOnSuccess,
//       },
//     );
//   },
// );

export const getThermexUnitData = createAsyncThunk(
  'thermexUnits/getThermexUnitData',
  (payload: {deviceId: string | undefined; sensorId: string | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as ThermexUnitClient).getThermexUnitData(payload.deviceId, payload.sensorId),
      thermexUnitClient,
      {},
    );
  },
);

export const setupThermexUnitsForApartment = createAsyncThunk('thermexUnits/getThermexUnitData', () => {
  return AsyncOperationHandler(
    (client) =>
      (client as ThermexUnitClient).setupThermexUnitsForApartment({
        deviceId: 'tersæøü',
        apartmentId: 1018,
        sensors: [
          {
            sensorID: '221121131344',
            name: 'teæørsü',
          } as any,
        ] as WbThermexUnitDto[],
      } as WbThermexDeviceDto),
    thermexUnitClient,
    {},
  );
});

export const removeThermexUnit = createAsyncThunk('thermexUnits/removeThermexUnit', (thermexUnit: ThermexUnitDTO) => {
  return AsyncOperationHandler(
    (client) => (client as ThermexUnitClient).removeThermexUnit(thermexUnit),
    thermexUnitClient,
    {},
  );
});
