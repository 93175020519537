import { Box, Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localized } from '../../../../i18n/i18n';
import { toggleSort } from '../../../../state/features/page-header-setting/page-header-setting-slice';
import { defaultPageSetting } from '../../../../state/features/page-header-setting/types';
import { AppState } from '../../../../state/store';
import { usePageHeader } from './page-header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.colors.header.headerSelectedTextColor,
      fontWeight: 'bold',
    },
  }),
);

interface PropsFromParent {
  containerClassName?: string;
}

export const PageHeaderSort: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const {areaId} = usePageHeader();
  const classes = useStyles();
  const pageSetting =
    useSelector((store: AppState) => store.pageSettingReducer.pageSettings.find((setting) => setting.key === areaId)) ||
    defaultPageSetting(areaId);

  const sortDirectionChanged = useCallback(() => {
    if (pageSetting) {
      const sortDirection = pageSetting.sort === 'asc' ? 'desc' : 'asc';
      dispatch(toggleSort({value: sortDirection, key: areaId}));
    }
  }, [dispatch, areaId, pageSetting]);

  return (
    <Box marginRight={4} display="flex" alignItems="center" flexDirection="row" className={props.containerClassName}>
      <Typography variant="body2">{localized('SortBy')}</Typography>
      <Button className={classes.button} onClick={sortDirectionChanged}>
        A {pageSetting.sort === 'asc' ? ' —> ' : ` <— `} Z
      </Button>
    </Box>
  );
});
