import {Box, SortDirection, SxProps} from '@mui/material';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectUserLocationIdsByOrganizationUserId,
  setEditUserLocation,
} from 'src/state/features/location-organization-user/location-organization-user-slice';
import {Pages} from 'src/utilities/enums/pages';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {sortListItems} from '../../../../utilities/helpers/general-helpers';
import {VirtualizedListTable} from '../../../components/default-components/virtual-list-table/virtual-list-table';
import {LocationManagementListItem} from './location-user-relation-list-item';

interface PropsFromParent {
  organizationUserId?: number;
}

export const LocationManagementListView: FC<PropsFromParent> = React.memo(({organizationUserId}) => {
  const dispatch = useDispatch();
  const [filteredItems, setFilteredItems] = useState<number[]>([]);
  const searchString = useSelector((store: AppState) => store.pageSettingReducer.searchString);
  const users = useSelector((store: AppState) => store.usersReducer.users);
  const locations = useSelector((store: AppState) => store.locationReducer.locations);
  const pageSetting = useSelector(selectPageSettingByKey(Pages.Locations));
  const userLocations = useSelector(selectUserLocationIdsByOrganizationUserId(organizationUserId?.toString()));
  const editUserLocations = useSelector(
    (store: AppState) => store.locationOrganizationUserReducer.editLocationOrganizationUser,
  );
  const columnSizes = useMemo((): number[] => [1, 2, 2], []);

  useEffect(() => {
    if (userLocations && organizationUserId) {
      dispatch(setEditUserLocation({key: organizationUserId, ids: userLocations}));
    }
  }, [dispatch, organizationUserId]);

  useEffect(() => {
    setFilteredItems(
      sortListItems(locations, 'name', pageSetting?.sort as SortDirection).map((location) => location.id!),
    );
  }, [locations, pageSetting, searchString, users]);

  const renderLocationItem = useCallback(
    ({key, index, style}: any) => {
      if (index > filteredItems.length - 1) {
        return null;
      }
      return (
        <Box key={key} style={style}>
          <LocationManagementListItem
            columnSizes={columnSizes}
            locationId={filteredItems[index]}
            organizationUserId={organizationUserId}
            isSelected={editUserLocations?.ids?.includes(filteredItems[index])}
          />
        </Box>
      );
    },
    [filteredItems, columnSizes, organizationUserId, editUserLocations],
  );

  return (
    <Box sx={{marginTop: theme.spacing(3), minWidth: 600} as SxProps}>
      <VirtualizedListTable items={filteredItems} columnSizes={columnSizes} rowRenderer={renderLocationItem} />
    </Box>
  );
});
