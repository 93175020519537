import {Theme} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {editUserLocation} from 'src/state/features/location-organization-user/location-organization-user-slice';
import {selectLocationById} from 'src/state/features/location/location-slice';
import {theme} from 'src/styles/theme';
import {BasicSvgIcon} from 'src/view/components/default-components/basic-svg-icon';
import {ReactComponent as MapsIcon} from '../../../../assets/maps_icon.svg';
import {localized} from '../../../../i18n/i18n';
import {ListTableItem} from '../../../components/default-components/list-table/list-table-item';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedItem: {
      backgroundColor: theme.colors.sidePanel.sidePanelSelectedColor,
    },
    notSelectedItem: {
      backgroundColor: theme.colors.sidePanel.sidePanelNotSelectedColor,
    },
  }),
);

interface PropsFromParent {
  locationId: number;
  organizationUserId?: number;
  isSelected?: boolean;
  columnSizes?: number[];
}

export const LocationManagementListItem: FC<PropsFromParent> = React.memo(
  ({locationId, organizationUserId, isSelected, ...props}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useSelector(selectLocationById(locationId));

    const locationSelected = useCallback(
      (locationId: number) => {
        if (organizationUserId) dispatch(editUserLocation({key: organizationUserId, locationId: locationId}));
      },
      [dispatch, organizationUserId],
    );

    if (!location) {
      return null;
    }

    return (
      <ListTableItem
        propStyle={isSelected ? classes.selectedItem : classes.notSelectedItem}
        {...props}
        itemSelected={locationSelected}
        contentItem={locationId}
        objectValues={[
          <BasicSvgIcon stroke={theme.colors.sidePanel.sidePanelBgColor} component={MapsIcon} />,
          location.address,
          location.numberOfThermexUnits + ' ' + localized('Units'),
        ]}
      />
    );
  },
);
