// Local Storage keys
export const AccessTokenKey = "accessToken";

// Dates
export const DateFormat = "DD/MM/YY";
export const DateFormatWithHours = "DD/MM/YY HH:mm";
export const TimestampFormat = "HH:mm";

// Auth
export const AuthGetAdminRoleArray = "https://jm-iot-api/roles";
export const RedirectUriStorageKey = "url";

export const AuthRoles = {
    User: 'User',
    Admin: 'Admin',
    GlobalAdmin: 'GlobalAdmin'
}

// App Config
export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const BrandName = process.env.REACT_APP_CUSTOMER_BRAND;
export const InsightsKey = process.env.REACT_APP_INSIGHTS_KEY;

// Google maps
export const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
export const StaticMapsApiUrl = process.env.REACT_APP_GOOGLE_MAPS_STATIC_MAP_API_URL
