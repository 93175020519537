import {createSelector, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import {selectApartmentsByLocationId} from '../apartment/apartment-slice';
import {
  getThermexUnitData,
  getThermexUnits,
  getThermexUnitsByLocation,
  removeThermexUnit,
  updateThermexUnit,
} from './operation';
import {ThermexUnitState} from './types';

const initialState: ThermexUnitState = {
  thermexUnits: [],
  pending: false,
  isSuccess: false,
  thermexDataByDevice: [],
};

export const thermexUnitSlice = createSlice({
  name: 'thermexUnits',
  initialState,
  reducers: {
    clearThermexUnitData: (state) => {
      state.thermexDataByDevice = [];
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getThermexUnits.fulfilled, (state, action) => {
      state.pending = false;
      state.thermexUnits = action.payload;
    });
    builder.addCase(getThermexUnitsByLocation.fulfilled, (state, action) => {
      state.pending = false;
      state.thermexUnits = action.payload;
    });
    builder.addCase(getThermexUnitData.fulfilled, (state, action) => {
      state.thermexDataByDevice = action.payload;
    });
    // Save
    // builder.addCase(saveThermexUnit.fulfilled, (state, action) => {
    //   state.pending = false;
    //   state.isSuccess = true;
    // });

    // builder.addCase(saveThermexUnit.pending, (state, action) => {
    //   state.pending = true;
    //   state.isSuccess = false;
    // });

    // builder.addCase(saveThermexUnit.rejected, (state, action) => {
    //   state.pending = false;
    //   state.isSuccess = false;
    // });
    // Update
    builder.addCase(updateThermexUnit.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;

      const index = state.thermexUnits.findIndex((thermexUnit) => thermexUnit.sensorId === action.payload.sensorId);
      if (index > -1) {
        state.thermexUnits[index] = action.payload;
      }
    });

    builder.addCase(updateThermexUnit.pending, (state, action) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addCase(updateThermexUnit.rejected, (state, action) => {
      state.pending = false;
      state.isSuccess = false;
    });

    builder.addCase(removeThermexUnit.pending, (state, action) => {
      const index = state.thermexUnits.findIndex((tu) => tu.sensorId === action.meta.arg.sensorId);
      if (index > -1) {
        state.thermexUnits.splice(index, 1);
      }
    });

    // Common actions. addMatcher() must always be after .addCase
    builder.addMatcher(isAnyOf(getThermexUnitData.pending), (state) => {
      state.pending = true;
      state.isSuccess = false;
    });
    builder.addMatcher(isAnyOf(getThermexUnitData.rejected), (state) => {
      state.pending = false;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(getThermexUnitData.fulfilled), (state) => {
      state.pending = false;
      state.isSuccess = true;
    });
  },
});

export const selectThermexUnits = (store: AppState) => store.thermexUnitReducer.thermexUnits;
export const selectThermexById = (id: number | undefined) =>
  createSelector(selectThermexUnits, (units) => {
    return units.find((unit) => unit.id === id);
  });

export const selectThermexUnitsByApartmentId = (id: number | undefined) =>
  createSelector(selectThermexUnits, (units) => {
    return units.filter((un) => un.apartmentId === id);
  });

export const selectThermexUnitById = (sensorId: string | undefined) =>
  createSelector(selectThermexUnits, (units) => {
    return units.find((tu) => tu.sensorId === sensorId);
  });

export const selectThermexUnitsCountByLocation = (locationId: number | undefined) =>
  createSelector([selectApartmentsByLocationId(locationId), selectThermexUnits], (apartments, thermexUnits) => {
    return thermexUnits.filter((unit) => apartments.some((apartment) => apartment.id === unit.apartmentId)).length;
  });

// eslint-disable-next-line
export const {clearThermexUnitData} = thermexUnitSlice.actions;

export default thermexUnitSlice.reducer;
