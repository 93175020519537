import styled from '@emotion/styled';
import {Tab} from '@mui/material';
import {secondaryTextColor} from 'src/styles/color-constants';
import {theme} from '../../../../styles/theme';

export const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(15),
  marginRight: 30,
  color: theme.palette.text.disabled,
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  '&.show-indicator:after': {
    content: '"\\A"',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    background: secondaryTextColor,
    display: 'inline-block',

    position: 'absolute',
    right: '-7px',
    top: '7px',
  },
  minHeight: 35,
  minWidth: 20,
  padding: '0 0px 0 0',
  overflow: 'visible',
});

export const StyledTabsVertical = styled(Tab)({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(15),
  marginRight: 30,
  color: theme.palette.text.disabled,
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  minHeight: 35,
  minWidth: 20,
  paddingLeft: 0,
  paddingRight: 0,
});
