export const routes = {
  userManagement: '/user-management',
  userManagementCreate: '/user-management/create',
  userManagementEdit: '/user-management/:userId/edit',
  
  organization: '/organizations',
  organizationCreate: '/organizations/create',
  organizationEdit: '/organizations/:organizationId/edit',
  
  template: '/template',

  callback: '/callback',

  noPermission: '/no-permission',

  locations: '/locations',
  locationsCreate: '/locations/create',
  locationsEdit: '/locations/:locationId/edit',

  locationsDetail: '/locations/:locationId/detail',
  locationsDetailWithApartment: '/locations/:locationId/detail/:apartmentId',

  notifications: '/notifications',
};

export const defaultRoute = routes.locations;
