import {PaletteOptions} from '@mui/material/styles';
import {backgroundColor, primaryColor, primaryTextColor, secondaryColor, secondaryTextColor, whiteColor} from './color-constants';

export const DefaultPalette: PaletteOptions = {
  primary: {
    main: primaryColor,
  },
  secondary: {
    main: secondaryColor,
  },
  background: {
    default: backgroundColor,
    paper: whiteColor,
  },
  text: {
    primary: primaryTextColor,
    secondary: secondaryTextColor,
  },
  Example: {main: '#333'},
};
