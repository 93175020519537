import {StyledEngineProvider, Theme, ThemeProvider} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {SnackbarProvider} from 'notistack';
import React, {FC, useCallback} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {AppRoutes} from './app-routes';
import {GeneralAuthProvider} from './auth/auth-provider';
import {store} from './state/store';
import {theme} from './styles/theme';
import {Snackbar} from './view/components/default-components/snackbar/snackbar';
import {SnackbarCloseButton} from './view/components/default-components/snackbar/snackbar-close-button';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

// Persist the whole store
const persistor = persistStore(store);

export const App: FC = () => {
  const getCloseButton = useCallback((key: number | string) => {
    return <SnackbarCloseButton snackKey={key as number} />;
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GeneralAuthProvider>
              <SnackbarProvider maxSnack={4} action={getCloseButton}>
                <CssBaseline />
                <Snackbar />
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </SnackbarProvider>
            </GeneralAuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
};
