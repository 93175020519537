import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Box, Collapse, Divider, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback} from 'react';
import {MeasureType} from '../../../../../api/api';
import {ReactComponent as MoreDetails} from '../../../../../assets/more_details_dots.svg';
import {invalidColor, primaryColor} from '../../../../../styles/color-constants';
import {theme} from '../../../../../styles/theme';
import {BasicSvgIcon} from '../../../../components/default-components/basic-svg-icon';
import {FlexColumn} from '../../../../components/default-components/flex-column';
import {FlexRow} from '../../../../components/default-components/flex-row';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      alignItems: 'center',
      backgroundColor: theme.colors.listTable.ListTableBgColor,
      margin: theme.spacing(1.6, 0),
      padding: theme.spacing(1.45, 2),
      borderLeft: 'solid 6px',
      borderColor: 'transparent',
    },
    itemText: {
      fontSize: theme.typography.body2.fontSize,
      color: theme.colors.listTable.ListTableTextColor,
    },
    showCursor: {
      cursor: 'pointer',
    },
  }),
);

export interface IThermexUnitListItem {
  measureType: MeasureType;
  icon: JSX.Element;
  name: string;
  value: number;
  unit?: string;
  showAsWarning?: boolean;
  child?: JSX.Element;
}

interface PropsFromParent {
  data: IThermexUnitListItem;
  selected: boolean;
  callback: (type: MeasureType) => void;
}

export const ThermexUnitListItem: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();
  const onClickExpand = useCallback(() => {
    props.callback(props.data.measureType);
  }, [props]);

  return (
    <FlexColumn sx={{boxShadow: 4}} className={classes.itemContainer}>
      <FlexRow sx={{alignItems: 'center'}}>
        <Box sx={{margin: theme.spacing(0, 0.5)}}>{props.data.icon}</Box>
        <Typography
          sx={{
            display: 'flex',
            flexGrow: 2,
            margin: theme.spacing(0, 1),
            maxWidth: 150,
            color: props.data.showAsWarning ? invalidColor : primaryColor,
          }}>
          {props.data.name}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            flexGrow: 2,
            margin: theme.spacing(0, 0.5),
            fontWeight: 'bold',
            color: props.data.showAsWarning ? invalidColor : primaryColor,
          }}>
          {props.data.value && props.data.value + ' ' + (props.data.unit ? props.data.unit : '')}
        </Typography>
        <Box sx={{margin: theme.spacing(0, 1.5), cursor: 'pointer'}} onClick={onClickExpand}>
          <ArrowForwardIosIcon sx={{transform: props.selected ? 'rotate(270deg)' : 'rotate(90deg)'}} />
        </Box>
      </FlexRow>
      <Collapse sx={{width:"100%"}} in={props.selected}>{props.data.child}</Collapse>
    </FlexColumn>
  );
});
