import {createSelector, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import {selectThermexUnitsByApartmentId} from '../thermex-unit/thermex-unit-slice';
import {getAlarmsBySensorId} from './operations';
import {AlarmsState} from './types';

const initialState: AlarmsState = {
  alarms: [],
  pending: false,
  isSuccess: false,
};

export const alarmsSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getAlarmsBySensorId.fulfilled, (state, action) => {
      state.alarms = action.payload;
    });

    // Matchers
    builder.addMatcher(isAnyOf(getAlarmsBySensorId.pending), (state) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(getAlarmsBySensorId.rejected), (state) => {
      state.pending = false;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(getAlarmsBySensorId.fulfilled), (state) => {
      state.pending = false;
      state.isSuccess = true;
    });
  },
});

export const selectAlarms = (store: AppState) => store.alarmsReducer.alarms;

export const selectAlarmsByApartmentId = (id: number | undefined) =>
  createSelector([selectThermexUnitsByApartmentId(id), selectAlarms], (thermexUnits, alarms) => {
    const thermexUnitIds: Number[] = thermexUnits.map((x) => x.id);
    return alarms.filter((a) => thermexUnitIds.includes(Number(a.sensorId)));
  });

export default alarmsSlice.reducer;
