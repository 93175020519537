import {Box, SxProps} from '@mui/material';
import {DateTime} from 'luxon';
import React, {FC, useCallback, useEffect, useMemo, useState, MouseEvent} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAlarmsBySensorId} from 'src/state/features/alarms/operations';
import {getThermexUnitData, removeThermexUnit} from 'src/state/features/thermex-unit/operation';
import {clearThermexUnitData} from 'src/state/features/thermex-unit/thermex-unit-slice';
import {applySidePanelContent, setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {AppState} from 'src/state/store';
import {invalidColor} from 'src/styles/color-constants';
import {UnitTypeTextFormat} from 'src/utilities/enums/UnitTypeTextFormat';
import {DataTypeArr, GroupDataByKey, MakeDataSet, ThermexData} from 'src/utilities/helpers/bar-chart-data-helper';
import {DrillDownBarChart} from 'src/view/components/charts/drill-down-bar-chart';
import {BasicIcon} from 'src/view/components/default-components/basic-icon';
import {BasicSpinner} from 'src/view/components/default-components/spinner/basic-spinner';
import {IThermexUnitDTO, MeasureType, SeverityLevel, ThermexUnitDTO} from '../../../../../api/api';
import {ReactComponent as BatteryStatus} from '../../../../../assets/battery_status.svg';
import {ReactComponent as BatteryStatusWarning} from '../../../../../assets/battery_status_warning.svg';
import {ReactComponent as Humidity} from '../../../../../assets/humidity_logo.svg';
import {ReactComponent as HumidityWarning} from '../../../../../assets/humidity_logo_warning.svg';
import {ReactComponent as Temperature} from '../../../../../assets/temperature_logo.svg';
import {ReactComponent as TemperatureWarning} from '../../../../../assets/temperature_logo_warning.svg';
import {ReactComponent as VocHouse} from '../../../../../assets/voc_logo.svg';
import {ReactComponent as VocHouseWarning} from '../../../../../assets/voc_logo_warning.svg';
import {ReactComponent as MoreDetails} from '../../../../../assets/more_details_dots.svg';
import {getLanguage, localized, localizedInterpolation} from '../../../../../i18n/i18n';
import {theme} from '../../../../../styles/theme';
import {BasicSvgIcon} from '../../../../components/default-components/basic-svg-icon';
import {EditRoom} from './edit-room';
import {IThermexUnitListItem, ThermexUnitListItem} from './thermex-unit-item';
import BorderColorIcon from '@mui/icons-material/BorderColor';

interface PropsFromParent {
  thermexUnit?: IThermexUnitDTO;
}
export const ThermexUnitList: FC<PropsFromParent> = React.memo(({thermexUnit}) => {
  const dispatch = useDispatch();
  const {thermexDataByDevice, pending} = useSelector((store: AppState) => store.thermexUnitReducer);
  const {alarms} = useSelector((store: AppState) => store.alarmsReducer);
  const [selectedUnit, setSelecetUnit] = useState<MeasureType>();
  const [groupedData, setGroupedData] = useState<DataTypeArr>();
  useEffect(() => {
    // Fetch data if unit is changed
    if (thermexUnit && thermexUnit.sensorId) {
      dispatch(getThermexUnitData({deviceId: thermexUnit.deviceId, sensorId: thermexUnit.sensorId}));
      dispatch(getAlarmsBySensorId({deviceId: thermexUnit.deviceId, sensorId: thermexUnit.sensorId}));
    }
    // Clear data in store when leaving page
    return () => {
      dispatch(clearThermexUnitData());
    };
  }, [thermexUnit, dispatch]);

  const alarmKeys = useMemo(() => {
    let keys: {[key: string]: {[key: string]: boolean}} = {};
    alarms.forEach((alarm) => {
      if (alarm && alarm.createdOn) {
        let datetime = DateTime.fromJSDate(alarm.createdOn);
        let dateKey = datetime.toISODate();
        const weekYearKey = `${datetime.weekNumber} - ${datetime.year}`;
        keys[alarm.measureType] = {};
        keys[alarm.measureType][dateKey] = true;
        keys[alarm.measureType][weekYearKey] = true;
        keys[alarm.measureType][dateKey + datetime.hour] = true;
      }
    });
    return keys;
  }, [alarms]);

  const {newestDataPoint, timestamp} = useMemo(() => {
    // take newest datapoint and formats
    let t = thermexDataByDevice.slice().at(-1);
    if (t) {
      return {
        newestDataPoint: {
          vocindex: Math.round(t.vocindex * 10) / 10,
          humidity: Math.round(t.humidity),
          temperature: Math.round(t.temperature * 10) / 10,
          batterylevel: Math.round(t.batterylevel),
        } as ThermexData,
        timestamp: t.timestamp,
      };
    }
    return {newestDataPoint: {} as ThermexData, timestamp: undefined};
  }, [thermexDataByDevice]);

  const setSelectedCallback = useCallback(
    (type: MeasureType) => {
      if (type === selectedUnit) {
        setSelecetUnit(undefined);
      } else {
        setSelecetUnit(type);
      }
    },
    [selectedUnit],
  );

  useEffect(() => {
    // Receives raw data and groups them into the categories
    setGroupedData(GroupDataByKey(thermexDataByDevice));
  }, [thermexDataByDevice]);

  const unitMeasures: IThermexUnitListItem[] = useMemo(() => {
    let listItems: IThermexUnitListItem[] = [];
    if (groupedData && thermexUnit) {
      6;
      const showVocError =
        thermexUnit.alarmsTriggered &&
        thermexUnit.alarmsTriggered[MeasureType[MeasureType.VOCINDEX] as any] === SeverityLevel.Critical;
      const showTempError =
        thermexUnit.alarmsTriggered &&
        thermexUnit.alarmsTriggered[MeasureType[MeasureType.TEMPERATURE] as any] === SeverityLevel.Critical;
      const showHumError =
        thermexUnit.alarmsTriggered &&
        thermexUnit.alarmsTriggered[MeasureType[MeasureType.HUMIDITY] as any] === SeverityLevel.Critical;
      const showBatError =
        thermexUnit.alarmsTriggered &&
        thermexUnit.alarmsTriggered[MeasureType[MeasureType.BATTERYLEVEL] as any] === SeverityLevel.Critical;

      listItems = listItems.concat([
        {
          measureType: MeasureType.VOCINDEX,
          icon: <BasicSvgIcon sx={{fontSize: 30}} component={showVocError ? VocHouseWarning : VocHouse} />,
          name: 'VOC',
          value: newestDataPoint.vocindex,
          showAsWarning: showVocError,
          child: (
            <DrillDownBarChart barChartData={MakeDataSet(groupedData.vocindex, alarmKeys[MeasureType.VOCINDEX])} />
          ),
        } as IThermexUnitListItem,
        {
          measureType: MeasureType.HUMIDITY,
          icon: (
            <BasicSvgIcon
              sx={{fontSize: 30}}
              stroke={invalidColor}
              component={showHumError ? HumidityWarning : Humidity}
            />
          ),
          name: localized('Humidity'),
          value: newestDataPoint.humidity,
          unit: UnitTypeTextFormat.Percentage,
          showAsWarning: showHumError,
          child: (
            <DrillDownBarChart
              barChartData={MakeDataSet(groupedData.humidity, alarmKeys[MeasureType.HUMIDITY], 0)}
              unit={UnitTypeTextFormat.Percentage}
            />
          ),
        } as IThermexUnitListItem,
        {
          measureType: MeasureType.TEMPERATURE,
          icon: <BasicSvgIcon sx={{fontSize: 30}} component={showTempError ? TemperatureWarning : Temperature} />,
          name: localized('Temperature'),
          value: newestDataPoint.temperature,
          unit: UnitTypeTextFormat.DegreeCelsius,
          showAsWarning: showTempError,
          child: (
            <DrillDownBarChart
              barChartData={MakeDataSet(groupedData.temperature, alarmKeys[MeasureType.TEMPERATURE])}
              unit={UnitTypeTextFormat.DegreeCelsius}
            />
          ),
        } as IThermexUnitListItem,
        {
          measureType: MeasureType.BATTERYLEVEL,
          icon: <BasicSvgIcon sx={{fontSize: 30}} component={showBatError ? BatteryStatusWarning : BatteryStatus} />,
          name: localized('BatteryStatus'),
          value: newestDataPoint.batterylevel,
          unit: UnitTypeTextFormat.Percentage,
          showAsWarning: showBatError,
          child: (
            <DrillDownBarChart
              barChartData={MakeDataSet(groupedData.batterylevel, alarmKeys[MeasureType.BATTERYLEVEL], 0)}
              unit={UnitTypeTextFormat.Percentage}
            />
          ),
        } as IThermexUnitListItem,
      ]);
    }
    return listItems;
  }, [
    groupedData,
    thermexUnit,
    newestDataPoint.vocindex,
    newestDataPoint.humidity,
    newestDataPoint.temperature,
    newestDataPoint.batterylevel,
    alarmKeys,
  ]);

  const OpenPanel = useCallback(() => {
    // Display the location editor in a side bar
    dispatch(applySidePanelContent(<EditRoom thermexUnitId={thermexUnit?.sensorId} />));
    dispatch(setSidePanelOpen(true));
  }, [dispatch, thermexUnit]);

  if (!thermexUnit) {
    return null;
  }
  return (
    <Box sx={{marginTop: theme.spacing(4), paddingX: theme.spacing(6)} as SxProps}>
      {pending ? (
        <BasicSpinner />
      ) : (
        <>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'} as SxProps}>
            <Box>
              {localizedInterpolation('LastUpdate', {
                area: timestamp?.toLocaleString(getLanguage(), {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                }),
              })}
            </Box>
            <BorderColorIcon sx={{cursor: 'pointer'}} stroke={''} onClick={OpenPanel} />
          </Box>
          {unitMeasures.map((i, key) => {
            return (
              <ThermexUnitListItem
                key={key}
                data={i}
                selected={i.measureType === selectedUnit}
                callback={setSelectedCallback}
              />
            );
          })}
        </>
      )}
    </Box>
  );
});
