import {Box, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC, useCallback} from 'react';
import {ListItemWidth} from '../../../../styles/styling-constants';
import {FlexRow} from '../../../components/default-components/flex-row';

interface PropsFromsParent<T> {
  columnSizes?: number[];
  objectValues: any[];
  itemSelected?: (item: T) => void;
  contentItem?: T;
  maxWidth?: (number | undefined)[];
  showSelected?: boolean;
  propStyle?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      height: 60,
      alignItems: 'center',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(3, 2),
      width: ListItemWidth,
      borderLeft: 'solid 6px',
      borderColor: 'transparent',
    },
    itemText: {
      fontSize: theme.typography.body2.fontSize,
      color: theme.colors.listTable.ListTableTextColor,
    },
    showCursor: {
      cursor: 'pointer',
    },
    showSelected: {
      borderLeft: 'solid 6px',
      borderColor: theme.palette.text.secondary,
    },
    defaultBackground: {
      backgroundColor: theme.colors.listTable.ListTableBgColor,
    },
  }),
);

type Props = PropsFromsParent<any>;

export const ListTableItem: FC<Props> = React.memo(
  ({columnSizes, objectValues, itemSelected, contentItem, maxWidth, showSelected, propStyle}) => {
    const classes = useStyles();
    const itemSelectedLocal = useCallback(() => {
      if (itemSelected && contentItem) {
        itemSelected(contentItem);
      }
    }, [itemSelected, contentItem]);

    return (
      <FlexRow
        sx={{boxShadow: 4}}
        onClick={itemSelectedLocal}
        className={clsx(
          classes.itemContainer,
          {
            [classes.showCursor]: itemSelected,
            [classes.showSelected]: showSelected,
          },
          propStyle ? propStyle : classes.defaultBackground,
        )}>
        {objectValues.map((objectValue, index) => {
          const flex = columnSizes && columnSizes[index];
          let width = maxWidth && maxWidth[index];
          if (!width && (index === 0 || index === objectValues.length - 1)) {
            width = 50;
          }

          return (
            <Box key={index} flex={flex} maxWidth={width} display={'flex'} className={classes.itemText}>
              {objectValue}
            </Box>
          );
        })}
      </FlexRow>
    );
  },
);
