import React, {FC, memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {OrganizationDTO} from '../../../../api/api';
import {useAuth} from '../../../../auth/auth-provider';
import {localized} from '../../../../i18n/i18n';
import {getOrganizations} from '../../../../state/features/organization/operation';
import {
  selectOrganisations,
  selectOrganiszationDropdownOptions,
} from '../../../../state/features/organization/organization-slice';
import {
  selectOrganizationSettingDropdown,
  setOrganizationSetting,
} from '../../../../state/features/settings/settings-slice';
import {getUserByAuthId} from '../../../../state/features/users/operation';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {BasicDropdown, DropdownOption} from '../baisc-dropdown/basic-dropdown';

interface Props {
  show?: boolean;
}

export const OrganizationPicker: FC<Props> = memo((props) => {
  const dispatch = useDispatch();
  const {validateOrganizationPermissions} = useAuth();
  const options = useSelector(selectOrganiszationDropdownOptions);
  const organizations = useSelector(selectOrganisations);
  const selectedOrganizationOption = useSelector(selectOrganizationSettingDropdown);
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);

  const onOrganizationChange = useCallback(
    (option: DropdownOption) => {
      const org = organizations.find((org) => org.id === option.value);
      dispatch(setOrganizationSetting(org as OrganizationDTO));
    },
    [dispatch, organizations],
  );

  useEffect(() => {
    validateOrganizationPermissions();
  }, [selectedOrganization, validateOrganizationPermissions]);

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserByAuthId());

    if (!selectedOrganization && organizations.length >= 1) {
      dispatch(setOrganizationSetting(organizations[0] as OrganizationDTO));
    }
  }, [dispatch, selectedOrganization, organizations]);

  return props.show && options.length !== 0 ? (
    <BasicDropdown
      label={localized('Organization')}
      labelSx={{
        backgroundColor: theme.colors.sideBar.sideBarProfileBackgroundColor,
        color: theme.colors.sideBar.sidebarTextColor,
      }}
      sx={{width: 200, padding: 0, color: theme.colors.sideBar.sidebarTextColor}}
      value={selectedOrganizationOption}
      onChange={onOrganizationChange}
      options={options}
    />
  ) : null;
});
