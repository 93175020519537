import {createAsyncThunk} from '@reduxjs/toolkit';
import {AlarmClient} from 'src/api/api';
import {alarmClient} from 'src/state/api-clients';
import {AsyncOperationHandler} from 'src/utilities/platform-helpers/api-helper';

export const getAlarmsBySensorId = createAsyncThunk(
  'alarms/getAlarmsBySensorId',
  (payload: {deviceId: string | undefined; sensorId: string | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as AlarmClient).getAlarmsBySensorId(payload.deviceId, payload.sensorId),
      alarmClient,
    );
  },
);
