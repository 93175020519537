import styled from '@emotion/styled';
import {Box, ListItem, ListItemIcon, ListItemText, Theme, Typography} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {theme} from '../../../styles/theme';

interface PropsFromParent {
  selected: boolean;
  icon?: JSX.Element;
  identifier: any;
  linkTo: string;
  text?: string;
  // eslint-disable-next-line no-unused-vars
  onMenuItemClicked?: (identifier: string) => void;
}
const SelectedBackground = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixFirefox: {
      color: 'darkslategrey',
      textDecoration: 'none',
    },
    // Sets stroke on all child components - Handle SvgIcon color
    selected: {
      backgroundColor: '#000000',
      '& *': {
        stroke: theme.colors.sideBar.sideBarIconSelected,
      },
    },
    listItem: {},
  }),
);

const StyledListItem = withStyles({
  root: {
    padding: '8px 0 8px 0px',
    justifyContent: 'center',
  },
})(ListItem);

export const DrawerItem: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();

  const onMenuItemClicked = () => {
    props.onMenuItemClicked && props.onMenuItemClicked(props.identifier);
  };

  return (
    <Link to={props.linkTo} className={classes.fixFirefox}>
      <StyledListItem selected={props.selected} onClick={onMenuItemClicked} key={props.identifier}>
        <Box sx={{minWidth: 79, justifyContent: 'center', display: 'flex'}}>
          <SelectedBackground
            className={clsx({
              [classes.selected]: props.selected,
              ['']: !props.selected,
            })}>
            <ListItemIcon
              className={classes.listItem}
              sx={{display: 'flex', justifyContent: 'center', color: 'blue', fill: 'blue'}}>
              {props.icon}
            </ListItemIcon>
          </SelectedBackground>
        </Box>
        <ListItemText
          primary={
            <Typography
              sx={{color: props.selected ? theme.colors.inputTextColor : theme.colors.sideBar.sidebarTextColor}}>
              {props.text}
            </Typography>
          }
        />
      </StyledListItem>
    </Link>
  );
});
