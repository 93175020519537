import React, {FC, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {OrganizationRole} from 'src/api/api';
import {getLocationUserByOrganizationId} from 'src/state/features/location-organization-user/operation';
import {getLocations} from 'src/state/features/location/operation';
import {applySidePanelContent, setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {Pages} from 'src/utilities/enums/pages';
import {TxButton} from 'src/view/components/default-components/buttons/tx-button';
import {StyledTab} from 'src/view/components/default-components/page-header.tsx/page-header-tab';
import {PageHeaderTabContainer} from 'src/view/components/default-components/page-header.tsx/page-header-tab-container';
import {useAuth} from '../../../auth/auth-provider';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {getOrganizations} from '../../../state/features/organization/operation';
import {selectPageSettingByKey} from '../../../state/features/page-header-setting/page-header-setting-slice';
import {ViewType} from '../../../state/features/page-header-setting/types';
import {getUsers} from '../../../state/features/users/operation';
import {AppState} from '../../../state/store';
import {FlexColumn} from '../../components/default-components/flex-column';
import {FlexRow} from '../../components/default-components/flex-row';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../components/default-components/page-header.tsx/page-header-content';
import {PageHeaderSearch} from '../../components/default-components/page-header.tsx/page-header-search';
import {PageHeaderSort} from '../../components/default-components/page-header.tsx/page-header-sort';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {BasicSpinner} from '../../components/default-components/spinner/basic-spinner';
import {CreateUser} from './create-user/create-user';
import {UserManagementListView} from './user-management-list/user-management-list-view';

export const UserManagement: FC = React.memo(() => {
  const {isGlobalAdmin, isAdmin} = useAuth();
  const dispatch = useDispatch();
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const pageSetting = useSelector(selectPageSettingByKey(Pages.UserManagement));
  const showSpinner = useSelector(
    (store: AppState) => store.usersReducer.pending && store.usersReducer.users.length < 1,
  );
  const openCreateUser = useCallback(() => {
    // Display the user editor in a side bar
    dispatch(applySidePanelContent(<CreateUser />));
    dispatch(setSidePanelOpen(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOrganizations());
    if (selectedOrganization) {
      dispatch(getUsers(selectedOrganization?.id));
      dispatch(getLocationUserByOrganizationId(selectedOrganization?.id));
      dispatch(getLocations(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  return (
    <PageTitle title={localized('Users')}>
      <PageHeader
        area="userManagement"
        title={localized('Users')}
        postfix={
          isAdmin && (
            <TxButton
              onClick={openCreateUser}
              text={'+ ' + localizedInterpolation('CreateNewInt', {area: localized('User')})}
            />
          )
        }>
        <PageHeaderContent>
          <FlexColumn flex={1}>
            <FlexRow flex={1} flexWrap="wrap">
              <PageHeaderTabContainer initValue={OrganizationRole.Janitor}>
                {isGlobalAdmin && <StyledTab value={OrganizationRole.Admin} label={localized('Admins')} />}
                {(isGlobalAdmin || isAdmin) && (
                  <StyledTab value={OrganizationRole.SuperUser} label={localized('SuperUser')} />
                )}

                {<StyledTab value={OrganizationRole.Technician} label={localized('Technician')} />}
                {<StyledTab value={OrganizationRole.Janitor} label={localized('Janitors')} />}
              </PageHeaderTabContainer>
              <PageHeaderSearch />
              <PageHeaderSort />
              {/* Implement when box view in introduced */}
              {/* <PageHeaderToggleView /> */}
            </FlexRow>
          </FlexColumn>
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer>
        {showSpinner ? (
          <BasicSpinner />
        ) : !pageSetting || pageSetting.itemView === ViewType.BoxView ? (
          <UserManagementListView />
        ) : pageSetting.itemView === ViewType.ListView ? (
          <UserManagementListView />
        ) : null}
      </PageContentContainer>
    </PageTitle>
  );
});
