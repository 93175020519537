import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateOranizationUserRealation} from 'src/state/features/location-organization-user/operation';
import {selectOrganizationUserByUserId, selectUserById} from 'src/state/features/users/users-slice';
import {setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {SidePanelButton} from 'src/view/components/default-components/side-panel/side-panel-button';
import {SidePanelTitel} from 'src/view/components/default-components/side-panel/side-panel-titel';
import {localized} from '../../../../i18n/i18n';
import {AppState} from '../../../../state/store';
import {LocationManagementListView} from './location-user-relation-list-view';
interface PropsFromParent {
  userId?: number;
}

export const LocationManagement: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  // Get user
  const {pending} = useSelector((store: AppState) => store.usersReducer);
  const user = useSelector(selectUserById(props.userId!));
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const organizationUser = useSelector(selectOrganizationUserByUserId(selectedOrganization?.id, props.userId!));
  const editUserLocations = useSelector(
    (store: AppState) => store.locationOrganizationUserReducer.editLocationOrganizationUser,
  );
  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);

  const saveLocationManagement = useCallback(() => {
    dispatch(
      updateOranizationUserRealation({
        list: editUserLocations.ids,
        orgUserId: organizationUser?.id,
      }),
    );
    closeSidePanel();
  }, [closeSidePanel, dispatch, editUserLocations.ids, organizationUser?.id]);

  return (
    <>
      <SidePanelTitel title={localized('Locations')} subTitle={localized('For') + ' ' + user?.name} />
      <LocationManagementListView organizationUserId={organizationUser?.id} />
      <SidePanelButton
        onClick={saveLocationManagement}
        buttonColor={'primary'}
        disabled={pending}
        text={localized('SaveAndClose')}
      />
    </>
  );
});
