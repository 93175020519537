import React, {FC} from 'react';
import {BasicButton, BasicButtonProps} from '../buttons/basic-button';

export const TxButton: FC<BasicButtonProps> = React.memo((props) => {
  return (
    <BasicButton
      {...props}
      sx={{
        ...props.sx,
        borderRadius: 0,
        marginY: 2,
        minWidth: 250,
        fontWeight: 'bold',
        paddingX: 3,
        paddingY: 1,
        fontSize: 12,
      }}
      variant="outlined"
    />
  );
});
