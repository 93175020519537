import {Box, SortDirection, SxProps} from '@mui/material';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Pages} from 'src/utilities/enums/pages';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {filterListItems, sortListItems} from '../../../../utilities/helpers/general-helpers';
import {VirtualizedListTable} from '../../../components/default-components/virtual-list-table/virtual-list-table';
import {UserManagementListItem} from './user-management-list-item';

export const UserManagementListView: FC = React.memo(() => {
  const [filteredItems, setFilteredItems] = useState<number[]>([]);
  const searchString = useSelector((store: AppState) => store.pageSettingReducer.searchString);
  const users = useSelector((store: AppState) => store.usersReducer.users);
  const pageSetting = useSelector(selectPageSettingByKey(Pages.UserManagement));
  const columnSizes = useMemo((): number[] => [0, 4, 3, 2, 2, 0], []);

  useEffect(() => {
    if (pageSetting?.tabIndex) {
      const pageTabFiltered = users.filter((i) =>
        i.organizationUser?.some((j) => j.organizationRole === pageSetting?.tabIndex),
      );
      const filteredArray = filterListItems(pageTabFiltered, ['name'], searchString);
      if (pageSetting) {
        setFilteredItems(
          sortListItems(filteredArray, 'name', pageSetting.sort as SortDirection).map((user) => user.id!),
        );
      }
    }
  }, [pageSetting, searchString, users]);

  const renderUserItem = useCallback(
    ({key, index, style}: any) => {
      if (index > filteredItems.length - 1) {
        return null;
      }
      return (
        <Box key={key} style={style} sx={{paddingLeft: 6, paddingRight: 6}}>
          <UserManagementListItem columnSizes={columnSizes} userId={filteredItems[index]} />
        </Box>
      );
    },
    [filteredItems, columnSizes],
  );

  return (
    <Box sx={{marginTop: theme.spacing(3)} as SxProps}>
      <VirtualizedListTable items={filteredItems} columnSizes={columnSizes} rowRenderer={renderUserItem} />
    </Box>
  );
});
