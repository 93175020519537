import {Box, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {searchChanged} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {AppState} from '../../../../state/store';
import {SearchInput} from '../search-input/search-input';
import {usePageHeader} from './page-header';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '280px',
        marginRight: 0,
      },
    },
  }),
);

export const PageHeaderSearch: FC = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {areaId} = usePageHeader();
  const searchString = useSelector((store: AppState) => store.pageSettingReducer.searchString);

  const searchChangedLocal = useCallback(
    (newSearchString: string) => {
      dispatch(searchChanged({value: newSearchString, key: areaId}));
    },
    [dispatch, areaId],
  );

  return (
    <Box marginLeft={4} marginRight={4} className={classes.searchBox}>
      <SearchInput value={searchString} onChange={searchChangedLocal} />
    </Box>
  );
});
