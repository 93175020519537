import {Typography} from '@mui/material';
import React, {FC, MouseEvent, useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {LocationDTO} from 'src/api/api';
import {localized, localizedInterpolation} from 'src/i18n/i18n';
import {deleteLocation} from 'src/state/features/location/operation';
import {theme} from 'src/styles/theme';
import {FlexColumn} from 'src/view/components/default-components/flex-column';
import {StatusIconBySeverity} from 'src/view/components/icons/status-icon';
import {StyledPopoverItem, TxStyledPopover} from 'src/view/components/popover/tx-styled-popover';
import {ReactComponent as House} from '../../../../assets/house_icon.svg';
import {ReactComponent as MoreDetails} from '../../../../assets/more_details_dots.svg';
import {ReactComponent as SkyScraper} from '../../../../assets/skyscraper_logo.svg';
import {useAuth} from '../../../../auth/auth-provider';
import {selectLocationById} from '../../../../state/features/location/location-slice';
import {applySidePanelContent, setSidePanelOpen} from '../../../../state/features/webapp/webapp-slice';
import {routes} from '../../../../state/routes';
import {BasicSvgIcon} from '../../../components/default-components/basic-svg-icon';
import {ListTableItem} from '../../../components/default-components/list-table/list-table-item';
import {LocationEdit} from '../location-edit/location-edit';

interface PropsFromParent {
  locationId: number;
  columnSizes?: number[];
}

export const LocationListItem: FC<PropsFromParent> = React.memo(({locationId, ...props}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const item = useSelector(selectLocationById(locationId));
  const {isAdmin} = useAuth();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorElRef = useRef<Element | null>();

  const goToLocationDetail = useCallback(
    () => {
      navigate(`${routes.locations}/${locationId}/detail`);
    }, // eslint-disable-next-line
    [locationId],
  );

  const editLocation = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsPopoverOpen(false);
      anchorElRef.current = e.currentTarget;

      // Display the location editor in a side bar
      dispatch(applySidePanelContent(<LocationEdit locationId={locationId} />));
      dispatch(setSidePanelOpen(true));
    },
    [dispatch, locationId],
  );

  const deleteLocationPressed = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (
        (await confirm(
          localizedInterpolation('AreYouSureYouWantToDelete', {
            area: localized('TheLocation').toLowerCase(),
          }),
        )) &&
        item
      ) {
        setIsPopoverOpen(false);
        anchorElRef.current = e.currentTarget;
        dispatch(deleteLocation({location: item as LocationDTO}));
      }
    },
    [dispatch, locationId],
  );

  const togglePopover = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      anchorElRef.current = e.currentTarget;

      setIsPopoverOpen(!isPopoverOpen);
    },
    [isPopoverOpen],
  );

  const popoverItems = useMemo(
    () => (
      <FlexColumn>
        <StyledPopoverItem onClick={editLocation}>{localized('Edit')}</StyledPopoverItem>
        <StyledPopoverItem onClick={deleteLocationPressed}>{localized('Delete')}</StyledPopoverItem>
        {/* TODO: Remove until implementation */}
        {/* <StyledPopoverItem>{localized('LogRegistration')}</StyledPopoverItem>
        <StyledPopoverItem sx={{borderBottom: 0}}>{localized('SeeLogDetails')}</StyledPopoverItem> */}
      </FlexColumn>
    ),
    [deleteLocationPressed, editLocation],
  );

  // If unable to find element, don't show anything
  if (!item) {
    return null;
  }

  return (
    <>
      <ListTableItem
        {...props}
        maxWidth={[undefined, undefined, 40, 200, 40, 200, 20]}
        itemSelected={goToLocationDetail}
        contentItem={item}
        objectValues={[
          <StatusIconBySeverity severityLevel={item.severityLevel} />,
          <Typography
            sx={{fontSize: theme.typography.body2, fontWeight: theme.typography.fontWeightBold, alignSelf: 'center'}}>
            {item.name}
          </Typography>,
          <BasicSvgIcon stroke={''} component={SkyScraper} />,
          <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>{item.city}</Typography>,
          <BasicSvgIcon stroke={''} component={House} />,
          <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>
            {item.numberOfThermexUnits + ' ' + localized('Units')}
          </Typography>,
          isAdmin && <BasicSvgIcon stroke={''} component={MoreDetails} onClick={togglePopover} />,
        ]}
      />
      <TxStyledPopover popoverStatus={isPopoverOpen} togglePopover={togglePopover} anchorEl={anchorElRef}>
        {popoverItems}
      </TxStyledPopover>
    </>
  );
});
