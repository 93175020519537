import styled from '@emotion/styled';
import {Box, BoxProps, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {headerBackgroundColor, secondaryTextColor} from '../../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: headerBackgroundColor,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    title: {
      fontSize: theme.typography.h3.fontSize,
      color: secondaryTextColor,
    },
  }),
);

interface PropsFromParent {}

// export const PageHeaderContent: FC<PropsFromParent> = React.memo((props) => {
//   const classes = useStyles();

//   return <FlexRow className={classes.root}>{props.children}</FlexRow>;
// });

export const PageHeaderContent = styled(Box)<BoxProps>(({theme}: any) => ({
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
}));
