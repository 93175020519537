import {Grid} from '@mui/material';
import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUser, NotificationSubscription, OrganizationRole, OrganizationUser, User, UserRole} from 'src/api/api';
import {selectUserById} from 'src/state/features/users/users-slice';
import {setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {theme} from 'src/styles/theme';
import {getOrganizationRoleDropdownOptions} from 'src/utilities/platform-helpers/enum-helper';
import {nameof} from 'src/utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'src/utilities/platform-helpers/validator-helper';
import {Form} from 'src/view/components/default-components/form/form';
import {SidePanelButton} from 'src/view/components/default-components/side-panel/side-panel-button';
import {SidePanelCheckboxInput} from 'src/view/components/default-components/side-panel/side-panel-checkbox-input';
import {SidePanelSelectInput} from 'src/view/components/default-components/side-panel/side-panel-select-input';
import {SidePanelTextInput} from 'src/view/components/default-components/side-panel/side-panel-text-input';
import {SidePanelTitel} from 'src/view/components/default-components/side-panel/side-panel-titel';
import {useAuth} from '../../../../auth/auth-provider';
import {localized} from '../../../../i18n/i18n';
import {saveUser, updateUser} from '../../../../state/features/users/operation';
import {AppState} from '../../../../state/store';
import {FlexRow} from '../../../components/default-components/flex-row';
interface PropsFromParent {
  userId?: number;
}

export const CreateUser: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const {isGlobalAdmin} = useAuth();
  // Get user
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const {pending} = useSelector((store: AppState) => store.usersReducer);
  const user = useSelector(selectUserById(props.userId!));
  const organizationRoleOptions = useMemo(() => getOrganizationRoleDropdownOptions(), []);
  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);

  const save = useCallback(
    (data: any) => {
      if (user) {
        user.name = data.name;
        // user.role = data.role ? UserRole.GlobalAdmin : UserRole.User;
        const newSubsciption = user.notificationSubscription
          ? user.notificationSubscription
          : new NotificationSubscription();
        newSubsciption.notifyEmail = data.notificationSubscription.notifyEmail;
        user.notificationSubscription = newSubsciption;
        user.organizationUser = user.organizationUser?.map((orgUser) => {
          if (orgUser.organizationId === selectedOrganization?.id) {
            orgUser.organizationRole = data.organizationRole as OrganizationRole;
          }
          return orgUser;
        });
        dispatch(updateUser(user));
      } else {
        const orgUser = new OrganizationUser();
        orgUser.init({
          organizationId: selectedOrganization?.id,
          organizationRole: (data.organizationRole as OrganizationRole) ?? OrganizationRole.Admin,
        } as OrganizationUser);
        const newUser = new User();
        const newSubsciption = new NotificationSubscription();
        newSubsciption.notifyEmail = data.notificationSubscription.notifyEmail;
        newUser.init(data);

        newUser.role = data.role ? UserRole.GlobalAdmin : UserRole.User;
        newUser.notificationSubscription = newSubsciption;

        newUser.organizationUser = [orgUser];

        dispatch(saveUser(newUser));
      }
      closeSidePanel();
    },
    [closeSidePanel, dispatch, selectedOrganization?.id, user],
  );

  // Mapping the user object to form values as organization role value is in a nested object
  const defaultValues = useMemo(() => {
    if (selectedOrganization && user?.organizationUser) {
      var organizationRole = user.organizationUser.find(
        (orgUser) => orgUser.organizationId === selectedOrganization?.id,
      );
      return {...user, organizationRole: organizationRole?.organizationRole, role: user?.role === UserRole.GlobalAdmin};
    }
    return {role: false};
  }, [selectedOrganization, user]);

  return (
    <>
      <SidePanelTitel
        title={defaultValues ? localized('Edit') : localized('CreateUser')}
        subTitle={defaultValues ? '' : localized('CreateUserFormSubTitle')}
      />
      <Form onSubmit={save} sx={{maxWidth: 400, marginTop: theme.spacing(3)}} defaultValues={defaultValues}>
        <SidePanelTextInput name={nameof<IUser>('name')} label={localized('Name')} validators={requiredValidator} />
        <SidePanelTextInput name={nameof<IUser>('email')} label={localized('Email')} validators={requiredValidator} />
        <SidePanelCheckboxInput name={'notificationSubscription.notifyEmail'} label={localized('Email')} />
        <FlexRow justifyContent="space-between" flexWrap="wrap">
          <Grid xs={3} item={true}>
            <SidePanelSelectInput
              name={'organizationRole'}
              noDefault
              options={organizationRoleOptions}
              validators={requiredValidator}
              label={localized('OrganizationRole')}
            />
          </Grid>

          {isGlobalAdmin && !props.userId && (
            <SidePanelCheckboxInput name={nameof<IUser>('role')} label={localized('GlobalAdmin')} />
          )}
        </FlexRow>
        <SidePanelButton type="submit" buttonColor={'primary'} disabled={pending} text={localized('SaveAndClose')} />
      </Form>
    </>
  );
});
