import {TextField} from '@mui/material';
import React, {FC, memo} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    valueAsNumber: boolean;
    valueAsDate: boolean;
    setValueAs: (value: any) => any;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

export const TextInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <Controller
      name={props.name}
      control={control}
      rules={props.validators}
      render={({field}) => (
        <TextField
          {...field}
          disabled={props.disabled}
          sx={{marginTop: 1, marginBottom: 2}}
          InputLabelProps={{shrink: true}}
          value={field.value ?? ''}
          label={props.label}
          error={!!errors[props.name]}
          helperText={!!errors[props.name] && errors[props.name].message}
        />
      )}
    />
  );
});
