import {TextField} from '@mui/material';
import React, {FC, memo, useCallback} from 'react';
import {Controller, ControllerProps, Message, useFieldArray, useFormContext, ValidationRule} from 'react-hook-form';
import {BasicButton} from '../buttons/basic-button';
import {FlexColumn} from '../flex-column';

interface Props {
  name: string;
  nestedName: string;
  label?: string;
  disabled?: boolean;
  buttonText: string;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    valueAsNumber: boolean;
    valueAsDate: boolean;
    setValueAs: (value: any) => any;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

export const FieldArrayIput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const {fields, append, prepend, remove, swap, move, insert} = useFieldArray({
    control,
    name: props.name,
  });

  // Create a new object and insert it into the list
  const appendRow = () => {
    let obj: any = {};
    obj[props.nestedName] = '';
    append(obj);
  };

  const buildField = useCallback(
    ({field}: Parameters<ControllerProps['render']>[0]) => (
      <TextField
        {...field}
        sx={{marginTop: 1, marginBottom: 2}}
        InputLabelProps={{shrink: true}}
        value={field.value ?? ''}
        label={props.label}
        error={!!errors[props.name]}
        helperText={!!errors[props.name] && errors[props.name].message}
      />
    ),
    [errors, props.label, props.name],
  );

  return (
    <>
      <FlexColumn gap={2}>
        <BasicButton
          sx={{margin: '8px 8px 8px 0'}}
          buttonColor={'secondary'}
          onClick={appendRow}
          text={props.buttonText}
        />
        {fields.map((item, index) => {
          return (
            <Controller
              key={item.id}
              // Path to the nested property
              name={`${props.name}[${index}].${props.nestedName}` as string}
              control={control}
              render={buildField}
            />
          );
        })}
      </FlexColumn>
    </>
  );
});
