import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Avatar, Box, Divider, Drawer, IconButton, Theme, Typography} from '@mui/material';
import List from '@mui/material/List';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {OrganizationRole} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import {ReactComponent as LogOut} from '../../../assets/baseline-logout-24px.svg';
import {ReactComponent as LocationLogo} from '../../../assets/location_logo.svg';
import {ReactComponent as NotificationLogo} from '../../../assets/notification_logo.svg';
import {ReactComponent as OrganizationLogo} from '../../../assets/organization_logo.svg';
import Logo from '../../../assets/thermex_white@2x.png';
import {ReactComponent as UserLogo} from '../../../assets/user_logo.svg';
import {useAuth} from '../../../auth/auth-provider';
import {localized} from '../../../i18n/i18n';
import {toggleDrawer} from '../../../state/features/webapp/webapp-slice';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {theme} from '../../../styles/theme';
import {BasicSvgIcon} from '../../components/default-components/basic-svg-icon';
import {OrganizationPicker} from '../../components/default-components/organization-picker/organization-picker';
import {BasicSpinner} from '../../components/default-components/spinner/basic-spinner';
import {DrawerItem} from './drawer-item';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerBackground: {
      backgroundColor: theme.colors.sideBar.sidebarBackgroundColor,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10),
      },
    },
    logoContainer: {
      height: '70px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    toggleBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      backgroundColor: theme.colors.sideBar.sideBarProfileBackgroundColor,
    },
    spacer: {
      flex: 1,
    },
    userField: {
      backgroundColor: 'rgb(245, 244, 244)',
      width: '100%',
    },
    userContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    avatar: {
      margin: theme.spacing(2),
    },
    avatarName: {
      margin: '16px 0',
    },
    logoutOpen: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
      '& img': {
        marginLeft: '8px',
      },
    },
    logoutClosed: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '32px 0',
    },
    drawerIcon: {
      transform: 'scale(1.2)',
    },
  }),
);

const toWhiteFilter = 'invert(95%) sepia(44%) saturate(25%) hue-rotate(164deg) brightness(106%) contrast(95%)';

export const MenuDrawer: FC = React.memo(() => {
  const {user, logout, isAdmin, isGlobalAdmin, loading} = useAuth();
  const handleLogout = useCallback(() => logout(), [logout]);
  let location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: AppState) => state.webAppReducer.open);
  const layoutClasses = useLayoutStyles();

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };
  const notificationLogo = useMemo(
    () => <BasicSvgIcon component={NotificationLogo} className={classes.drawerIcon} />,
    [classes],
  );
  const userLogo = useMemo(() => <BasicSvgIcon component={UserLogo} className={classes.drawerIcon} />, [classes]);
  const organizationLogo = useMemo(
    () => <BasicSvgIcon component={OrganizationLogo} className={classes.drawerIcon} />,
    [classes],
  );
  const locationIcon = useMemo(
    () => <BasicSvgIcon component={LocationLogo} className={classes.drawerIcon} />,
    [classes],
  );
  const locationBaseString = '/' + location.pathname.split('/')[1];

  return (
    <div>
      <Drawer
        variant="permanent"
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawerBackground, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        {/* This part isn't in the design, but keep, since it might be needed */}
        {/* <div className={classes.logoContainer}>
          <Link to="/">
            {open ? <img width={160} src={Logo} alt="Logo" /> : <img width={40} src={LogoSmall} alt="Logo" />}
          </Link>
        </div>

        <Divider /> */}

        <List sx={{marginTop: '32px'}}>
          <DrawerItem
            linkTo={routes.locations}
            icon={locationIcon}
            text={localized('Locations')}
            selected={locationBaseString === routes.locations}
            identifier={routes.locations}
          />
          {isAdmin && (
            <DrawerItem
              linkTo={routes.userManagement}
              icon={userLogo}
              text={localized('Users')}
              selected={locationBaseString === routes.userManagement}
              identifier={routes.userManagement}
            />
          )}

          <DrawerItem
            linkTo={routes.notifications}
            icon={notificationLogo}
            text={localized('Notifications')}
            selected={locationBaseString === routes.notifications}
            identifier={routes.notifications}
          />
          {isGlobalAdmin && (
            <DrawerItem
              linkTo={routes.organization}
              icon={organizationLogo}
              text={localized('Organization')}
              selected={locationBaseString === routes.organization}
              identifier={routes.organization}
            />
          )}
        </List>

        <div className={classes.spacer}></div>

        {/* This part isn't in the design, but keep, since it might be needed */}
        <Box sx={{width: '100%', backgroundColor: theme.colors.sideBar.sideBarProfileBackgroundColor}}>
          <Divider />
          {open ? (
            !loading ? (
              <div className={classes.userContainer}>
                <Avatar className={classes.avatar} src={user?.picture} />
                <div className={classes.avatarName}>
                  <Typography color={theme.colors.sideBar.sidebarTextColor} variant="body2">
                    {user?.name}
                  </Typography>
                  <Typography color={theme.colors.sideBar.sidebarTextColor} variant="body2">
                    {isGlobalAdmin ? user?.tokenRole : OrganizationRole[user?.orgainzationRole as OrganizationRole]}
                  </Typography>
                  <div onClick={handleLogout} className={classes.logoutOpen}>
                    <Typography color={theme.colors.sideBar.sidebarTextColor} variant="body2">
                      {localized('LogOut')}
                    </Typography>
                    <BasicSvgIcon
                      component={LogOut}
                      sx={{
                        width: 20,
                        marginLeft: 1,
                        filter: toWhiteFilter,
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <BasicSpinner />
            )
          ) : (
            <div onClick={handleLogout} className={classes.logoutClosed}>
              <BasicSvgIcon
                component={LogOut}
                sx={{
                  width: 25,
                  filter: toWhiteFilter,
                }}
              />
              <Typography color={theme.colors.sideBar.sidebarTextColor} variant="body2">
                {localized('LogOut')}
              </Typography>
            </div>
          )}
          <OrganizationPicker show={open} />
        </Box>

        <Divider color={theme.colors.sideBar.sidebarTextColor} />

        <div className={classes.toggleBar}>
          <IconButton onClick={handleToggleDrawer} size="large">
            {open ? (
              <ChevronLeftIcon htmlColor={theme.colors.sideBar.sidebarTextColor} />
            ) : (
              <ChevronRightIcon htmlColor={theme.colors.sideBar.sidebarTextColor} />
            )}
          </IconButton>
        </div>
        <div className={classes.logoContainer}>
          <Link to="/">
            {open ? <img width={80} src={Logo} alt="Logo" /> : <img width={60} src={Logo} alt="Logo" />}
          </Link>
        </div>
      </Drawer>
    </div>
  );
});
