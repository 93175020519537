// Theme colors
export const primaryColor = '#212121';
export const primaryTextColor = '#212121';

export const secondaryColor = '#25623E';
export const secondaryTextColor = '#98D09E';

export const backgroundColor = '#F9F9F9';
export const validColor = '#3D7353';
export const invalidColor = '#BB2525';
export const warningColor = '#F9C98A';
export const whiteColor = '#FFFFFF';

export const disableColor = '#CECECE';
// Component colors

// Header
export const headerBackgroundColor = '#F9F9F9';

// Sidebar
export const sidebarBackgroundColor = '#212121';
export const sidebarIconColor = '#FFFFFF';
export const sidebarBackgroundSelectedColor = '#ECECEC';
export const sidebarSelectedBorder = '#B8B9C8';
export const sideBarProfileBackgroundColor = '#212121';
export const sideBarIconSelected = '#98D09E';

// Content Wrapper

// Basic Card
export const basicCardBgColor = '#FFFFFF';
export const basicCardBoxShadow = '0px 3px 6px #00000029';
// Button

// Input

// Toggle

// Switch

// Info item

// Textfield

// Checkbox Filter
export const checkboxFilterBg = '#FAFAFA';

// SearchInput
export const searchBgColor = '#E6E6E6';

// List Table

// Info item 2
export const infoItem2BackgroundColor = '#FAFAFA';

// Side panel
export const sidePanelTextInputLabelColor = "#98D09E";
export const sidePanelSelectedColor = "#98D09E";
export const sidePanelNotSelectedColor = "#568569";

// Popover
export const popoverBorderColor = validColor;
