import {Box, TextField} from '@mui/material';
import React, {FC, memo, useCallback, useEffect, useState} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {whiteColor} from 'src/styles/color-constants';
import {preventNonNumericalInput} from 'src/utilities/platform-helpers/number-helper';

interface Props {
  label?: string;
  disabled?: boolean;
  sxContainer?: any;
  value?: string;
  onChange?: (text: string) => void;
  onBlur?: (text?: string) => void;
}
export const PlainInputText: FC<Props> = memo(({value, onChange, sxContainer, disabled, label, onBlur}) => {
  const [localText, setLocalText] = useState<string | undefined>('');
  const textChangedLocal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
      setLocalText(event.target.value);
    },
    [onChange],
  );

  const onBlurLocal = useCallback(() => {
    if (onBlur) {
      onBlur(localText);
    }
  }, [localText, onBlur]);

  useEffect(() => {
    // Set init value
    setLocalText(value);
  }, [value]);

  return (
    <TextField
      disabled={disabled}
      sx={sxContainer}
      InputLabelProps={{shrink: true}}
      InputProps={{disableUnderline: true}}
      value={localText}
      label={label}
      onChange={textChangedLocal}
      onBlur={onBlurLocal}
      variant="standard"
    />
  );
});
