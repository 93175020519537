import {Grid} from '@mui/material';
import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ILocationDTO, LocationDTO} from '../../../../api/api';
import {localized} from '../../../../i18n/i18n';
import {selectLocationById} from '../../../../state/features/location/location-slice';
import {updateLocation} from '../../../../state/features/location/operation';
import {setSidePanelOpen} from '../../../../state/features/webapp/webapp-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {nameof} from '../../../../utilities/platform-helpers/nameof-helper';
import {requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {FlexRow} from '../../../components/default-components/flex-row';
import {Form} from '../../../components/default-components/form/form';
import {SidePanelTextInput} from '../../../components/default-components/side-panel/side-panel-text-input';
import {SidePanelTitel} from '../../../components/default-components/side-panel/side-panel-titel';

interface PropsFromParent {
  locationId: number;
}

export const LocationEdit: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const item = useSelector(selectLocationById(props.locationId));
  const savePending = useSelector((store: AppState) => store.locationReducer.pending);

  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);

  const save = useCallback(
    (location: LocationDTO) => {
      dispatch(updateLocation({location, callbackOnSuccess: closeSidePanel}));
    },
    [closeSidePanel, dispatch],
  );

  return (
    <>
      <SidePanelTitel title={localized('Edit')} subTitle={item?.address ?? ''} />
      <Form onSubmit={save} sx={{maxWidth: 400, marginTop: theme.spacing(3)}} defaultValues={item}>
        <SidePanelTextInput
          name={nameof<ILocationDTO>('name')}
          label={localized('Name')}
          validators={requiredValidator}
        />
        <SidePanelTextInput
          name={nameof<ILocationDTO>('address')}
          label={localized('Address')}
          validators={requiredValidator}
        />
        <FlexRow justifyContent="space-between" flexWrap="wrap">
          <Grid xs={3} item={true}>
            <SidePanelTextInput
              name={nameof<ILocationDTO>('postalCode')}
              label={localized('PostalCode')}
              validators={requiredValidator}
            />
          </Grid>
          <SidePanelTextInput
            name={nameof<ILocationDTO>('city')}
            label={localized('City')}
            validators={requiredValidator}
          />
        </FlexRow>
        <BasicButton type="submit" buttonColor={'primary'} disabled={savePending} text={localized('SaveAndClose')} />
      </Form>
    </>
  );
});
