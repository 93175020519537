import {Box, SortDirection, SxProps} from '@mui/material';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {SeverityLevel} from 'src/api/api';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {Pages} from '../../../../utilities/enums/pages';
import {filterListItems, sortListItems} from '../../../../utilities/helpers/general-helpers';
import {VirtualizedListTable} from '../../../components/default-components/virtual-list-table/virtual-list-table';
import {LocationListItem} from './location-list-item';

const columnSizes = [1, 2, 2, 2, 2, 2, 1];

export const LocationListView: FC = React.memo(() => {
  const [filteredItems, setFilteredItems] = useState<number[]>([]);
  const searchString = useSelector((store: AppState) => store.pageSettingReducer.searchString);
  const pageSetting = useSelector(selectPageSettingByKey(Pages.Locations));

  const locations = useSelector((store: AppState) => store.locationReducer.locations);
  useEffect(() => {
    // if index 1 (Remarks) is selected, only show locations with a severity level other than clear
    const pageTabFiltered = locations.filter(
      (l) => pageSetting?.tabIndex === 0 || (l.severityLevel !== SeverityLevel.Clear && pageSetting?.tabIndex === 1),
    );

    const filteredArray = filterListItems(pageTabFiltered, ['name', 'city'], searchString);
    if (pageSetting) {
      setFilteredItems(
        sortListItems(filteredArray, 'name', pageSetting.sort as SortDirection).map((location) => location.id!),
      );
    }
  }, [pageSetting, searchString, locations]);

  const renderUserItem = useCallback(
    ({key, index, style}: any) => {
      return (
        <Box key={key} style={style} sx={{paddingLeft: 6, paddingRight: 6}}>
          <LocationListItem columnSizes={columnSizes} locationId={filteredItems[index]} />
        </Box>
      );
    },
    [filteredItems],
  );

  return (
    <Box sx={{marginTop: theme.spacing(3)} as SxProps}>
      <VirtualizedListTable items={filteredItems} columnSizes={columnSizes} rowRenderer={renderUserItem} />
    </Box>
  );
});
