import {DialogActions, DialogTitle, Typography} from '@mui/material';
import React, {FC, MouseEvent, useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectUserLocationIdsByOrganizationUserId} from 'src/state/features/location-organization-user/location-organization-user-slice';
import {applySidePanelContent, setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {theme} from 'src/styles/theme';
import {BasicSvgIcon} from 'src/view/components/default-components/basic-svg-icon';
import {FlexColumn} from 'src/view/components/default-components/flex-column';
import {StyledPopoverItem, TxStyledPopover} from 'src/view/components/popover/tx-styled-popover';
import {OrganizationRole} from '../../../../api/api';
import {ReactComponent as EmailLogo} from '../../../../assets/email_logo.svg';
import {ReactComponent as MapsIcon} from '../../../../assets/maps_icon.svg';
import {ReactComponent as MoreDetails} from '../../../../assets/more_details_dots.svg';
import {ReactComponent as UserLogo} from '../../../../assets/user_logo2.svg';
import {useAuth} from '../../../../auth/auth-provider';
import {localized, localizedDynamic} from '../../../../i18n/i18n';
import {deleteOrganizationUser, deleteUser} from '../../../../state/features/users/operation';
import {selectUserById} from '../../../../state/features/users/users-slice';
import {AppState} from '../../../../state/store';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {ListTableItem} from '../../../components/default-components/list-table/list-table-item';
import {BasicModal} from '../../../components/default-components/modal/basic-modal';
import {CreateUser} from '../create-user/create-user';
import {LocationManagement} from '../location-user-relation/location-user-relation';

interface PropsFromParent {
  userId: number;
  columnSizes?: number[];
}

export const UserManagementListItem: FC<PropsFromParent> = React.memo(({userId, ...props}) => {
  const dispatch = useDispatch();
  const {isAdmin} = useAuth();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorElRef = useRef<Element | null>();
  const item = useSelector(selectUserById(userId));
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const orgUser = useMemo(() => {
    if (item?.organizationUser) {
      return item.organizationUser.find((orgUser) => orgUser.organizationId === selectedOrganization?.id);
    }
  }, [item, selectedOrganization]);
  const userLocations = useSelector(selectUserLocationIdsByOrganizationUserId(orgUser?.id?.toString()));

  const openEditUser = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsPopoverOpen(false);
      anchorElRef.current = e.currentTarget;

      if (isAdmin) {
        // Display the user editor in a side bar
        dispatch(applySidePanelContent(<CreateUser userId={userId} />));
        dispatch(setSidePanelOpen(true));
      }
    },
    [dispatch, isAdmin, userId],
  );

  const openEditLocations = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsPopoverOpen(false);
      anchorElRef.current = e.currentTarget;

      if (isAdmin) {
        // Display the user editor in a side bar
        dispatch(applySidePanelContent(<LocationManagement userId={userId} />));
        dispatch(setSidePanelOpen(true));
      }
    },
    [dispatch, isAdmin, userId],
  );

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogDeleteUserOpen, setDialogDeleteUserOpen] = useState<boolean>(false);
  const handleDeleteOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleDialogCancel = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleDialogDelete = useCallback(() => {
    if (item) {
      item.organizationUser?.map((orgUser) => {
        if (orgUser.organizationId === selectedOrganization?.id) {
          orgUser.isDeleted = true;
        }
        return orgUser;
      });
      dispatch(deleteOrganizationUser(item));
    }
    setDialogOpen(false);
  }, [dispatch, item, selectedOrganization]);

  const handleDialogHardDelete = useCallback(() => {
    if (item) {
      dispatch(deleteUser(item));
    }
    setDialogDeleteUserOpen(false);
  }, [dispatch, item]);

  const handleDialogHardDeleteCancel = useCallback(() => {
    setDialogDeleteUserOpen(false);
  }, []);

  const handleHardDeleteOpen = useCallback(() => {
    setDialogDeleteUserOpen(true);
  }, []);

  const togglePopover = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      anchorElRef.current = e.currentTarget;

      setIsPopoverOpen(!isPopoverOpen);
    },
    [isPopoverOpen],
  );

  const popoverItems = useMemo(
    () => (
      <FlexColumn>
        <StyledPopoverItem onClick={openEditUser}>{localized('Edit')}</StyledPopoverItem>
        <StyledPopoverItem onClick={openEditLocations}>{localized('Locations')}</StyledPopoverItem>
        <StyledPopoverItem onClick={handleDeleteOpen}>{localized('DeleteOrgUser')}</StyledPopoverItem>
        <StyledPopoverItem onClick={handleHardDeleteOpen}>{localized('DeleteUser')}</StyledPopoverItem>
      </FlexColumn>
    ),
    [handleDeleteOpen, openEditLocations, openEditUser, handleHardDeleteOpen],
  );

  if (!item) {
    return null;
  }

  return (
    <ListTableItem
      {...props}
      objectValues={[
        <></>,
        <Typography
          sx={{fontSize: theme.typography.body2, fontWeight: theme.typography.fontWeightBold, alignSelf: 'center'}}>
          {item.name}
        </Typography>,
        <>
          <BasicSvgIcon
            sx={{marginRight: 2}}
            stroke={theme.colors.listTable.ListTableTextColor}
            component={EmailLogo}
          />
          <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>{item.email}</Typography>
        </>,
        <>
          <BasicSvgIcon sx={{marginRight: 2}} stroke={theme.colors.listTable.ListTableTextColor} component={UserLogo} />
          <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>
            {localizedDynamic(OrganizationRole[orgUser?.organizationRole!])}
          </Typography>
        </>,
        <>
          <BasicSvgIcon sx={{marginRight: 2}} stroke={theme.colors.listTable.ListTableTextColor} component={MapsIcon} />
          <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>
            {userLocations?.length + ' ' + localized('Locations')}
          </Typography>
        </>,
        isAdmin && (
          <div key="index">
            <BasicSvgIcon stroke={''} component={MoreDetails} onClick={togglePopover} />
            <BasicModal open={dialogOpen}>
              <DialogTitle>{localized('ConfirmDeleteOrganizationUser')}</DialogTitle>
              <DialogActions>
                <BasicButton onClick={handleDialogCancel} text={localized('Cancel')} buttonColor={'secondary'} />
                <BasicButton onClick={handleDialogDelete} text={localized('Delete')} buttonColor={'error'} />
              </DialogActions>
            </BasicModal>
            <BasicModal open={dialogDeleteUserOpen}>
              <DialogTitle>{localized('ConfirmDeleteUser')}</DialogTitle>
              <DialogActions>
                <BasicButton
                  onClick={handleDialogHardDeleteCancel}
                  text={localized('Cancel')}
                  buttonColor={'secondary'}
                />
                <BasicButton onClick={handleDialogHardDelete} text={localized('Delete')} buttonColor={'error'} />
              </DialogActions>
            </BasicModal>
          </div>
        ),
        <TxStyledPopover popoverStatus={isPopoverOpen} togglePopover={togglePopover} anchorEl={anchorElRef}>
          {popoverItems}
        </TxStyledPopover>,
      ]}
    />
  );
});
