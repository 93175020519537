import {createAsyncThunk} from '@reduxjs/toolkit';
import {organizationUserClient} from 'src/state/api-clients';
import {OrganizationUserClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';

export const getLocationUserByOrganizationId = createAsyncThunk(
  'locationOrganizationUser/getLocationUserByOrganizationId',
  (organizationId: number) => {
    return AsyncOperationHandler(
      (client) => (client as OrganizationUserClient).getLocationUserByOrganizationId(organizationId),
      organizationUserClient,
    );
  },
);

export const updateOranizationUserRealation = createAsyncThunk(
  'organizationUser/updateOranizationUserRealation',
  (input: {list: number[]; orgUserId: number | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as OrganizationUserClient).updateOrganizationUserRealation(input.list, input.orgUserId),
      organizationUserClient,
      {
        showResponseMessage: true,
      },
    );
  },
);
