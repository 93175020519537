import {
  AuthenticationResult,
  BrowserAuthError,
  Configuration,
  EndSessionRequest,
  LogLevel,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import {localized} from '../../i18n/i18n';
import {Monitor} from '../../services/telemetry-service';
import {AuthRoles} from '../../utilities/constants';
import {B2CConfig, tokenStorageKey, TOKEN_ROLE_IDENTIFIER} from '../auth-constants';
import {AuthUser} from '../auth-types';

// Configuration for the msal PublicClientApplication
const authConfig: Configuration = {
  auth: {
    authority: B2CConfig.Authority,
    clientId: B2CConfig.ClientId,
    knownAuthorities: [B2CConfig.Authority],
    redirectUri: B2CConfig.RedirectUri,
  },
  cache: {
    cacheLocation: B2CConfig.CacheLocation,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            Monitor.logTrace('[Auth error]' + message);
            return;
          case LogLevel.Info:
            console.info(message);
            Monitor.logTrace('[Auth info]' + message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            Monitor.logTrace('[Auth debug]' + message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            Monitor.logTrace('[Auth warning]' + message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
};

// Helper function to get active account selected in login
export const getActiveAccount = () => {
  const account = msalInstance?.getActiveAccount();
  if (account) {
    return account;
  }
};

// Msal instance object through which all authentication flows
let msalInstance: PublicClientApplication | null;

// Different types of request for the authentication flows.
const redirectRequest: RedirectRequest = {scopes: ['openid', B2CConfig.Scope], redirectStartPage: window.location.href};
const popUpRequest: PopupRequest = {scopes: ['openid', B2CConfig.Scope]};

// Callback functions for success and logout
let handleTokenResponse: (accessToken: string) => void | undefined;
let handleLogout: () => void | undefined;

// Initiating the Msal instance and registering the redirectPromise callback function
export const initAuthClient = async (
  handleTokenResponseCallback: (accessToken: string) => void,
  handleLogoutCallback: () => void,
) => {
  handleTokenResponse = handleTokenResponseCallback;
  handleLogout = handleLogoutCallback;
  msalInstance = await new PublicClientApplication(authConfig);
  msalInstance.handleRedirectPromise().then(handleAuthenticationResult).catch(handleAuthenticationError);
};

// Handler for the authenticationResult from authentication requests
export const handleAuthenticationResult = (response: AuthenticationResult | null) => {
  if (msalInstance) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      // No accounts Login
      clientLoginWithRedirect();
    } else if (account && response === null) {
      clientAcquireTokenSilent();
    }

    if (response !== null) {
      msalInstance.setActiveAccount(response.account);
      handleTokenResponse(response.accessToken);
    }
  }
};

// Error handler for auth errors
export const handleAuthenticationError = (error: BrowserAuthError) => {
  // In case of password reset the masl instance throws this error, which we need to handle and start password reset flow
  if (error.errorMessage.includes('AADB2C90077') && msalInstance) {
    clientLoginWithRedirect();
  }
  // In case of password reset the masl instance throws this error, which we need to handle and start password reset flow
  else if (error.errorMessage.includes('AADB2C90118') && msalInstance) {
    let passwordResetRequest: RedirectRequest = {
      scopes: redirectRequest.scopes,
      authority: B2CConfig.PasswordResetAuthority,
    };
    msalInstance.loginRedirect(passwordResetRequest);
    // Error is user returning from aborted password redirect, redirect to login page
  } else if (error.errorMessage.includes('AADB2C90091') && msalInstance) {
    clientLoginWithRedirect();
  } else {
    Monitor.logException(error);
    console.error(error);
  }
};

// Login with redirect
export const clientLoginWithRedirect = async () => {
  msalInstance?.loginRedirect(redirectRequest).catch(handleAuthenticationError);
};

// Login with popup
export const clientLoginWithPopup = async () => {
  msalInstance?.acquireTokenPopup(popUpRequest).then(handleAuthenticationResult).catch(handleAuthenticationError);
};

// Login silent
export const clientLoginSilent = async () => {
  const silentRequest: SilentRequest = {
    scopes: ['openid', B2CConfig.Scope],
    forceRefresh: false,
    account: getActiveAccount(),
  };
  msalInstance?.acquireTokenSilent(silentRequest).then(handleAuthenticationResult).catch(handleAuthenticationError);
};

// Aquire token silent. Used in case the user is validated, but does not have a token.
export const clientAcquireTokenSilent = async () => {
  const silentRequest: SilentRequest = {
    scopes: ['openid', B2CConfig.Scope],
    forceRefresh: false,
    account: getActiveAccount(),
  };
  msalInstance?.acquireTokenSilent(silentRequest).then(handleAuthenticationResult).catch(handleAuthenticationError);
};

// Logout
export const clientLogout = async () => {
  const logoutRequest: EndSessionRequest = {
    account: getActiveAccount(),
  };
  handleLogout();
  msalInstance?.logoutRedirect(logoutRequest);
};

// Helper function to get the role from the B2C specific token
export function clientGetUserRole(tokenId: string = tokenStorageKey): string {
  let token = localStorage.getItem(tokenId);

  if (token) {
    let jwtToken = jwtDecode<any>(token);
    if (jwtToken[TOKEN_ROLE_IDENTIFIER]) {
      return jwtToken[TOKEN_ROLE_IDENTIFIER];
    } else {
      return '';
    }
  }
  return '';
}

// Helper function to get user information from the B2C specific token
export function clientGetUser(): AuthUser {
  let token = localStorage.getItem(tokenStorageKey);
  if (token) {
    let jwtToken = jwtDecode<any>(token);
    let user: AuthUser = {
      name: jwtToken.name,
      tokenRole: localized(jwtToken[TOKEN_ROLE_IDENTIFIER]),
    };
    return user;
  }
  return {} as AuthUser;
}

// Helper function to check if user has the admin role
export function clientUserIsAdmin() {
  let token = localStorage.getItem(tokenStorageKey);
  if (token) {
    let jwtToken = jwtDecode<any>(token);
    if (jwtToken[B2CConfig.TokenRoleIdentifier]) {
      return (
        jwtToken[B2CConfig.TokenRoleIdentifier].includes(AuthRoles.Admin) ||
        jwtToken[B2CConfig.TokenRoleIdentifier].includes(AuthRoles.GlobalAdmin)
      );
    }
  }
  return false;
}

// helper function to check if user has the global admin role
export function clientUserisGlobalAdmin() {
  let token = localStorage.getItem(tokenStorageKey);
  if (token) {
    let jwtToken = jwtDecode<any>(token);
    if (jwtToken[B2CConfig.TokenRoleIdentifier]) {
      return jwtToken[B2CConfig.TokenRoleIdentifier].includes(AuthRoles.GlobalAdmin);
    }
  }
  return false;
}
