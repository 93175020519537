import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Theme} from '@mui/material';
import React, {FC, memo, useMemo} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {theme} from '../../../../styles/theme';
import {KeyboardArrowDown} from '@mui/icons-material';
import {whiteColor} from 'src/styles/color-constants';
import {DropdownOption} from '../baisc-dropdown/basic-dropdown';
import {createStyles, makeStyles, withStyles} from '@mui/styles';
import {localized} from 'src/i18n/i18n';

interface Props {
  name: string;
  label?: string;
  options: DropdownOption[];
  noDefault?: boolean;
  disabled?: boolean;
  initValue?: any;
  sx?: any;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    disabled: boolean;
  }>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.colors.sidePanel.sidePanelTextInputLabelColor,
    },
  }),
);

export const SidePanelSelectInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
    getValues,
  } = useFormContext();
  const classes = useStyles();
  const mapOptionsToItems = useMemo(
    () =>
      props.options.map((option) => (
        <MenuItem value={option.value} sx={{color: theme.colors.sidePanel.sidePanelTextInputColor}} key={option.value}>
          {option.label}
        </MenuItem>
      )),
    [props.options],
  );

  // TODO: Temp hack to fix validation problem
  const customValidator = {
    validate: {
      required: () => {
        return getValues()['role'] === false && !getValues()[props.name] ? localized('FieldIsRequired') : true;
      },
    },
  };

  return props.options.length !== 0 ? (
    <Controller
      name={props.name}
      control={control}
      rules={customValidator ?? props.validators}
      render={({field}) => (
        <FormControl variant="standard">
          {props.label && <span className={classes.label}>{props.label}</span>}
          <Select
            {...field}
            disableUnderline
            sx={{
              width: '150px',
              padding: 1.5,
              boxShadow: '0px 3px 15px #0000001A',
              backgroundColor: whiteColor,
              color: theme.colors.sidePanel.sidePanelTextInputColor,
              ...props.sx,
            }}
            disabled={props.disabled}
            error={!!errors[props.name]}
            value={field.value ?? (props.noDefault ? '' : props.initValue ? props.initValue : props.options[0].value)}
            IconComponent={KeyboardArrowDown}>
            {mapOptionsToItems}
          </Select>
          {!!errors[props.name] && (
            <FormHelperText sx={{color: theme.palette.error.main}}>{errors[props.name].message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  ) : (
    <></>
  );
});
