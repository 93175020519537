import {Button, PopoverOrigin, styled} from '@mui/material';
import React, {FC, MouseEvent} from 'react';
import {theme} from 'src/styles/theme';
import {BasicPopover} from './basic-popover';

export const StyledPopoverItem = styled(Button)({
  backgroundColor: theme.colors.popover.backgroundColor,
  color: theme.colors.popover.textColor,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  fontSize: theme.typography.body2.fontSize,
  borderBottom: 2,
  borderColor: theme.colors.popover.borderColor,
  borderBottomStyle: 'solid',
  textTransform: 'none',
  borderRadius: 0,
  justifyContent: 'flex-start',
  ':hover': {
    opacity: 0.8,
    backgroundColor: theme.colors.popover.backgroundColor,
  },
});

const anchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

interface PropsFromParent {
  togglePopover(e: MouseEvent<SVGSVGElement>): void;
  popoverStatus: boolean;
  anchorEl: React.MutableRefObject<Element | null | undefined>;
}

export const TxStyledPopover: FC<PropsFromParent> = React.memo((props) => {
  return (
    <BasicPopover
      open={props.popoverStatus}
      anchorEl={props.anchorEl.current}
      // No support for positioning it if it overflows the screen.
      // Here's a possible solution: https://stackoverflow.com/a/64138997
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={{
        '& .MuiPaper-elevation': {
          borderRadius: 0,
          minWidth: 180,
        },
      }}
      onBackdropClick={props.togglePopover}>
      {props.children}
    </BasicPopover>
  );
});
