import {Theme} from '@emotion/react';
import {Box, Typography} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {localized} from 'src/i18n/i18n';
import {selectApartmentById} from 'src/state/features/apartment/apartment-slice';
import {selectPageSettingByKey} from '../../../../../state/features/page-header-setting/page-header-setting-slice';
import {selectThermexUnitsByApartmentId} from '../../../../../state/features/thermex-unit/thermex-unit-slice';
import {theme} from '../../../../../styles/theme';
import {Pages} from '../../../../../utilities/enums/pages';
import {FlexColumn} from '../../../../components/default-components/flex-column';
import {FlexRow} from '../../../../components/default-components/flex-row';
import {PageContentContainer} from '../../../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../../../components/default-components/page-header.tsx/page-header-content';
import {StyledTab} from '../../../../components/default-components/page-header.tsx/page-header-tab';
import {PageHeaderTabContainer} from '../../../../components/default-components/page-header.tsx/page-header-tab-container';
import {ApartmentSelectNotificationSetting} from './apartment-select-notification-setting';
import {ThermexUnitList} from './thermex-unit-list';

interface PropsFromParent {
  apartmentId: number | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyDetailContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    emptyDetailText: {},
  }),
);

export const ApartmentDetail: FC<PropsFromParent> = React.memo(({apartmentId}) => {
  const classes = useStyles();
  const apartment = useSelector(selectApartmentById(apartmentId));

  const units = useSelector(selectThermexUnitsByApartmentId(apartmentId));
  const pageSetting = useSelector(selectPageSettingByKey(Pages.ApartmentDetail + apartmentId));

  return (
    <FlexColumn flex={2} bgcolor={'background.paper'}>
      {!apartment ? (
        <div className={classes.emptyDetailContainer}>
          <Typography
            fontSize="30px"
            flex={0.5}
            textAlign="center"
            color={theme.colors.disableColor}
            sx={{opacity: '26%'}}>
            {localized('EmptyApartmentDetail')}
          </Typography>
        </div>
      ) : (
        <>
          <PageHeader
            shouldClearPageSettingOnUnmount={true}
            area={Pages.ApartmentDetail + apartmentId}
            postfix={<ApartmentSelectNotificationSetting apartment={apartment} />}
            title={apartment?.name || ''}>
            <PageHeaderContent>
              <FlexColumn flex={1}>
                <FlexRow flex={1} flexWrap="wrap">
                  <PageHeaderTabContainer initValue={0}>
                    {units.map((i, key) => {
                      return (
                        <StyledTab
                          className={
                            i.notificationSettingId !== apartment.notificationSettingId ? 'show-indicator' : ''
                          }
                          key={key}
                          label={i.name}
                        />
                      );
                    })}
                  </PageHeaderTabContainer>
                </FlexRow>
              </FlexColumn>
            </PageHeaderContent>
          </PageHeader>
          <PageContentContainer>
            <ThermexUnitList thermexUnit={units[pageSetting?.tabIndex!]} />
          </PageContentContainer>
        </>
      )}
    </FlexColumn>
  );
});
