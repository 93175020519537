import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {SxProps, Theme} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {FC, useEffect, useState} from 'react';
import {CssTextField} from '../../../components/default-components/text-fields/basic-text-field';
interface PropsFromParent {
  onChange: (item: DropdownOption) => void;
  items: DropdownOption[];
  label: string;
  value: DropdownOption | undefined;
  sx?: SxProps<Theme>;
}

const dropdownSx: SxProps = {display: 'contents'};

export class DropdownOption {
  label: string;
  value: any;
  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

export const BasicDropdown: FC<PropsFromParent> = React.memo(({onChange, items, label, value, sx}) => {
  const renderInput = (params: any) => <CssTextField disabled={true} {...params} label={label} margin="normal" />;
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);

  // Add an item option in case of
  useEffect(() => {
    let options = items;

    options.unshift(new DropdownOption('', undefined));
    setDropdownOptions(options);
  }, [items]);

  const onChangeLocal = (event: any, newValue: any) => {
    onChange(newValue);
  };

  const getOptionLabel = (option: DropdownOption) => {
    return option.label;
  };

  const getOptionSelected = (option: DropdownOption, valueOption: DropdownOption) => option.value === valueOption.value;

  return (
    <Autocomplete
      value={value}
      sx={{...dropdownSx, ...sx}}
      onChange={onChangeLocal}
      options={dropdownOptions}
      id="disable-clearable"
      disableClearable={true}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      renderInput={renderInput}
      popupIcon={<KeyboardArrowDown />}
    />
  );
});
