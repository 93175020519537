import {createSelector, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import {deleteLocation, getLocations, saveLocation, updateLocation} from './operation';
import {LocationState} from './types';

const initialState: LocationState = {
  locations: [],
  pending: false,
  isSuccess: false,
};

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.pending = false;
      state.locations = action.payload;
    });
    // Save
    builder.addCase(saveLocation.fulfilled, (state, action) => {
      state.locations.push(action.payload);
    });

    builder.addCase(updateLocation.fulfilled, (state, action) => {
      const index = state.locations.findIndex((org) => org.id === action.payload.id);
      if (index > -1) {
        state.locations[index] = action.payload;
      }
    });

    builder.addCase(deleteLocation.fulfilled, (state, action) => {
      const index = state.locations.findIndex((loc) => loc.id === action.meta.arg.location.id);
      if (index > -1) {
        state.locations.splice(index, 1);
      }
    });

    // Common actions. addMatcher() must always be after .addCase
    builder.addMatcher(isAnyOf(updateLocation.pending, saveLocation.pending, deleteLocation.pending), (state) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(updateLocation.rejected, saveLocation.rejected, deleteLocation.rejected), (state) => {
      state.pending = false;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(updateLocation.fulfilled, saveLocation.fulfilled, deleteLocation.fulfilled), (state) => {
      state.pending = false;
      state.isSuccess = true;
    });
  },
});

export const selectLocations = (store: AppState) => store.locationReducer.locations;
export const selectLocationSpinner = (store: AppState) =>
  store.locationReducer.pending && store.locationReducer.locations.length < 1;
export const selectLocationById = (id: number | undefined) =>
  createSelector(selectLocations, (locations) => {
    return locations.find((loc) => loc.id === id);
  });

// eslint-disable-next-line
export const {} = locationSlice.actions;

export default locationSlice.reducer;
