import {Button, PopoverOrigin, styled} from '@mui/material';
import React, {FC, MouseEvent, useCallback, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {localized} from 'src/i18n/i18n';
import {theme} from 'src/styles/theme';
import {FlexColumn} from 'src/view/components/default-components/flex-column';
import {BasicPopover} from 'src/view/components/popover/basic-popover';
import {ReactComponent as ErrorIcon} from '../../../../assets/error_icon.svg';
import {ReactComponent as House} from '../../../../assets/house_icon.svg';
import {ReactComponent as MoreDetails} from '../../../../assets/more_details_dots.svg';
import {ReactComponent as SkyScraper} from '../../../../assets/skyscraper_logo.svg';
import {useAuth} from '../../../../auth/auth-provider';
import {selectLocationById} from '../../../../state/features/location/location-slice';
import {applySidePanelContent, setSidePanelOpen} from '../../../../state/features/webapp/webapp-slice';
import {routes} from '../../../../state/routes';
import {BasicSvgIcon} from '../../../components/default-components/basic-svg-icon';
import {ListTableItem} from '../../../components/default-components/list-table/list-table-item';
import {BasicButton} from '../buttons/basic-button';

interface PropsFromParent {
  buttonText: string;
  sidePanelContent: JSX.Element | undefined;
}

export const SidePanelRouteButton: FC<PropsFromParent> = React.memo(({buttonText, sidePanelContent, children}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const OpenPanel = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // Display the location editor in a side bar
      dispatch(applySidePanelContent(sidePanelContent));
      dispatch(setSidePanelOpen(true));
    },
    [dispatch, sidePanelContent],
  );

  return (
    <BasicButton
      onClick={OpenPanel}
      text={buttonText}
      sx={{borderRadius: 0, fontWeight: 'bold', paddingX: 3, paddingY: 1}}
      variant="outlined"
    />
  );
});
