import {Box, Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React, {FC} from 'react';
import {FlexRow} from '../../../components/default-components/flex-row';
import { ListItemHeaderWidth, ListItemWidth } from '../../../../styles/styling-constants';

interface PropsFromParent {
  columnSizes?: number[];
  headers: string[];
  maxWidth?: (number | undefined)[];
  width?: any;
  listHasScroll: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: (props: PropsFromParent) => ({
      height: 40,
      alignItems: 'center',
      margin: theme.spacing(0, 1, 0, 1),
      padding: theme.spacing(0, 3, 0, 3),
      width: props.listHasScroll ? ListItemHeaderWidth : ListItemWidth,
    }),
    headerText: {
      fontSize: theme.typography.body2.fontSize,
      color: theme.colors.listTable.ListTableTextColor,
    },
  }),
);

type Props = PropsFromParent;

export const ListTableHeader: FC<Props> = React.memo(({columnSizes, headers, maxWidth, width, listHasScroll}) => {
  const classes = useStyles({listHasScroll, headers});
  return (
    <FlexRow className={classes.headerContainer}>
      {headers.map((header, index) => {
        const flex = columnSizes && columnSizes[index];
        let width = maxWidth && maxWidth[index];
        if (!width && (index === 0 || index === headers.length - 1)) {
          width = 50;
        }
        return (
          <Box key={index} className={classes.headerText} flex={flex} maxWidth={width}>
            {header}
          </Box>
        );
      })}
    </FlexRow>
  );
});
