import {createSelector, createSlice, PayloadAction, isAnyOf} from '@reduxjs/toolkit';
import {AppState} from 'src/state/store';
import {getLocationUserByOrganizationId, updateOranizationUserRealation} from './operation';

import {LocationOrganizationUserState} from './types';

const initialState: LocationOrganizationUserState = {
  locationOrganizationUser: {} as {[key: string]: number[]},
  editLocationOrganizationUser: {} as {key: number; ids: number[]},
  pending: false,
  isSuccess: false,
};

export const locationOrganizationUserSlice = createSlice({
  name: 'locationOrganizationUser',
  initialState,
  reducers: {
    setEditUserLocation: (state, action: PayloadAction<{key: number; ids: number[]}>) => {
      state.editLocationOrganizationUser = action.payload;
    },
    editUserLocation: (state, action: PayloadAction<{key: number; locationId: number}>) => {
      if (state.editLocationOrganizationUser.key !== action.payload.key) {
        state.editLocationOrganizationUser = {key: action.payload.key, ids: []};
      }

      var index = state.editLocationOrganizationUser.ids.indexOf(action.payload.locationId);
      var temp = {...state.editLocationOrganizationUser};

      if (index === -1) {
        temp.ids = [...temp.ids, action.payload.locationId];
      } else {
        temp.ids.splice(index, 1);
      }

      state.editLocationOrganizationUser = temp;
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getLocationUserByOrganizationId.fulfilled, (state, action) => {
      state.pending = false;
      state.locationOrganizationUser = action.payload;
    });

    builder.addCase(getLocationUserByOrganizationId.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getLocationUserByOrganizationId.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(updateOranizationUserRealation.fulfilled, (state, action) => {
      const orgUserIdString = action.meta.arg.orgUserId?.toString();
      if (orgUserIdString) {
        state.locationOrganizationUser[orgUserIdString] = action.meta.arg.list;
      }
    });

    // Common actions. addMatcher() must always be after .addCase
    builder.addMatcher(isAnyOf(updateOranizationUserRealation.pending), (state) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(updateOranizationUserRealation.rejected), (state) => {
      state.pending = false;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(updateOranizationUserRealation.fulfilled), (state) => {
      state.pending = false;
      state.isSuccess = true;
    });
  },
});

export const selectUserLocations = (store: AppState) => store.locationOrganizationUserReducer.locationOrganizationUser;

export const selectUserLocationIdsByOrganizationUserId = (id: string | undefined) =>
  createSelector(selectUserLocations, (locations) => {
    if (id) {
      return locations[id] ?? [];
    }
    return [];
  });

// eslint-disable-next-line
export const {editUserLocation, setEditUserLocation} = locationOrganizationUserSlice.actions;

export default locationOrganizationUserSlice.reducer;
