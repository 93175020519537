import {Box, SortDirection, SxProps} from '@mui/material';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Pages} from 'src/utilities/enums/pages';
import {selectPageSettingByKey} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {filterListItems, sortListItems} from '../../../../utilities/helpers/general-helpers';
import {VirtualizedListTable} from '../../../components/default-components/virtual-list-table/virtual-list-table';
import {OrganizationListItem} from './organization-list-item';

const columnSizes = [1, 4, 2, 2, 2, 0];
export const OrganizationListView: FC = React.memo(() => {
  const [filteredItems, setFilteredItems] = useState<number[]>([]);
  const searchString = useSelector((store: AppState) => store.pageSettingReducer.searchString);
  const pageSetting = useSelector(selectPageSettingByKey(Pages.Organizations));
  const organizations = useSelector((store: AppState) => store.organizationReducer.organizations);
  useEffect(() => {
    const filteredArray = filterListItems(organizations, ['name'], searchString);
    if (pageSetting) {
      setFilteredItems(sortListItems(filteredArray, 'name', pageSetting.sort as SortDirection).map((org) => org.id!));
    }
  }, [pageSetting, searchString, organizations]);

  const renderUserItem = useCallback(
    ({key, index, style}: any) => {
      if (index > filteredItems.length - 1) {
        return null;
      }
      return (
        <Box key={key} style={style} sx={{paddingLeft: 6, paddingRight: 6}}>
          <OrganizationListItem columnSizes={columnSizes} organizationId={filteredItems[index]} />
        </Box>
      );
    },
    [filteredItems],
  );

  return (
    <Box sx={{marginTop: theme.spacing(3)} as SxProps}>
      <VirtualizedListTable items={filteredItems} columnSizes={columnSizes} rowRenderer={renderUserItem} />
    </Box>
  );
});
