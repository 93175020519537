import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {Button, styled, Typography} from '@mui/material';
import React from 'react';
import {localized} from '../../../i18n/i18n';
import {theme} from '../../../styles/theme';

const StyledText = styled(Typography)({
  color: theme.colors.backToPage.textColor,
  fontSize: theme.typography.body2.fontSize,
});

const alignLeftIconWithContentBelow = '-6px';

export const BackToPage = (props: {onClick: () => void; label?: string}) => {
  return (
    <Button
      onClick={props.onClick}
      fullWidth={false}
      sx={{textTransform: 'none', alignSelf: 'flex-start', paddingLeft: 0, paddingBottom: 0}}>
      <KeyboardArrowLeftIcon
        fontSize="small"
        sx={{color: theme.colors.backToPage.textColor, marginLeft: alignLeftIconWithContentBelow}}
      />
      <StyledText>{props.label ?? localized('BackToOverview')}</StyledText>
    </Button>
  );
};
