import {createSelector, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {RoomType} from 'src/api/api';
import {removeElement, updateElement} from 'src/utilities/platform-helpers/array-helper';
import {AppState} from '../../store';
import {setOrganizationSetting} from '../settings/settings-slice';
import {
  createNotificationRange,
  deleteNotificationRange,
  deleteNotificationSetting,
  getNotificationSettings,
  saveNotificationSettings,
  updateNotificationRange,
  updateNotificationSetting,
} from './operation';
import {NotificationSettingState} from './types';

const initialState: NotificationSettingState = {
  notificationSettings: [],
  pending: false,
  isSuccess: false,
};

export const notificationSettingsSlice = createSlice({
  name: 'notificationSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setOrganizationSetting, (state) => {
      state.notificationSettings = [];
    });
    builder.addCase(getNotificationSettings.fulfilled, (state, action) => {
      state.notificationSettings = action.payload;
    });

    // Save
    builder.addCase(saveNotificationSettings.fulfilled, (state, action) => {
      state.notificationSettings.push(action.payload);
    });

    // Update
    builder.addCase(updateNotificationRange.fulfilled, (state, action) => {
      const indexNs = state.notificationSettings.findIndex((ns) => ns.id === action.payload.notificationSettingId);

      // Continue if Notification setting exist
      if (indexNs > -1) {
        const ns = {...state.notificationSettings[indexNs]};
        if (ns && ns.notificationRanges) {
          const index = ns?.notificationRanges.findIndex((nr) => nr.id === action.payload.id);

          // Upsert depending on current state
          if (index > -1) {
            ns.notificationRanges[index] = action.payload;
            state.notificationSettings[indexNs] = ns;
          } else {
            ns.notificationRanges.push(action.payload);
            state.notificationSettings[indexNs] = ns;
          }
        }
      }
    });

    // Save
    builder.addCase(updateNotificationSetting.fulfilled, (state, action) => {
      state.notificationSettings = updateElement(state.notificationSettings, action.payload);
    });

    // Create
    builder.addCase(createNotificationRange.fulfilled, (state, action) => {
      const indexNs = state.notificationSettings.findIndex((ns) => ns.id === action.payload.notificationSettingId);

      // Continue if Notification setting exist
      if (indexNs > -1) {
        const ns = {...state.notificationSettings[indexNs]};
        if (ns && ns.notificationRanges) {
          ns.notificationRanges.push(action.payload);
          state.notificationSettings[indexNs] = ns;
        }
      }
    });

    // Delete
    builder.addCase(deleteNotificationRange.fulfilled, (state, action) => {
      const indexNs = state.notificationSettings.findIndex(
        (ns) => ns.id === action.meta.arg.notificationRange.notificationSettingId,
      );
      // Continue if Notification setting exist
      if (indexNs > -1) {
        const ns = {...state.notificationSettings[indexNs]};
        if (ns && ns.notificationRanges) {
          ns.notificationRanges = ns?.notificationRanges.filter((nr) => nr.id !== action.meta.arg.notificationRange.id);
          state.notificationSettings[indexNs] = ns;
        }
      }
    });

    builder.addCase(deleteNotificationSetting.fulfilled, (state, action) => {
      state.notificationSettings = removeElement(state.notificationSettings, action.payload);
    });

    // Common actions. addMatcher() must always be after .addCase
    builder.addMatcher(
      isAnyOf(
        getNotificationSettings.pending,
        updateNotificationRange.pending,
        createNotificationRange.pending,
        saveNotificationSettings.pending,
      ),
      (state) => {
        state.pending = true;
        state.isSuccess = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getNotificationSettings.rejected,
        updateNotificationRange.rejected,
        createNotificationRange.rejected,
        saveNotificationSettings.rejected,
      ),
      (state) => {
        state.pending = false;
        state.isSuccess = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        getNotificationSettings.fulfilled,
        updateNotificationRange.fulfilled,
        createNotificationRange.fulfilled,
        saveNotificationSettings.fulfilled,
      ),
      (state) => {
        state.pending = false;
        state.isSuccess = true;
      },
    );
  },
});

export const selectNotificationSetting = (store: AppState) => store.notificationSettingReducer.notificationSettings;

export const selectNotificationSettingById = (id: number) =>
  createSelector(selectNotificationSetting, (settings) => {
    return settings.find((setting) => setting.id === id);
  });

export const selectNotificationSettingByRoomType = (roomType: RoomType|undefined) =>
  createSelector(selectNotificationSetting, (settings) => {
    return settings.filter((setting) => setting.roomType === RoomType.Standard || setting.roomType === roomType);
  });

export const selectNotificationSettingsByOrganizationId = (id: number | undefined) =>
  createSelector(selectNotificationSetting, (settings) => {
    return settings.filter((setting) => setting.organizationId === id);
  });

// eslint-disable-next-line
export const {} = notificationSettingsSlice.actions;

export default notificationSettingsSlice.reducer;
