import React, {useMemo} from 'react';
import {IAlarm, SeverityLevel} from '../../../api/api';
import {ReactComponent as CheckmarkIcon} from '../../../assets/checkmark_status.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/error_icon.svg';
import {ReactComponent as WarningIcon} from '../../../assets/warning_icon.svg';
import {BasicSvgIcon} from '../default-components/basic-svg-icon';

export const StatusIcon = (props: {alarms: IAlarm[]}) => {
  const severityLevels = useMemo(() => props.alarms.map((x) => x.severityLevel), [props.alarms]);
  const icon = severityLevels.includes(SeverityLevel.Critical)
    ? ErrorIcon
    : severityLevels.includes(SeverityLevel.Minor)
    ? WarningIcon
    : CheckmarkIcon;

  return <BasicSvgIcon component={icon} />;
};

export const StatusIconBySeverity = (props: {severityLevel: SeverityLevel}) => {
  const icon =
    props.severityLevel === SeverityLevel.Critical
      ? ErrorIcon
      : props.severityLevel === SeverityLevel.Minor
      ? WarningIcon
      : CheckmarkIcon;

  return <BasicSvgIcon component={icon} />;
};
