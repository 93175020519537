export const VirtualizedContentBoxHeight = 'calc(100vh - 145px) !important';
export const VirtualizedContentBoxHeightmobile = 'calc(100vh - 145px - 2 * 56px) !important';
export const VirtualizedListTableBoxHeight = 'calc(100vh - 225px) !important';
export const VirtualizedListTableBoxHeightMobile = 'calc(100vh - 225px - 2 * 56px) !important';

export const ListItemWidth = 'calc(100%)';
export const ListItemHeaderWidth = 'calc(100% - 34px)';

export const NoMarginPageWrapper = {
    marginTop: -24,
}