import React from 'react';
import { localized } from '../../../i18n/i18n';
import { PageContentContainer } from '../../components/default-components/page-container/page-content-container';
import { PageHeader } from '../../components/default-components/page-header.tsx/page-header';
import { PageTitle } from '../../components/default-components/page-title/page-title';

export const Template = React.memo(() => {

  return (
    <PageTitle title="Template">
      <PageHeader area="template" title={localized('Template')}></PageHeader>
      <PageContentContainer></PageContentContainer>
    </PageTitle>
  );
});
