import {Theme} from '@mui/material';
import Grid from '@mui/material/Grid';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {tokenStorageKey} from './auth/auth-constants';
import {useAuth} from './auth/auth-provider';
import {Callback} from './auth/callback';
import {GlobalAdminRoute} from './auth/global-admin-route';
import {PrivateRoute} from './auth/private-route';
import {routes} from './state/routes';
import {isTokenExpired} from './utilities/platform-helpers/auth-helper';
import {SidePanelProvider} from './view/components/default-components/side-panel/side-panel-provider';
import {OverlaySpinner} from './view/components/default-components/spinner/overlay-spinner';
import {MenuDrawer} from './view/navigation/desktop/menu-drawer';
import {LocationDetailOverview} from './view/pages/locations/location-detail/location-detail-overview';
import {LocationForm} from './view/pages/locations/location-form/location-form';
import {LocationsOverview} from './view/pages/locations/locations-overview';
import {Notifications} from './view/pages/notifications/notifications';
import {Organization} from './view/pages/organization/organization';
import {Template} from './view/pages/template/template';
import {UserManagement} from './view/pages/user-management/user-management';

export const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileOnly: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    desktopOnly: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    appContent: {flex: 1, height: '100vh', display:'flex', flexDirection:'column'},
  }),
);

export const AppRoutes: FC = React.memo(() => {
  const classes = useLayoutStyles();
  const {isAuthenticated} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // VERY IMPORTANT TO NOT NAVIGATE BEFORE USER IS AUTHENTICATED
    if (location.pathname === '/' && isAuthenticated) {
      navigate(routes.locations);
    }
  }, [location.pathname, navigate, isAuthenticated]);

  return (
    <div>
      {!isTokenExpired(tokenStorageKey) && (
        <div>
          {/* <AppHeaderBar /> */}
          <Grid wrap="nowrap" container={true}>
            <MenuDrawer />
            <OverlaySpinner />
            <div className={classes.appContent}>
              <Routes>
                <Route path="/" element={<PrivateRoute />}>
                  <Route path={routes.template} element={<Template />} />
                  <Route path={routes.locations}>
                    <Route index element={<LocationsOverview />} />
                    <Route path={routes.locationsDetail} element={<LocationDetailOverview />} />
                    <Route path={routes.locationsDetailWithApartment} element={<LocationDetailOverview />} />
                    <Route element={<GlobalAdminRoute />}>
                      <Route path={routes.locationsCreate} element={<LocationForm />} />
                    </Route>
                  </Route>
                  <Route path={routes.userManagement}>
                    <Route index element={<UserManagement />} />
                  </Route>

                  <Route path={routes.organization}>
                    <Route index element={<Organization />} />
                  </Route>
                  <Route path={routes.notifications}>
                    <Route index element={<Notifications />} />
                  </Route>
                </Route>
                <Route path={routes.callback} element={<Callback />} />
              </Routes>
            </div>
          </Grid>
          <SidePanelProvider />
          {/* <AppDrawer /> */}
        </div>
      )}
    </div>
  );
});
