import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import React, {FC, memo, useCallback} from 'react';
import {Controller, Message, useFormContext, ValidationRule} from 'react-hook-form';
import {theme} from 'src/styles/theme';

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  validators?: Partial<{
    required: Message | ValidationRule<boolean>;
    min: ValidationRule<number | string>;
    max: ValidationRule<number | string>;
    maxLength: ValidationRule<number>;
    minLength: ValidationRule<number>;
    pattern: ValidationRule<RegExp>;
    valueAsNumber: boolean;
    valueAsDate: boolean;
    setValueAs: (value: any) => any;
    shouldUnregister?: boolean;
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled: boolean;
  }>;
}

export const SidePanelCheckboxInput: FC<Props> = memo((props) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const buildField = useCallback(
    ({field}) => (
      <FormControl sx={{marginTop: 1, marginBottom: 1, marginLeft: 0, justifyContent: 'end'}} disabled={props.disabled}>
        <FormControlLabel
          sx={{color: theme.colors.sidePanel.sidePanelTextInputLabelColor}}
          label={props.label ?? ''}
          control={
            <Checkbox
              checked={(field.value === true || field.value === 0) ?? false}
              onChange={(e) => field.onChange(e.target.checked)}
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
            />
          }
        />
        {!!errors[props.name] && (
          <FormHelperText sx={{color: theme.palette.error.main}}>{errors[props.name].message}</FormHelperText>
        )}
      </FormControl>
    ),
    [errors, props.disabled, props.label, props.name],
  );

  return <Controller name={props.name} control={control} rules={props.validators} render={buildField} />;
});
