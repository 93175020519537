import {createAsyncThunk} from '@reduxjs/toolkit';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {OrganizationClient, Organization, OrganizationDTO} from '../../../api/api';
import {organizationClient} from '../../api-clients';

export const getOrganizations = createAsyncThunk('organizations/getOrganizations', (dispatch) => {
  return AsyncOperationHandler((client) => (client as OrganizationClient).get(), organizationClient);
});

export const getOrganizationById = createAsyncThunk('organizations/getOrganizationById', (organizationId: number) => {
  return AsyncOperationHandler((client) => (client as OrganizationClient).getById(organizationId), organizationClient);
});

export const saveOrganization = createAsyncThunk('organizations/saveOrganizations', (organization: OrganizationDTO) => {
  return AsyncOperationHandler((client) => (client as OrganizationClient).post(organization), organizationClient, {
    showResponseMessage: true,
  });
});

export const updateOrganization = createAsyncThunk(
  'organizations/updateOrganizations',
  (organization: OrganizationDTO) => {
    return AsyncOperationHandler((client) => (client as OrganizationClient).put(organization), organizationClient, {
      showResponseMessage: true,
    });
  },
);

export const deleteOrganization = createAsyncThunk('organizations/deleteOrganizations', (organization: OrganizationDTO) => {
  return AsyncOperationHandler((client) => (client as OrganizationClient).delete(organization), organizationClient);
});