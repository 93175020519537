import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {applySidePanelContent, setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {Pages} from 'src/utilities/enums/pages';
import {TxButton} from 'src/view/components/default-components/buttons/tx-button';
import {StyledTab} from 'src/view/components/default-components/page-header.tsx/page-header-tab';
import {PageHeaderTabContainer} from 'src/view/components/default-components/page-header.tsx/page-header-tab-container';
import {useAuth} from '../../../auth/auth-provider';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {getOrganizations} from '../../../state/features/organization/operation';
import {ViewType} from '../../../state/features/page-header-setting/types';
import {AppState} from '../../../state/store';
import {FlexColumn} from '../../components/default-components/flex-column';
import {FlexRow} from '../../components/default-components/flex-row';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../components/default-components/page-header.tsx/page-header-content';
import {PageHeaderSearch} from '../../components/default-components/page-header.tsx/page-header-search';
import {PageHeaderSort} from '../../components/default-components/page-header.tsx/page-header-sort';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {BasicSpinner} from '../../components/default-components/spinner/basic-spinner';
import {OrganizationBoxView} from './organization-box/organization-box-view';
import {OrganizationEdit} from './organization-edit/organization-edit';
import {OrganizationListView} from './organization-list/organization-list-view';

export const Organization: FC = React.memo(() => {
  const navigate = useNavigate();
  const {isGlobalAdmin} = useAuth();
  const pageSettings = useSelector((store: AppState) => store.pageSettingReducer.pageSettings);
  const pageSetting = pageSettings.find((setting) => setting.key === Pages.Organizations);
  const showSpinner = useSelector(
    (store: AppState) => store.organizationReducer.pending && store.organizationReducer.organizations.length < 1,
  );
  const dispatch = useDispatch();

  const openCreateOrganization = () => {
    // Display the organization editor in a side bar
    dispatch(applySidePanelContent(<OrganizationEdit />));
    dispatch(setSidePanelOpen(true));
  };

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  return (
    <PageTitle title={localized('Organizations')}>
      <PageHeader
        area={Pages.Organizations}
        title={localized('Organizations')}
        postfix={
          isGlobalAdmin && (
            <TxButton
              onClick={openCreateOrganization}
              text={'+ ' + localizedInterpolation('CreateNewInt', {area: localized('Organization')})}
            />
          )
        }>
        <PageHeaderContent>
          <FlexColumn flex={1}>
            <FlexRow flex={1} flexWrap="wrap">
              <PageHeaderTabContainer initValue={0}>
                <></>
              </PageHeaderTabContainer>
              {/* Implement when box view in introduced */}
              {/* <PageHeaderToggleView /> */}
              <PageHeaderSearch />
              <PageHeaderSort />
            </FlexRow>
          </FlexColumn>
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer>
        {showSpinner ? (
          <BasicSpinner />
        ) : !pageSetting || pageSetting.itemView === ViewType.BoxView ? (
          <OrganizationListView />
        ) : pageSetting.itemView === ViewType.ListView ? (
          <OrganizationListView />
        ) : null}
      </PageContentContainer>
    </PageTitle>
  );
});
