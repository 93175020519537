import {SortDirection} from '@mui/material';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {createSelector} from 'reselect';
import {AppState} from '../../store';
import {setOrganizationSetting} from '../settings/settings-slice';
import {PageHeaderSettingState, ViewType} from './types';

const initialState: PageHeaderSettingState = {
  pageSettings: [],
};

export const pageSettingSlice = createSlice({
  name: 'pageSetting',
  initialState,
  reducers: {
    toggleView: (state, action: PayloadAction<{value: ViewType; key: string}>) => {
      if (action.payload.key) {
        let settingExist = state.pageSettings.find((setting) => setting.key === action.payload.key);
        if (settingExist) {
          settingExist.itemView = action.payload.value;
        } else {
          state.pageSettings.push({
            key: action.payload.key,
            sort: 'asc',
            itemView: action.payload.value,
            tabIndex: false,
          });
        }
      }
    },
    setTabIndex: (state, action: PayloadAction<{value: any; key: string}>) => {
      if (action.payload.key) {
        let settingExist = state.pageSettings.find((setting) => setting.key === action.payload.key);
        if (settingExist) {
          settingExist.tabIndex = action.payload.value;
        } else {
          state.pageSettings.push({
            key: action.payload.key,
            sort: 'asc',
            itemView: ViewType.BoxView,
            tabIndex: action.payload.value,
          });
        }
      }
    },
    searchChanged: (state, action: PayloadAction<{value: string; key: string}>) => {
      state.searchString = action.payload.value;
    },
    toggleSort: (state, action: PayloadAction<{value: SortDirection; key: string}>) => {
      if (action.payload.key) {
        let settingExist = state.pageSettings.find((setting) => setting.key === action.payload.key);
        if (settingExist) {
          settingExist.sort = action.payload.value;
        } else {
          state.pageSettings.push({
            key: action.payload.key,
            sort: action.payload.value,
            itemView: ViewType.BoxView,
            tabIndex: false,
          });
        }
      }
    },
    clearPageSetting: (state, action: PayloadAction<string>) => {
      state.pageSettings = state.pageSettings.filter((setting) => setting.key && setting.key !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setOrganizationSetting, (state) => {
      state.pageSettings = [];
    });
  },
});

export const selectPageSetting = (store: AppState) => store.pageSettingReducer.pageSettings;

export const selectPageSettingByKey = (key: string) =>
  createSelector(selectPageSetting, (settings) => {
    return settings.find((setting) => setting.key === key);
  });

export const selectPageSettingTabIndex = (key: string) =>
  createSelector(selectPageSetting, (settings) => {
    return settings.find((setting) => setting.key === key)?.tabIndex;
  });

export const {toggleView, searchChanged, toggleSort, setTabIndex, clearPageSetting} = pageSettingSlice.actions;

export default pageSettingSlice.reducer;
