import React, {FC, useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {getNotificationSettings} from 'src/state/features/notification-setting/operation';
import {routes} from 'src/state/routes';
import {AppState} from 'src/state/store';
import {usePrevious} from 'src/utilities/use-previous-hook';
import {localized} from '../../../../i18n/i18n';
import {getApartments} from '../../../../state/features/apartment/operation';
import {FlexRow} from '../../../components/default-components/flex-row';
import {PageTitle} from '../../../components/default-components/page-title/page-title';
import {ApartmentDetail} from './apartment-detail/apartment-detail';
import {ApartmentListOverview} from './apartment-list/apartment-list-overview';

export const LocationDetailContext = React.createContext({} as any);
export const useWizard = () => useContext(LocationDetailContext);

export const LocationDetailOverview: FC = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {locationId, apartmentId} = useParams();
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  useEffect(() => {
    if (locationId) {
      dispatch(getApartments(Number(locationId)));
    }
  }, [dispatch, locationId]);

  const prevOrg = usePrevious(selectedOrganization);
  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getNotificationSettings(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  // Route to location overview, if org is changed, while at another orgs locations
  useEffect(() => {
    if (selectedOrganization && prevOrg && prevOrg.id !== selectedOrganization.id) {
      navigate(routes.locations);
    }
  }, [navigate, selectedOrganization,prevOrg]);

  return (
    <PageTitle title={localized('Locations')}>
      <FlexRow>
        <ApartmentListOverview
          locationId={locationId ? +locationId : undefined}
          apartmentId={apartmentId ? +apartmentId : undefined}
        />
        <ApartmentDetail apartmentId={apartmentId ? +apartmentId : undefined} />
      </FlexRow>
    </PageTitle>
  );
});
