import {MeasureType, NotificationInterval, OrganizationRole, RoomType, UserRole} from '../../api/api';
import {localizedDynamic} from '../../i18n/i18n';
import {DropdownOption} from '../../view/components/default-components/baisc-dropdown/basic-search-dropdown';

export function getUserRoleDropdownOptions(): DropdownOption[] {
  var options = Object.keys(UserRole)
    .filter((k) => typeof UserRole[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), UserRole[e as any]));

  return options.filter((option) => option.label !== 'Admin');
}

export function getOrganizationRoleDropdownOptions(): DropdownOption[] {
  return Object.keys(OrganizationRole)
    .filter(
      (k) =>
        typeof OrganizationRole[k as any] === 'number' &&
        +OrganizationRole[k as any] !== 20 &&
        +OrganizationRole[k as any] !== 10,
    )
    .map((e) => new DropdownOption(localizedDynamic(e), OrganizationRole[e as any]));
}

export function getMeasureTypeOptions(): DropdownOption[] {
  return Object.keys(MeasureType)
    .filter((k) => typeof MeasureType[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), MeasureType[e as any]));
}

export function getNotificationIntervalOptions(): DropdownOption[] {
  return Object.keys(NotificationInterval)
    .filter((k) => typeof NotificationInterval[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), NotificationInterval[e as any]));
}

export const recordToEntries = <T extends Record<string, unknown>>(record: T, isEnum = true) => {
  return Object.keys(record)
    .filter((entry) => !isEnum || isNaN(Number(entry)))
    .map((key: keyof typeof record) => ({
      key,
      value: record[key],
    }));
};

export function getRoomTypeOptions(): DropdownOption[] {
  return Object.keys(RoomType)
    .filter((k) => typeof RoomType[k as any] === 'number')
    .map((e) => new DropdownOption(localizedDynamic(e), RoomType[e as any]));
}
