import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateOrganization, saveOrganization} from 'src/state/features/organization/operation';
import {selectOrganisationById} from 'src/state/features/organization/organization-slice';
import {OrganizationDTO} from '../../../../api/api';
import {localized} from '../../../../i18n/i18n';
import {setSidePanelOpen} from '../../../../state/features/webapp/webapp-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {nameof} from '../../../../utilities/platform-helpers/nameof-helper';
import {requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {Form} from '../../../components/default-components/form/form';
import {SidePanelTextInput} from '../../../components/default-components/side-panel/side-panel-text-input';
import {SidePanelTitel} from '../../../components/default-components/side-panel/side-panel-titel';

interface PropsFromParent {
  organizationId?: number;
}

export const OrganizationEdit: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const item = useSelector(selectOrganisationById(props.organizationId));
  const savePending = useSelector((store: AppState) => store.locationReducer.pending);

  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);

  const save = useCallback(
    (organization: OrganizationDTO) => {
      if (item) {
        dispatch(updateOrganization(organization as OrganizationDTO));
      } else {
        dispatch(saveOrganization(organization as OrganizationDTO));
      }
      closeSidePanel();
    },
    [closeSidePanel, dispatch, item],
  );

  return (
    <>
      <SidePanelTitel
        title={item ? localized('Edit') : localized('CreateOrganization')}
        subTitle={item?.name ?? localized('CreateOrganizationFormSubTitle')}
      />
      <Form onSubmit={save} sx={{maxWidth: 400, marginTop: theme.spacing(3)}} defaultValues={item}>
        <SidePanelTextInput
          name={nameof<OrganizationDTO>('name')}
          label={localized('Name')}
          validators={requiredValidator}
        />
        <SidePanelTextInput
          name={nameof<OrganizationDTO>('description')}
          label={localized('Description')}
          validators={requiredValidator}
        />
        <BasicButton type="submit" buttonColor={'primary'} disabled={savePending} text={localized('SaveAndClose')} />
      </Form>
    </>
  );
});
