import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  INotificationRangeDTO,
  INotificationSettingDTO,
  NotificationRangeDTO,
  NotificationSettingDTO,
} from 'src/api/api';
import {localized, localizedInterpolation} from 'src/i18n/i18n';
import {createNotificationRange, saveNotificationSettings} from 'src/state/features/notification-setting/operation';
import {setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {AppState} from 'src/state/store';
import {theme} from 'src/styles/theme';
import {getMeasureTypeOptions, getRoomTypeOptions} from 'src/utilities/platform-helpers/enum-helper';
import {nameof} from 'src/utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'src/utilities/platform-helpers/validator-helper';
import {Form} from 'src/view/components/default-components/form/form';
import {SidePanelButton} from 'src/view/components/default-components/side-panel/side-panel-button';
import {SidePanelSelectInput} from 'src/view/components/default-components/side-panel/side-panel-select-input';
import {SidePanelTextInput} from 'src/view/components/default-components/side-panel/side-panel-text-input';
import {SidePanelTitel} from 'src/view/components/default-components/side-panel/side-panel-titel';

interface PropsFromParent {}

export const CreateProfile: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const savePending = useSelector((store: AppState) => store.notificationSettingReducer.pending);
  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);
  const save = useCallback(
    (setting: NotificationSettingDTO) => {
      dispatch(saveNotificationSettings({notificationSetting: setting, callbackOnSuccess: closeSidePanel}));
    },
    [closeSidePanel, dispatch],
  );
  return (
    <>
      <SidePanelTitel
        title={localizedInterpolation('CreateNewInt', {area: localized('NotificationProfile').toLowerCase()})}
      />
      <Form
        onSubmit={save}
        sx={{maxWidth: 400, marginTop: theme.spacing(3)}}
        defaultValues={{organizationId: selectedOrganization?.id}}>
        <SidePanelTextInput
          name={nameof<INotificationSettingDTO>('name')}
          label={localized('Name')}
          validators={requiredValidator}
        />
        <SidePanelSelectInput
          name={nameof<INotificationSettingDTO>('roomType')}
          noDefault
          options={getRoomTypeOptions()}
          validators={requiredValidator}
          label={localized('ChooseRoomType')}
        />

        <SidePanelButton
          type="submit"
          buttonColor={'primary'}
          disabled={savePending}
          text={localized('SaveAndClose')}
        />
      </Form>
    </>
  );
});
