import {SvgIcon, SvgIconProps} from '@mui/material';
import React, {FC} from 'react';
import {disableColor} from '../../../styles/color-constants';

interface PropsFromParent {
  component: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string}>;
}

export const BasicSvgIcon: FC<SvgIconProps & PropsFromParent> = React.memo((props) => {
  return <SvgIcon  stroke={disableColor} inheritViewBox={true} {...props} />;
});
