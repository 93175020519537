import {Box, Drawer} from '@mui/material';
import React, {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setSidePanelOpen} from '../../../../state/features/webapp/webapp-slice';
import {AppState} from '../../../../state/store';

interface PropsFromParent {}
export const SidePanelProvider: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const {sidePanelOpen, sidePanelContent} = useSelector((store: AppState) => store.webAppReducer);

  const toggleDrawer = () => {
    dispatch(setSidePanelOpen(!sidePanelOpen));
  };
  return (
    <React.Fragment key={'right'}>
      <Drawer
        BackdropProps={{sx: {backgroundColor: 'transparent'}}}
        onBackdropClick={toggleDrawer}
        anchor={'right'}
        open={sidePanelOpen}>
        <Box sx={{paddingX: 8, paddingY: 6, backgroundColor: 'secondary.main', height: '100%'}}>{sidePanelContent}</Box>
      </Drawer>
    </React.Fragment>
  );
});
