import {Theme} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {SeverityLevel} from 'src/api/api';
import {useAuth} from 'src/auth/auth-provider';
import {selectPageSettingByKey} from 'src/state/features/page-header-setting/page-header-setting-slice';
import {getThermexUnitsByLocation} from 'src/state/features/thermex-unit/operation';
import {selectThermexUnitsCountByLocation} from 'src/state/features/thermex-unit/thermex-unit-slice';
import {applySidePanelContent, setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {ReactComponent as HouseIcon} from '../../../../../assets/house_icon.svg';
import {localized, localizedInterpolation} from '../../../../../i18n/i18n';
import {selectApartmentsByLocationId} from '../../../../../state/features/apartment/apartment-slice';
import {selectLocationById} from '../../../../../state/features/location/location-slice';
import {routes} from '../../../../../state/routes';
import {Pages} from '../../../../../utilities/enums/pages';
import {BasicSvgIcon} from '../../../../components/default-components/basic-svg-icon';
import {FlexColumn} from '../../../../components/default-components/flex-column';
import {FlexRow} from '../../../../components/default-components/flex-row';
import {PageContentContainer} from '../../../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../../../components/default-components/page-header.tsx/page-header-content';
import {StyledTab} from '../../../../components/default-components/page-header.tsx/page-header-tab';
import {PageHeaderTabContainer} from '../../../../components/default-components/page-header.tsx/page-header-tab-container';
import {BackToPage} from '../../../../components/navigation/back-to-page';
import {ApartmentEdit} from '../apartment-edit/apartment-edit';
import {ApartmentList} from './apartment-list';

interface PropsFromParent {
  locationId: number | undefined;
  apartmentId: number | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    devicesLabelText: {
      marginLeft: 10,
    },
    houseIcon: {
      marginBottom: 5,
    },
  }),
);

export const ApartmentListOverview: FC<PropsFromParent> = React.memo(({locationId, apartmentId}) => {
  const dispatch = useDispatch();
  const {isGlobalAdmin} = useAuth();
  const {name} = useSelector(selectLocationById(locationId)) ?? {};
  const apartments = useSelector(selectApartmentsByLocationId(locationId));
  const pageSetting = useSelector(selectPageSettingByKey(Pages.ApartmentList));
  const filteredApartments = useMemo(() => {
    // if index 1 (Remarks) is selected, only show apartments with a severity level other than clear
    return apartments.filter(
      (a) => pageSetting?.tabIndex === 0 || (a.severityLevel !== SeverityLevel.Clear && pageSetting?.tabIndex === 1),
    );
  }, [apartments, pageSetting]);
  const apartmentIds = useMemo(() => filteredApartments.map((a) => a.id), [filteredApartments]);
  const thermexUnitsCount = useSelector(selectThermexUnitsCountByLocation(locationId));
  const classes = useStyles();
  const navigate = useNavigate();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorElRef = useRef<Element | null>();

  const createApartment = useCallback(() => {
    // Display the location editor in a side bar
    dispatch(applySidePanelContent(<ApartmentEdit locationId={locationId} />));
    dispatch(setSidePanelOpen(true));
  }, [dispatch, locationId]);

  useEffect(() => {
    if (locationId) {
      dispatch(getThermexUnitsByLocation(locationId));
    }
  }, []);

  const onBackToPage = useCallback(() => {
    navigate(routes.locations);
  }, [navigate]);

  const unitsLabel = (
    <FlexRow alignItems="center">
      <BasicSvgIcon component={HouseIcon} className={classes.houseIcon} />
      <span className={classes.devicesLabelText}>{localizedInterpolation('UnitsX', {x: thermexUnitsCount})}</span>
    </FlexRow>
  );

  return (
    <FlexColumn flex={1} sx={{minWidth: 500}}>
      <PageHeader area={Pages.ApartmentList} title={name ?? ''} prefix={<BackToPage onClick={onBackToPage} />}>
        <PageHeaderContent>
          <FlexColumn flex={1}>
            <FlexRow flex={1} flexWrap="wrap">
              <PageHeaderTabContainer initValue={0}>
                <StyledTab label={unitsLabel} />
                <StyledTab label={localized('Remark')} />
              </PageHeaderTabContainer>
              {isGlobalAdmin && <StyledTab onClick={createApartment} label={'+ ' + localized('CreateApartment')} />}
            </FlexRow>
          </FlexColumn>
        </PageHeaderContent>
      </PageHeader>
      <PageContentContainer>
        <ApartmentList apartmentId={apartmentId} apartmentIds={apartmentIds} />
      </PageContentContainer>
    </FlexColumn>
  );
});
