import React from 'react';

const integerOnlyRegex = /^[0-9]+$/;

export function DecimalNumberChanged(newValue: string, setState: (valueToSet: number | undefined) => void) {
  newValue = newValue.replace(',', '.');

  const newValueNumeric = parseFloat(newValue);

  if (newValue !== '' && typeof newValueNumeric === 'number' && !isNaN(newValueNumeric)) {
    if (newValue[newValue.length - 1] !== '.') {
      setState(newValueNumeric);
    }
    return;
  }

  setState(undefined);
}

export function ConvertToDecimalRegex(text: string) {
  return text
    .replace(/(?!^)-/g, '') // removes any - that is not first character in string
    .replace(/,/g, '.') // replaces , with .
    .replace(/\..*/, (c) => '.' + c.replace(/\./g, () => '')) // ensures there is at most 1 . in string
    .replace(/ /g, ''); // replaces any spaces with nothing
}

export function ValidateDecimalRegex(regexString: string) {
  let rgx = /^-?[0-9]*\.?[0-9]*$/;
  return regexString.match(rgx);
}

export function ConvertExponentialValueToString(numberToConvert: number) {
  let data = String(numberToConvert).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = '',
    sign = numberToConvert < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
}

export const preventNonNumericalInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!e.key.match(integerOnlyRegex)) {
    e.preventDefault();
  }
};
