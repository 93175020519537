import styled from '@emotion/styled';
import {Tabs} from '@mui/material';
import React, {FC, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isNullOrUndefinedOrFalse} from 'src/utilities/helpers/general-helpers';
import {setTabIndex} from '../../../../state/features/page-header-setting/page-header-setting-slice';
import {AppState} from '../../../../state/store';
import {theme} from '../../../../styles/theme';
import {usePageHeader} from './page-header';

interface PropsFromParent {
  initValue?: any;
}

export const PageHeaderTabContainer: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const {areaId} = usePageHeader();

  const pageSetting = useSelector((store: AppState) =>
    store.pageSettingReducer.pageSettings.find((setting) => setting.key === areaId),
  );

  // Set default tab index, if no pageSetting is available
  useEffect(() => {
    if (!pageSetting) {
      dispatch(setTabIndex({value: !isNullOrUndefinedOrFalse(props.initValue) ? props.initValue : false, key: areaId}));
    }
  }, [pageSetting, areaId, dispatch]);

  const tabChanged = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: any) => {
      dispatch(setTabIndex({value, key: areaId}));
    },
    [dispatch, areaId],
  );

  if (!props.children) {
    return null;
  }

  return (
    <StyledTabs
      value={!isNullOrUndefinedOrFalse(pageSetting?.tabIndex) ? pageSetting?.tabIndex : props.initValue}
      onChange={tabChanged}
      aria-label="primary tabs example">
      {props.children}
    </StyledTabs>
  );
});

export const StyledTabs = styled(Tabs)({
  height: 35,
  minHeight: 35,
  overflow: 'visible',
  flex: 1,
  '&  .MuiTabs-fixed': {
    overflow: 'visible !important',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    height: 6,
    backgroundColor: theme.palette.text.secondary,
    bottom: -3.5,
    overflow: 'visible',
  },
  borderBottomColor: theme.colors.disableColor,
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
});

PageHeaderTabContainer.defaultProps = {
  initValue: false,
};
