import * as React from 'react';
import {FC, memo, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {RedirectUriStorageKey} from '../utilities/constants';
import {useAuth} from './auth-provider';

export const PrivateRoute: FC = memo(() => {
  const {isAuthenticated, loading: isLoading, login} = useAuth();
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      window.sessionStorage.setItem(RedirectUriStorageKey, window.location.pathname);
      login();
    };
    fn();
  }, [isLoading, isAuthenticated, login]);

  return <Outlet />;
});
