import {MeasureType,  RoomType} from '../../api/api';
import {localized, localizedDynamic} from '../../i18n/i18n';

export const getLocalizedRoomType = (type: RoomType) => {
  const text = 'RoomType.' + RoomType[type];
  const translation = localizedDynamic(text);

  return text === translation ? localized('StandardRoom') : translation;
};

export const getLocalizedMeasureUnitType = (type: MeasureType) => {
  const keyPrefix = 'UnitTypeTextFormat.';
  const text = keyPrefix + MeasureType[type];
  const translation = localizedDynamic(text);

  return text === translation ? localizedDynamic(keyPrefix + MeasureType[MeasureType.VOCINDEX]) : translation;
};
