export interface INumericDTO {
  id: number;
}

export const addElement = <T extends INumericDTO>(arr: T[], updated: T): T[] => (arr ? arr.concat(updated) : [updated]);
export const addElements = <T extends INumericDTO>(arr: T[], updated: T[]): T[] =>
  arr ? arr.concat(updated) : updated;
export const removeElement = <T extends INumericDTO>(arr: T[], updated: T): T[] =>
  arr?.filter((e) => e.id !== updated.id);
export const removeElements = <T extends INumericDTO>(arr: T[], updated: T[]): T[] => {
  const updatedSet = new Set<number>(updated?.map((u) => u.id));
  return arr?.filter((e) => !updatedSet.has(e.id));
};
export const updateElement = <T extends INumericDTO>(arr: T[], updated: T): T[] =>
  arr?.map((e) => (e.id === updated.id ? updated : e));
export const updateElements = <T extends INumericDTO>(arr: T[], updated: T[]): T[] => {
  const updatedMap = new Map<number, T>(updated?.map((u) => [u.id, u]));
  return arr?.map((e) => updatedMap.get(e.id) || e);
};

export const updateElementCustom = <T extends INumericDTO>(
  arr: T[],
  updated: T,
  comparer: (existing: T, update: T) => boolean,
): T[] => arr?.map((e) => (comparer(e, updated) ? updated : e));

export const removeElementCustom = <T extends INumericDTO>(
  arr: T[],
  updated: T,
  comparer: (existing: T, update: T) => boolean,
): T[] => arr?.filter((e) => comparer(e, updated));

export const mergeElements = <T extends INumericDTO>(arr: T[], updated: T[]): T[] => {
  const updatedMap = new Map<number, T>(updated?.map((u) => [u.id, u]));
  const merged = arr?.map((e) => {
    const updated = updatedMap.get(e.id);
    updatedMap.delete(e.id);
    return updated || e;
  });
  //Since we delete from map of updated values when element matches, whatever is left now, must be new values - so we add them
  merged?.push(...updatedMap.values());
  return merged;
};
