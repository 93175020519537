import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {WebappState} from './types';

const initialState: WebappState = {
  open: false,
  showOverlaySpinner: false,
  sidePanelOpen: false,
};

export const webappSlice = createSlice({
  name: 'webappSettings',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.open = !state.open;
    },
    setOverlaySpinner: (state, action: PayloadAction<boolean>) => {
      state.showOverlaySpinner = action.payload;
    },
    setSidePanelOpen: (state, action: PayloadAction<boolean>) => {
      state.sidePanelOpen = action.payload;
    },
    applySidePanelContent: (state, action: PayloadAction<JSX.Element | undefined>) => {
      if(action.payload){
        state.sidePanelContent = action.payload;
        state.sidePanelOpen = true;
      }else{
        state.sidePanelOpen = false;
      }
    },
  },
});

export const {toggleDrawer, setOverlaySpinner, setSidePanelOpen, applySidePanelContent} = webappSlice.actions;
export default webappSlice.reducer;
