import {Divider, Typography, useMediaQuery} from '@mui/material';
import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  INotificationRangeDTO,
  INotificationSettingDTO,
  IThermexUnitDTO,
  NotificationRangeDTO,
  NotificationSettingDTO,
  ThermexUnitDTO,
} from 'src/api/api';
import {localized, localizedInterpolation} from 'src/i18n/i18n';
import {selectNotificationSettingByRoomType} from 'src/state/features/notification-setting/notification-setting-slice';
import {createNotificationRange, saveNotificationSettings} from 'src/state/features/notification-setting/operation';
import {removeThermexUnit, updateThermexUnit} from 'src/state/features/thermex-unit/operation';
import {selectThermexUnitById} from 'src/state/features/thermex-unit/thermex-unit-slice';
import {setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {AppState} from 'src/state/store';
import {theme} from 'src/styles/theme';
import {getMeasureTypeOptions, getRoomTypeOptions} from 'src/utilities/platform-helpers/enum-helper';
import {nameof} from 'src/utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'src/utilities/platform-helpers/validator-helper';
import {DropdownOption} from 'src/view/components/default-components/baisc-dropdown/basic-search-dropdown';
import {BasicIcon} from 'src/view/components/default-components/basic-icon';
import {FlexRow} from 'src/view/components/default-components/flex-row';
import {Form} from 'src/view/components/default-components/form/form';
import {SidePanelButton} from 'src/view/components/default-components/side-panel/side-panel-button';
import {SidePanelSelectInput} from 'src/view/components/default-components/side-panel/side-panel-select-input';
import {SidePanelTextInput} from 'src/view/components/default-components/side-panel/side-panel-text-input';
import {SidePanelTitel} from 'src/view/components/default-components/side-panel/side-panel-titel';

interface PropsFromParent {
  thermexUnitId: string | undefined;
}

export const EditRoom: FC<PropsFromParent> = React.memo(({thermexUnitId}) => {
  const dispatch = useDispatch();
  const thermexUnit = useSelector(selectThermexUnitById(thermexUnitId));

  const savePending = useSelector((store: AppState) => store.thermexUnitReducer.pending);
  const notificationSettings = useSelector(selectNotificationSettingByRoomType(thermexUnit?.roomType));
  const settingOptions = useMemo(() => {
    return notificationSettings ? notificationSettings.map((ns) => new DropdownOption(ns.name ?? '', ns.id)) : [];
  }, [notificationSettings]);

  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);

  const save = useCallback(
    (thermexUnit: ThermexUnitDTO) => {
      dispatch(updateThermexUnit({thermexUnit, callbackOnSuccess: closeSidePanel}));
    },
    [closeSidePanel, dispatch],
  );

  const removeThermexUnitClicked = async () => {
    if (
      await confirm(
        localizedInterpolation('AreYouSureYouWantToDelete', {
          area: thermexUnit?.name,
        }),
      )
    ) {
      if (thermexUnit) {
        dispatch(removeThermexUnit(thermexUnit as ThermexUnitDTO));
        closeSidePanel();
      }
    }
  };

  return (
    <>
      <SidePanelTitel
        title={localizedInterpolation('EditInt', {area: localized('Sensor').toLowerCase()})}
      />
      <Form onSubmit={save} sx={{maxWidth: 400, marginTop: theme.spacing(3), gap: 3}} defaultValues={thermexUnit}>
        <Typography sx={{color: theme.colors.sidePanel.sidePanelTextInputLabelColor}}>
          {`${localized('SensorId')}: ${thermexUnit?.sensorId}`}
        </Typography>
        <SidePanelTextInput
          name={nameof<IThermexUnitDTO>('name')}
          label={localized('Name')}
          validators={requiredValidator}
        />
        <SidePanelSelectInput
          name={nameof<IThermexUnitDTO>('roomType')}
          noDefault
          options={getRoomTypeOptions()}
          validators={requiredValidator}
          label={localized('ChooseRoomType')}
          sx={{width: 'unset'}}
        />
        <Divider sx={{margin: '35px 0 35px 0', background: theme.colors.sidePanel.sidePanelTextInputLabelColor}} />
        <SidePanelSelectInput
          name={nameof<IThermexUnitDTO>('notificationSettingId')}
          noDefault
          options={settingOptions}
          validators={requiredValidator}
          label={localized('ChooseNotificationProfile')}
          sx={{width: 'unset'}}
        />
        <FlexRow sx={{gap: 4}}>
          <SidePanelButton
            type="submit"
            buttonColor={'primary'}
            disabled={savePending}
            text={localized('SaveAndClose')}
          />
          <FlexRow sx={{alignItems: 'center', gap: 1}}>
            <Typography sx={{color: theme.colors.sidePanel.sidePanelTextInputLabelColor}}>
              {localized('DeleteRoom')}
            </Typography>
            <BasicIcon
              onClick={removeThermexUnitClicked}
              icon="delete"
              iconColor={theme.colors.sidePanel.sidePanelTextInputLabelColor}
              title={localized('Delete')}
            />
          </FlexRow>
        </FlexRow>
      </Form>
    </>
  );
});
