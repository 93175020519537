import styled from '@emotion/styled';
import {Box, Tabs} from '@mui/material';
import React, {FC, useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {theme} from 'src/styles/theme';
import {isNullOrUndefinedOrFalse} from 'src/utilities/helpers/general-helpers';
import {useNsContext} from './notifications';

interface PropsFromParent {
  initValue?: any;
}

export const NotificationSelectContainer: FC<PropsFromParent> = React.memo((props) => {
  const {setSelectedNotificationId, selectedNotificationId} = useNsContext();
  const tabChanged = useCallback((event: React.SyntheticEvent<Element, Event>, value: any) => {
    setSelectedNotificationId(value);
  }, []);
  return (
    <StyledTabs
      aria-label="vertical tabs example"
      orientation="vertical"
      value={selectedNotificationId}
      onChange={tabChanged}>
      {props.children}
    </StyledTabs>
  );
});

export const StyledTabs = styled(Tabs)({
  height: 'auto',
  overflow: 'visible',
  '&  .MuiTabs-fixed': {
    overflow: 'visible !important',
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    width: 6,
    backgroundColor: theme.palette.text.secondary,
    right: -3.5,
    overflow: 'visible',
  },
  borderRightWidth: 1,
});

NotificationSelectContainer.defaultProps = {
  initValue: false,
};
