import {Box, Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, {FC, useEffect, useRef, useState} from 'react';
import {List, ListRowRenderer} from 'react-virtualized';
import {VirtualizedListTableBoxHeight, VirtualizedListTableBoxHeightMobile} from '../../../../styles/styling-constants';
import {ListTableHeader} from '../list-table/list-table-header';
import './virtual-list-table.css';

interface PropsFromParent {
  columnSizes: number[];
  columnHeaders?: string[];
  maxWidth?: (number | undefined)[];
  items: any[];
  rowRenderer: ListRowRenderer;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      width: 'auto',
    },
    list: {
      height: VirtualizedListTableBoxHeight, // subtract page header,
      [theme.breakpoints.down('sm')]: {
        height: VirtualizedListTableBoxHeightMobile, // subtract page header and mobile menu-bars,
      },
      outline: 'none',
      width: 'auto !important',
    },
    scrollbarWidth: {},
  }),
);

type Props = PropsFromParent;

export const VirtualizedListTable: FC<Props> = React.memo(
  ({columnSizes, columnHeaders, maxWidth, items, rowRenderer}) => {
    const classes = useStyles();
    const divRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(divRef.current?.clientHeight || 1000);
    const [width, setWidth] = useState(divRef.current?.clientWidth || 4000);
    const [hasScroll, setHasScroll] = useState(false);

    // handles scroll bar, to align tableheader and tablerow
    useEffect(() => {
      const handler = () => {
        if (divRef && divRef.current) {
          const h = divRef.current.clientHeight;
          const w = divRef.current.clientWidth;
          if (height !== h) {
            setHeight(h);
          }
          const showScroll = h / 64 < items.length;

          if (showScroll !== hasScroll) {
            setHasScroll(showScroll);
          }

          if (width !== w) {
            setWidth(w);
          }
        }
      };
      handler();
      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
      // eslint-disable-next-line
    }, [items, hasScroll, height, width]);

    return (
      <div ref={divRef}>
        <Box>
          {columnHeaders && (
            <ListTableHeader listHasScroll={hasScroll} columnSizes={columnSizes} headers={columnHeaders} />
          )}
          <List
            className={classes.list}
            overscanRowCount={20}
            height={divRef.current?.clientHeight || height}
            width={divRef.current?.clientWidth || width}
            rowCount={items.length}
            rowHeight={74}
            rowRenderer={rowRenderer}
          />
        </Box>
      </div>
    );
  },
);
