import React, {FC, memo} from 'react';
import {Outlet, RouteProps} from 'react-router-dom';
import {BasicSpinner} from '../view/components/default-components/spinner/basic-spinner';
import {useAuth} from './auth-provider';
import {NoPermissionPage} from './no-permission-page';

interface Props extends RouteProps {
  element: any;
  path?: string;
}

// Redirect to start page, if somehow a normal user access an admin page
export const GlobalAdminRoute: FC = memo(() => {
  const {isGlobalAdmin, loading} = useAuth();
  if (loading) {
    return <BasicSpinner />;
  }
  if (!isGlobalAdmin) {
    return <NoPermissionPage />;
  }
  return <Outlet />;
});
