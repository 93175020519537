import {createAsyncThunk} from '@reduxjs/toolkit';
import {NotificationRangeDTO, NotificationSettingClient, NotificationSettingDTO} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {notificationSettingClient} from '../../api-clients';

export const getNotificationSettings = createAsyncThunk(
  'notificationSettings/getNotificationSettings',
  (organizationId: number) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSettingClient).get(organizationId),
      notificationSettingClient,
    );
  },
);

export const saveNotificationSettings = createAsyncThunk(
  'notificationSettings/saveNotificationSettings',
  (payload: {
    notificationSetting: NotificationSettingDTO;
    callbackOnSuccess?: (result: NotificationSettingDTO) => void;
  }) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSettingClient).post(payload.notificationSetting),
      notificationSettingClient,
      {
        showResponseMessage: true,
        callbackOnSuccess: payload.callbackOnSuccess,
      },
    );
  },
);

export const updateNotificationRange = createAsyncThunk(
  'notificationSettings/updateNotificationRange',
  (payload: {notificationRange: NotificationRangeDTO; callbackOnSuccess?: (result: NotificationRangeDTO) => void}) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSettingClient).updateNotificationRange(payload.notificationRange),
      notificationSettingClient,
    );
  },
);

export const createNotificationRange = createAsyncThunk(
  'notificationSettings/CreateNotificationRange',
  (payload: {notificationRange: NotificationRangeDTO; callbackOnSuccess?: (result: NotificationRangeDTO) => void}) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSettingClient).createNotificationRange(payload.notificationRange),
      notificationSettingClient,
      {
        showResponseMessage: true,
        callbackOnSuccess: payload.callbackOnSuccess,
      },
    );
  },
);

export const deleteNotificationRange = createAsyncThunk(
  'notificationSettings/deleteNotificationRange',
  (payload: {notificationRange: NotificationRangeDTO; callbackOnSuccess?: () => void}) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSettingClient).deleteNotificationRange(payload.notificationRange),
      notificationSettingClient,
    );
  },
);

export const updateNotificationSetting = createAsyncThunk(
  'notificationSettings/updateNotificationSetting',
  (setting: NotificationSettingDTO) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSettingClient).put(setting),
      notificationSettingClient,
    );
  },
);

export const deleteNotificationSetting = createAsyncThunk(
  'notificationSettings/deleteNotificationSetting',
  (setting: NotificationSettingDTO) => {
    return AsyncOperationHandler(
      (client) => (client as NotificationSettingClient).delete(setting),
      notificationSettingClient,
    );
  },
);
