import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React, {FC, memo, useCallback, useMemo} from 'react';
import {KeyboardArrowDown} from '@mui/icons-material';
import {DropdownOption} from './option-dropdown';

interface Props {
  options: DropdownOption[];
  noDefault?: boolean;
  disabled?: boolean;
  value?: any;
  sxContainer?: any;
  onChange?: (value: any) => void;
}

export const TxBasicSelect: FC<Props> = memo((props) => {
  const mapOptionsToItems = useMemo(
    () =>
      props.options.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.label}
        </MenuItem>
      )),
    [props.options],
  );

  const onChange = (event: SelectChangeEvent) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return props.options.length !== 0 ? (
    <Select
      onChange={onChange}
      variant="standard"
      disableUnderline
      sx={props.sxContainer}
      disabled={props.disabled}
      value={props.value}
      IconComponent={KeyboardArrowDown}>
      {mapOptionsToItems}
    </Select>
  ) : (
    <></>
  );
});
