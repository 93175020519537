import {DateTime, Interval} from 'luxon';
import {IRawThermexData} from 'src/api/api';
import {invalidColor, validColor} from 'src/styles/color-constants';
import {isNullOrUndefined} from './general-helpers';

export interface ThermexData {
  temperature: number;
  humidity: number;
  vocindex: number;
  batterylevel: number;
}
interface DataPoint {
  date: Date;
  value: number;
}
export type DataTypeArr = Record<keyof ThermexData, DataPoint[]>;
interface KeyValueData {
  [key: string]: {
    subData?: KeyValueData;
    count: number;
    combinedValue: number;
    hasError: boolean;
  };
}

export interface Dataset {
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
  tickColor: string[];
}
export interface IBarChartData {
  labels: string[];
  datasets: Dataset[];
  subDatasets?: IBarChartData[];
}

export interface Boundaries {
  lower: number;
  upper: number;
}

export const GroupDataByKey = (testData: IRawThermexData[]) => {
  const transformedArray: DataTypeArr = {} as DataTypeArr;
  const result = testData
    .slice()
    .reverse()
    .reduce(function (r, a) {
      const data: ThermexData = a;
      const keys = Object.keys(data).filter((key) => key !== 'timestamp');
      for (const key of keys) {
        if (a.timestamp) {
          const castedKey = key as keyof typeof data;

          transformedArray[castedKey] = transformedArray[castedKey] || [];

          transformedArray[castedKey].push({
            date: a.timestamp,
            value: data[castedKey],
          });
        }
      }

      return r;
    }, Object.create(null));

  return transformedArray;
};

const AccumulatedByDay = (dataPoints: DataPoint[], showError: {[key: string]: boolean}) => {
  // Find min date, to know how many data points we need
  var minDate = dataPoints
    .map((e) => DateTime.fromJSDate(e.date).toISODate())
    .reduce(function (a, b) {
      return a < b ? a : b;
    });

  let dataByDay = {} as KeyValueData;
  let end = DateTime.now();
  let start = DateTime.fromFormat(minDate, 'yyyy-MM-dd');
  let interval = Array.from(days(Interval.fromDateTimes(start, end)));
  interval.sort((d1, d2) => {
    if (d1 < d2) {
      return -1;
    }
    if (d1 > d2) {
      return 1;
    }
    return 0;
  });

  interval.forEach((date) => {
    let hours: any = {};
    for (var i = 0; i < 24; i++) {
      hours[i] = {count: 0, combinedValue: 0};
    }
    dataByDay[date.toISODate()] = {count: 0, combinedValue: 0, subData: hours, hasError: false};
  });

  dataPoints.map((dataPoint) => {
    const jsDate = DateTime.fromJSDate(dataPoint.date);
    const newDate = jsDate.toISODate();
    if (dataByDay[newDate]) {
      if (!isNullOrUndefined(dataPoint.value) && !isNaN(dataPoint.value)) {
        dataByDay[newDate].combinedValue += dataPoint.value;
        dataByDay[newDate].count += 1;
        dataByDay[newDate].hasError = showError && showError[newDate];
      }
      jsDate.hour;
      if (dataByDay[newDate] && dataByDay[newDate].subData && dataByDay[newDate].subData![jsDate.hour]) {
        dataByDay[newDate].subData![jsDate.hour].combinedValue += dataPoint.value;
        dataByDay[newDate].subData![jsDate.hour].count += 1;
        dataByDay[newDate].subData![jsDate.hour].hasError = showError && showError[newDate + jsDate.hour];
      }
    }
  });
  return dataByDay;
};

const AccumulatedByWeek1 = (dataByDate: KeyValueData, showError: {[key: string]: boolean}) => {
  // Starts at monday

  let dataByWeek = {} as KeyValueData;
  const length = Object.keys(dataByDate).length;
  Object.keys(dataByDate).map((key, i) => {
    const date = DateTime.fromFormat(key, 'yyyy-MM-dd');
    const weekYear = `${date.weekNumber} - ${date.year}`;
    if (!dataByWeek[weekYear]) {
      let initialSubset = {} as KeyValueData;
      initialSubset[key] = dataByDate[key];
      if (!isNullOrUndefined(dataByDate[key].combinedValue) && !isNaN(dataByDate[key].combinedValue)) {
        // Add average for each child. This is by date, not by date point. Since a one day can have more data points than another day.
        dataByWeek[weekYear] = {
          count: 1,
          combinedValue: dataByDate[key].count
            ? Math.round((dataByDate[key].combinedValue / dataByDate[key].count) * 10) / 10
            : 0,
          subData: initialSubset,
          hasError: showError && showError[weekYear],
        };
      }
    } else {
      if (!isNullOrUndefined(dataByDate[key].combinedValue) && !isNaN(dataByDate[key].combinedValue)) {
        // Add average for each child. This is by date, not by date point. Since a one day can have more data points than another day.
        dataByWeek[weekYear].combinedValue += dataByDate[key].count
          ? Math.round((dataByDate[key].combinedValue / dataByDate[key].count) * 10) / 10
          : 0;
        dataByWeek[weekYear].count += 1;
        dataByWeek[weekYear].subData![key] = dataByDate[key];
        dataByWeek[weekYear].hasError = showError && showError[weekYear];
      }
    }
  });
  return dataByWeek;
};

const Average = (data: KeyValueData) => {
  let newData = {...data};
  Object.keys(newData).map((key) => {
    if (newData[key].count && newData[key].combinedValue) {
      newData[key] = {
        ...newData[key],
        combinedValue: Math.round((newData[key].combinedValue / newData[key].count) * 10) / 10,
      };
    }
  });

  return newData;
};

const CreateBarCharDataSets = (data: KeyValueData, showError: {[key: string]: boolean}, numberOfDecimals = 1) => {
  let barChartData = {
    labels: [],
    datasets: [],
    subDatasets: [],
  } as IBarChartData;

  let dataset = {
    data: [],
    backgroundColor: [],
    borderColor: [],
    borderWidth: 1,
    tickColor: [],
  } as Dataset;
Object.keys(data).map((key) => {
    let color = data[key].hasError ? invalidColor : validColor;
    // Call recursive, to include all layers
    if (data[key].subData) {
      barChartData.subDatasets?.push(CreateBarCharDataSets(data[key].subData!, showError, numberOfDecimals));
    }

    // Calculate average value
    const average =
      data[key].count && data[key].combinedValue
        ? Math.round((data[key].combinedValue / data[key].count) * Math.pow(10, numberOfDecimals)) /
          Math.pow(10, numberOfDecimals)
        : 0;
    barChartData.labels.push(key);
    dataset.data.push(average);
    dataset.backgroundColor.push(color);
    dataset.borderColor.push(color);
    dataset.tickColor.push(color);
  });
  barChartData.datasets.push(dataset);

  return barChartData;
};

export const MakeDataSet = (data: DataPoint[], showError: {[key: string]: boolean}, numberOfDecimals = 1) => {
  if (!data || data.length <= 0) {
    return {labels: [], datasets: [{data: [], backgroundColor: [], borderColor: [], borderWidth: 0, tickColor: []}]};
  }
  // First divides the data into days, then into weeks.
  // From where it aggregates it into a bar chart data set
  // with weeks in the outer layer and days as subsets within that week.

  // By day
  let dataByDay = AccumulatedByDay(data, showError);

  // By week
  let dataByWeek = AccumulatedByWeek1(dataByDay, showError);

  // Create datasets
  let datasetByWeek = CreateBarCharDataSets(dataByWeek, showError, numberOfDecimals);

  return datasetByWeek;
};

function* days(interval: any) {
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({days: 1});
  }
}
