import {createSelector, createSlice, isAnyOf} from '@reduxjs/toolkit';
import {AppState} from '../../store';
import {deleteApartment, getApartments, saveApartment, updateApartment} from './operation';
import {ApartmentState} from './types';

const initialState: ApartmentState = {
  apartments: [],
  pending: false,
  isSuccess: false,
};

export const apartmentSlice = createSlice({
  name: 'apartments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getApartments.fulfilled, (state, action) => {
      state.pending = false;
      state.apartments = action.payload;
    });

    // Save
    builder.addCase(saveApartment.fulfilled, (state, action) => {
      state.apartments.push(action.payload);
    });

    // Update
    builder.addCase(updateApartment.fulfilled, (state, action) => {
      const index = state.apartments.findIndex((apartment) => apartment.id === action.payload.id);
      if (index > -1) {
        state.apartments[index] = action.payload;
      }
    });

    builder.addCase(deleteApartment.fulfilled, (state, action) => {
      const index = state.apartments.findIndex((loc) => loc.id === action.meta.arg.apartment.id);
      if (index > -1) {
        state.apartments.splice(index, 1);
      }
    });

    builder.addMatcher(isAnyOf(updateApartment.pending, saveApartment.pending, deleteApartment.pending), (state) => {
      state.pending = true;
      state.isSuccess = false;
    });

    builder.addMatcher(isAnyOf(updateApartment.rejected, saveApartment.rejected, deleteApartment.rejected), (state) => {
      state.pending = false;
      state.isSuccess = false;
    });

    builder.addMatcher(
      isAnyOf(updateApartment.fulfilled, saveApartment.fulfilled, deleteApartment.fulfilled),
      (state) => {
        state.pending = false;
        state.isSuccess = true;
      },
    );
  },
});

export const selectApartments = (store: AppState) => store.apartmentReducer.apartments;
export const selectApartmentById = (id: number | undefined) =>
  createSelector(selectApartments, (apartments) => {
    return apartments.find((ap) => ap.id === id);
  });

export const selectApartmentsByLocationId = (id: number | undefined) =>
  createSelector(selectApartments, (apartments) => {
    return apartments.filter((ap) => ap.locationId === id);
  });

// eslint-disable-next-line
export const {} = apartmentSlice.actions;

export default apartmentSlice.reducer;
