import {
  UserClient,
  OrganizationClient,
  LocationClient,
  OrganizationUserClient,
  NotificationSettingClient,
  ApartmentClient,
  ThermexUnitClient,
  AlarmClient,
} from '../api/api';
import {ApiBaseUrl} from '../utilities/constants';

export const userClient = new UserClient(ApiBaseUrl);
export const organizationClient = new OrganizationClient(ApiBaseUrl);
export const locationClient = new LocationClient(ApiBaseUrl);
export const organizationUserClient = new OrganizationUserClient(ApiBaseUrl);
export const apartmentClient = new ApartmentClient(ApiBaseUrl);
export const thermexUnitClient = new ThermexUnitClient(ApiBaseUrl);
export const notificationSettingClient = new NotificationSettingClient(ApiBaseUrl);
export const alarmClient = new AlarmClient(ApiBaseUrl);
export type ApiClient =
  | UserClient
  | OrganizationClient
  | LocationClient
  | OrganizationUserClient
  | ApartmentClient
  | ThermexUnitClient
  | NotificationSettingClient
  | AlarmClient;
