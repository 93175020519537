import {Typography} from '@mui/material';
import React, {FC} from 'react';
import {theme} from '../../../../styles/theme';
import {FlexColumn} from '../flex-column';

interface PropsFromParent {
  title: string;
  subTitle?: string;
}

export const SidePanelTitel: FC<PropsFromParent> = React.memo((props) => {
  return (
    <FlexColumn sx={{minWidth: 350}}>
      <Typography
        fontWeight="bold"
        lineHeight={1.25}
        fontSize="40px"
        color={theme.colors.sidePanel.sidePanelPrimaryText}
        component="div">
        {props.title}
      </Typography>
      <Typography variant="caption" color={theme.colors.sidePanel.sidePanelSecondaryText} component="div">
        {props.subTitle}
      </Typography>
    </FlexColumn>
  );
});
