import {Box} from '@mui/material';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch} from 'react-router-dom';
import {selectNotificationSettingByRoomType} from 'src/state/features/notification-setting/notification-setting-slice';
import {getNotificationSettings} from 'src/state/features/notification-setting/operation';
import {DropdownOption} from 'src/view/components/default-components/baisc-dropdown/basic-search-dropdown';
import {StyleableDropdown} from 'src/view/components/default-components/baisc-dropdown/styleable-dropdown';
import {CreateLocationDTO, ICreateLocationDTO, RoomType} from '../../../../api/api';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {saveLocation} from '../../../../state/features/location/operation';
import {routes} from '../../../../state/routes';
import {AppState} from '../../../../state/store';
import {nameof} from '../../../../utilities/platform-helpers/nameof-helper';
import {requiredValidator} from '../../../../utilities/platform-helpers/validator-helper';
import {BasicButton} from '../../../components/default-components/buttons/basic-button';
import {FieldArrayIput} from '../../../components/default-components/form/field-array-input';
import {Form} from '../../../components/default-components/form/form';
import {TextInput} from '../../../components/default-components/form/text-input';
import {PageContentContainer} from '../../../components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../components/default-components/page-header.tsx/page-header';
import {PageHeaderContent} from '../../../components/default-components/page-header.tsx/page-header-content';
import {PageTitle} from '../../../components/default-components/page-title/page-title';

interface PropsFromParent {}

export const LocationForm: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const match = useMatch(routes.organizationEdit);
  //Selector
  const selectedOrganization = useSelector((store: AppState) => store.settingsReducer.selectedOrganization);
  const notificationSettings = useSelector(selectNotificationSettingByRoomType(RoomType.Standard));

  //State
  const [nsId, setNsId] = useState<number | undefined>();

  //Memo
  const settingOptions = useMemo(() => {
    const options = notificationSettings
      ? notificationSettings.map((ns) => new DropdownOption(ns.name ?? '', ns.id))
      : [];
    if (!nsId && options.length > 0) {
      setNsId(options[0].value);
    }
    return options;
  }, [notificationSettings]);

  //Effect
  useEffect(() => {
    if (selectedOrganization) {
      dispatch(getNotificationSettings(selectedOrganization.id));
    }
  }, [dispatch, selectedOrganization]);

  //Functions
  const save = useCallback(
    (location: CreateLocationDTO) => {
      if (selectedOrganization?.id) {
        location.organizationId = selectedOrganization.id;
        location.apartments?.forEach((a) => {
          a.notificationSettingId = nsId;
        });
        dispatch(saveLocation({location}));
      }
    },
    [dispatch, selectedOrganization, nsId],
  );

  const onNsChange = (option: DropdownOption) => {
    setNsId(option.value);
  };

  return (
    <PageTitle
      title={localizedInterpolation(match !== null ? 'SaveInt' : 'CreateNewInt', {
        area: localized('Location'),
      })}>
      <PageHeader
        area="locationCreate"
        title={localizedInterpolation(match !== null ? 'SaveInt' : 'CreateNewInt', {
          area: localized('Location'),
        })}>
        <PageHeaderContent></PageHeaderContent>
      </PageHeader>
      <PageContentContainer>
        <Box sx={{paddingLeft: 6, paddingRight: 6}}>
          <StyleableDropdown
            value={nsId}
            label={localized('NotificationProfile')}
            options={settingOptions}
            onChange={onNsChange}
          />
          <Form onSubmit={save} sx={{maxWidth: 400}} defaultValues={{} as ICreateLocationDTO}>
            <TextInput
              name={nameof<ICreateLocationDTO>('name')}
              label={localized('Name')}
              validators={requiredValidator}
            />
            <TextInput
              name={nameof<ICreateLocationDTO>('address')}
              label={localized('Address')}
              validators={requiredValidator}
            />
            <TextInput
              name={nameof<ICreateLocationDTO>('city')}
              label={localized('City')}
              validators={requiredValidator}
            />
            <TextInput
              name={nameof<ICreateLocationDTO>('country')}
              label={localized('Country')}
              validators={requiredValidator}
            />
            <TextInput
              name={nameof<ICreateLocationDTO>('postalCode')}
              label={localized('PostalCode')}
              validators={requiredValidator}
            />
            <FieldArrayIput
              name={nameof<ICreateLocationDTO>('apartments')}
              label={localized('Apartment')}
              nestedName={nameof<ICreateLocationDTO>('name')}
              buttonText={'+ ' + localized('Apartment')}
            />
            <BasicButton
              type="submit"
              buttonColor={'primary'}
              // disabled={savePending}
              text={localizedInterpolation(match !== null ? 'SaveInt' : 'CreateNewInt', {
                area: localized('Location'),
              })}
            />
          </Form>
        </Box>
      </PageContentContainer>
    </PageTitle>
  );
});
