import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ApartmentDTO, IApartmentDTO} from 'src/api/api';
import {localized} from 'src/i18n/i18n';
import {selectApartmentById} from 'src/state/features/apartment/apartment-slice';
import {saveApartment, updateApartment} from 'src/state/features/apartment/operation';
import {
  selectNotificationSetting,
  selectNotificationSettingByRoomType,
} from 'src/state/features/notification-setting/notification-setting-slice';
import {setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {AppState} from 'src/state/store';
import {theme} from 'src/styles/theme';
import {nameof} from 'src/utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'src/utilities/platform-helpers/validator-helper';
import {DropdownOption} from 'src/view/components/default-components/baisc-dropdown/basic-search-dropdown';
import {BasicButton} from 'src/view/components/default-components/buttons/basic-button';
import {Form} from 'src/view/components/default-components/form/form';
import { SidePanelButton } from 'src/view/components/default-components/side-panel/side-panel-button';
import {SidePanelSelectInput} from 'src/view/components/default-components/side-panel/side-panel-select-input';
import {SidePanelTextInput} from 'src/view/components/default-components/side-panel/side-panel-text-input';
import {SidePanelTitel} from 'src/view/components/default-components/side-panel/side-panel-titel';

interface PropsFromParent {
  aparmentId?: number;
  locationId?: number;
}

export const ApartmentEdit: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const item = useSelector(selectApartmentById(props.aparmentId));
  const savePending = useSelector((store: AppState) => store.apartmentReducer.pending);
  const notificationSettings = useSelector(selectNotificationSetting);
  const settingOptions = useMemo(() => {
    return notificationSettings ? notificationSettings.map((ns) => new DropdownOption(ns.name ?? '', ns.id)) : [];
  }, [notificationSettings]);
  const closeSidePanel = useCallback(() => {
    dispatch(setSidePanelOpen(false));
  }, [dispatch]);

  const save = useCallback(
    (apartment: ApartmentDTO) => {
      if (item) {
        dispatch(updateApartment({apartment, callbackOnSuccess: closeSidePanel}));
      } else {
        apartment.locationId = props.locationId!;
        dispatch(saveApartment({apartment, callbackOnSuccess: closeSidePanel}));
      }
    },
    [closeSidePanel, dispatch, item, props.locationId],
  );

  return (
    <>
      <SidePanelTitel
        title={item ? localized('Edit') : localized('CreateApartment')}
        subTitle={item ? '' : localized('CreateApartmentFormSubTitle')}
      />
      <Form onSubmit={save} sx={{maxWidth: 400, marginTop: theme.spacing(3)}} defaultValues={item}>
        <SidePanelTextInput
          name={nameof<IApartmentDTO>('name')}
          label={localized('Name')}
          validators={requiredValidator}
        />
        {!item && (
          <SidePanelSelectInput
            name={nameof<IApartmentDTO>('notificationSettingId')}
            noDefault
            options={settingOptions}
            validators={requiredValidator}
            label={localized('ChooseNotificationProfile')}
            sx={{width: 'unset'}}
          />
        )}

        <SidePanelButton
          type="submit"
          buttonColor={'primary'}
          disabled={savePending}
          text={localized('SaveAndClose')}
        />
      </Form>
    </>
  );
});
