import {Box, SxProps} from '@mui/material';
import React, {FC, useCallback} from 'react';
import {theme} from '../../../../../styles/theme';
import {VirtualizedListTable} from '../../../../components/default-components/virtual-list-table/virtual-list-table';
import {ApartmentListItem} from './apartment-list-item';

const columnSizes = [1, 2, 4, 1];

interface PropsFromParent {
  apartmentId: number | undefined;
  apartmentIds: number[];
}

export const ApartmentList: FC<PropsFromParent> = React.memo(({apartmentId, apartmentIds}) => {
  const renderUserItem = useCallback(
    ({key, index, style}: any) => {
      return (
        <Box key={key} style={style} sx={{paddingLeft: 6, paddingRight: 6}}>
          <ApartmentListItem
            isSelected={apartmentIds[index] === apartmentId}
            columnSizes={columnSizes}
            apartmentId={apartmentIds[index]}
          />
        </Box>
      );
    },
    [apartmentIds, apartmentId],
  );

  return (
    <Box sx={{marginTop: theme.spacing(3)} as SxProps}>
      <VirtualizedListTable items={apartmentIds} columnSizes={columnSizes} rowRenderer={renderUserItem} />
    </Box>
  );
});
