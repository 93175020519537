import {createAsyncThunk} from '@reduxjs/toolkit';
import {ApartmentClient, ApartmentDTO} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {apartmentClient} from '../../api-clients';

export const getApartments = createAsyncThunk('apartments/getApartments', (locationId: number) => {
  return AsyncOperationHandler((client) => (client as ApartmentClient).getByLocationId(locationId), apartmentClient);
});

export const updateApartment = createAsyncThunk(
  'apartments/updateApartment',
  (payload: {apartment: ApartmentDTO; callbackOnSuccess?: (result: ApartmentDTO) => void}) => {
    return AsyncOperationHandler((client) => (client as ApartmentClient).put(payload.apartment), apartmentClient, {
      showResponseMessage: true,
      callbackOnSuccess: payload.callbackOnSuccess,
    });
  },
);

export const saveApartment = createAsyncThunk(
  'apartments/saveApartment',
  (payload: {apartment: ApartmentDTO; callbackOnSuccess?: (result: ApartmentDTO) => void}) => {
    return AsyncOperationHandler((client) => (client as ApartmentClient).post(payload.apartment), apartmentClient, {
      showResponseMessage: true,
      callbackOnSuccess: payload.callbackOnSuccess,
    });
  },
);

export const deleteApartment = createAsyncThunk(
  'apartments/deleteApartment',
  (payload: {apartment: ApartmentDTO; callbackOnSuccess?: () => void}) => {
    return AsyncOperationHandler((client) => (client as ApartmentClient).delete(payload.apartment), apartmentClient, {
      showResponseMessage: true,
      callbackOnSuccess: payload.callbackOnSuccess,
    });
  },
);
