import {Typography} from '@mui/material';
import React, {FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectLocations} from 'src/state/features/location/location-slice';
import {applySidePanelContent, setSidePanelOpen} from 'src/state/features/webapp/webapp-slice';
import {theme} from 'src/styles/theme';
import {BasicSvgIcon} from 'src/view/components/default-components/basic-svg-icon';
import {FlexColumn} from 'src/view/components/default-components/flex-column';
import {StyledPopoverItem, TxStyledPopover} from 'src/view/components/popover/tx-styled-popover';
import {OrganizationDTO, OrganizationRole} from '../../../../api/api';
import {ReactComponent as MoreDetails} from '../../../../assets/more_details_dots.svg';
import {ReactComponent as SkyScraper} from '../../../../assets/skyscraper_logo.svg';
import {ReactComponent as UserLogo} from '../../../../assets/user_logo2.svg';
import {useAuth} from '../../../../auth/auth-provider';
import {localized, localizedInterpolation} from '../../../../i18n/i18n';
import {deleteOrganization} from '../../../../state/features/organization/operation';
import {selectOrganisationById} from '../../../../state/features/organization/organization-slice';
import {BasicIcon} from '../../../components/default-components/basic-icon';
import {ListTableItem} from '../../../components/default-components/list-table/list-table-item';
import {OrganizationEdit} from '../organization-edit/organization-edit';
import {ReactComponent as House} from '../../../../assets/house_icon.svg';

interface PropsFromParent {
  organizationId: number;
  columnSizes?: number[];
}

export const OrganizationListItem: FC<PropsFromParent> = React.memo(({organizationId, ...props}) => {
  const dispatch = useDispatch();
  const {isGlobalAdmin, authId} = useAuth();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorElRef = useRef<Element | null>();
  const organization = useSelector(selectOrganisationById(organizationId));
  const locations = useSelector(selectLocations);
  useEffect(() => {
    if (isGlobalAdmin) {
      setIsAdmin(true);
    } else if (organization && authId) {
      organization.organizationUser?.map((orgUser) => {
        if (
          orgUser.organizationId === organization.id &&
          orgUser.user?.authId === authId &&
          (orgUser.organizationRole === OrganizationRole.SuperUser ||
            orgUser.organizationRole === OrganizationRole.Admin)
        ) {
          setIsAdmin(true);
        }
      });
    }
  }, [organization, authId, isGlobalAdmin]);

  const editOrganization = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setIsPopoverOpen(false);
      anchorElRef.current = e.currentTarget;

      // Display the organization editor in a side bar
      dispatch(applySidePanelContent(<OrganizationEdit organizationId={organizationId} />));
      dispatch(setSidePanelOpen(true));
    },
    [dispatch, organizationId],
  );

  const deleteOrganizationPressed = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (
        (await confirm(
          localizedInterpolation('AreYouSureYouWantToDelete', {
            area: localized('TheOrganization').toLowerCase(),
          }),
        )) &&
        organization
      ) {
        setIsPopoverOpen(false);
        anchorElRef.current = e.currentTarget;
        dispatch(deleteOrganization(organization as OrganizationDTO));
      }
    },
    [dispatch, organization, organizationId],
  );

  const togglePopover = useCallback(
    (e: MouseEvent<SVGSVGElement>) => {
      e.stopPropagation();
      anchorElRef.current = e.currentTarget;

      setIsPopoverOpen(!isPopoverOpen);
    },
    [isPopoverOpen],
  );

  const popoverItems = useMemo(
    () => (
      <FlexColumn>
        <StyledPopoverItem onClick={editOrganization}>{localized('Edit')}</StyledPopoverItem>
        {isGlobalAdmin && (
          <StyledPopoverItem onClick={deleteOrganizationPressed}>{localized('Delete')}</StyledPopoverItem>
        )}
        {/* TODO: Remove until implementation */}
        {/* <StyledPopoverItem>{localized('LogRegistration')}</StyledPopoverItem>
        <StyledPopoverItem sx={{borderBottom: 0}}>{localized('SeeLogDetails')}</StyledPopoverItem> */}
      </FlexColumn>
    ),
    [deleteOrganizationPressed, editOrganization],
  );

  if (!organization) {
    return null;
  }

  return (
    <>
      <ListTableItem
        {...props}
        // itemSelected={goToLocationDetail}
        contentItem={organization}
        objectValues={[
          <BasicIcon key="index" icon="business" />,
          <Typography
            sx={{fontSize: theme.typography.body2, fontWeight: theme.typography.fontWeightBold, alignSelf: 'center'}}>
            {organization.name}
          </Typography>,
          <>
            <BasicSvgIcon
              sx={{marginRight: 2}}
              stroke={theme.colors.listTable.ListTableTextColor}
              component={UserLogo}
            />
            <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>
              {(organization.organizationUser ? organization.organizationUser.length : '0') + ' ' + localized('Users')}
            </Typography>
          </>,
          <>
            <BasicSvgIcon sx={{marginRight: 2}} stroke={''} component={SkyScraper} />
            <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>
              {organization.numberOfLocations + ' ' + localized('Locations')}
            </Typography>
          </>,
          <>
            <BasicSvgIcon sx={{marginRight: 2}} stroke={''} component={House} />
            <Typography sx={{fontSize: theme.typography.body2, alignSelf: 'center'}}>
              {organization.numberOfUnits + ' ' + localized('Units')}
            </Typography>
          </>,
          <BasicSvgIcon stroke={''} component={MoreDetails} onClick={togglePopover} />,
        ]}
      />
      <TxStyledPopover popoverStatus={isPopoverOpen} anchorEl={anchorElRef} togglePopover={togglePopover}>
        {popoverItems}
      </TxStyledPopover>
    </>
  );
});
