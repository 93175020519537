import {Typography} from '@mui/material';
import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {INotificationRangeDTO, MeasureType, NotificationRangeDTO} from 'src/api/api';
import {localized, localizedDynamic, localizedInterpolation} from 'src/i18n/i18n';
import {deleteNotificationRange, updateNotificationRange} from 'src/state/features/notification-setting/operation';
import {whiteColor} from 'src/styles/color-constants';
import {getLocalizedMeasureUnitType} from 'src/utilities/enums/enum-converter';
import {getNotificationIntervalOptions} from 'src/utilities/platform-helpers/enum-helper';
import {nameof} from 'src/utilities/platform-helpers/nameof-helper';
import {requiredValidator} from 'src/utilities/platform-helpers/validator-helper';
import {BasicIcon} from 'src/view/components/default-components/basic-icon';
import {BasicSvgIcon} from 'src/view/components/default-components/basic-svg-icon';
import {Form} from 'src/view/components/default-components/form/form';
import {IntegerTextInput} from 'src/view/components/default-components/form/integer-text-input';
import {TxSelectInput} from 'src/view/components/default-components/form/tx-select-input';
import {ReactComponent as MoreDetails} from '../../../../assets/more_details_dots.svg';

interface PropsFromParent {
  notificationRange: INotificationRangeDTO | undefined;
}
const inputContainer = {width: '150px', padding: 1.5, boxShadow: '0px 3px 15px #0000001A', backgroundColor: whiteColor};

export const NotificationRangeSetup: FC<PropsFromParent> = React.memo((props) => {
  const dispatch = useDispatch();
  const save = useCallback(
    (notificationRange: NotificationRangeDTO) => {
      dispatch(updateNotificationRange({notificationRange}));
    },
    [dispatch],
  );

  const deleteNotificationRangePresssed = async () => {
    if (
      await confirm(
        localizedInterpolation('AreYouSureYouWantToDelete', {
          area: localized('TheNotificationProfile').toLowerCase(),
        }),
      )
    ) {
      dispatch(deleteNotificationRange({notificationRange: props.notificationRange as NotificationRangeDTO}));
    }
  };

  return (
    <Form
      onSubmitOnChange={true}
      onSubmit={save}
      defaultValues={props.notificationRange}
      sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
      <Typography sx={{flex: 2, maxWidth: 200, fontSize: 16, fontWeight: 'bold', minWidth: 120}}>
        {props.notificationRange?.measureType !== undefined &&
          localizedDynamic(MeasureType[props.notificationRange?.measureType])}
      </Typography>
      <Typography sx={{textAlign: 'end', maxWidth: 150, flex: 2, fontSize: 14, minWidth: 100}}>
        {localized('notifyIf')}
      </Typography>
      <Typography sx={{textAlign: 'end', maxWidth: 100, flex: 1, fontSize: 16, fontWeight: 'bold'}}>
        {localized('under')}
      </Typography>
      <IntegerTextInput sxContainer={inputContainer} name={nameof<INotificationRangeDTO>('under')} />
      <Typography sx={{textAlign: 'end', maxWidth: 100, flex: 1, fontSize: 16, fontWeight: 'bold'}}>
        {localized('over')}
      </Typography>
      <IntegerTextInput sxContainer={inputContainer} name={nameof<INotificationRangeDTO>('over')} />
      <Typography sx={{textAlign: 'start', maxWidth: 100, flex: 1, fontSize: 16, fontWeight: 'bold'}}>
        {props.notificationRange?.measureType !== undefined &&
          getLocalizedMeasureUnitType(props.notificationRange?.measureType)}
      </Typography>
      <Typography sx={{textAlign: 'end', maxWidth: 50, flex: 1, fontSize: 16, fontWeight: 'bold'}}>i</Typography>
      <IntegerTextInput sxContainer={inputContainer} name={nameof<INotificationRangeDTO>('intervalValue')} />
      <TxSelectInput
        sxContainer={inputContainer}
        name={nameof<INotificationRangeDTO>('notificationInterval')}
        initValue={props.notificationRange?.notificationInterval}
        options={getNotificationIntervalOptions()}
        validators={requiredValidator}
      />
      <BasicIcon onClick={deleteNotificationRangePresssed} icon="delete" title={localized('Delete')} />
    </Form>
  );
});
