import {Action, configureStore, Reducer, ThunkAction} from '@reduxjs/toolkit';
import {PersistConfig, persistReducer} from 'redux-persist';
import {PersistPartial} from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import authReducerBase from './features/auth/auth-slice';
import locationReducer from './features/location/location-slice';
import locationOrganizationUserReducer from './features/location-organization-user/location-organization-user-slice';
import notificationSettingReducer from './features/notification-setting/notification-setting-slice';
import organizationReducer from './features/organization/organization-slice';
import pageSettingReducerBase from './features/page-header-setting/page-header-setting-slice';
import settingsReducerBase from './features/settings/settings-slice';
import snackbarReducer from './features/snackbar/snackbar-slice';
import usersReducer from './features/users/users-slice';
import webAppReducerBase from './features/webapp/webapp-slice';
import apartmentReducer from './features/apartment/apartment-slice';
import thermexUnitReducer from './features/thermex-unit/thermex-unit-slice';
import alarmsReducer from './features/alarms/alarms-slice';
import {setStore} from './store-container';
export type AppState = ReturnType<typeof store.getState>;

const createPersistReducer = <T extends Reducer<any, Action<any>>>(config: PersistConfig<any>, reducer: T) => {
  return persistReducer(config, reducer) as typeof reducer & PersistPartial;
};

// Create persisted reducers
const webappPersistConfig: PersistConfig<any> = {
  key: 'webapp',
  storage,
  whitelist: ['open'],
  blacklist: ['showOverlaySpinner'],
};
const webAppReducer = createPersistReducer(webappPersistConfig, webAppReducerBase);

const settingsPersistConfig: PersistConfig<any> = {
  key: 'settingsReducer',
  storage,
  whitelist: ['selectedOrganization'],
};
const settingsReducer = createPersistReducer(settingsPersistConfig, settingsReducerBase);

const pageSettingPersistConfig: PersistConfig<any> = {
  key: 'pageSettingReducer',
  storage,
  whitelist: ['pageSettings'],
};

const pageSettingReducer = createPersistReducer(pageSettingPersistConfig, pageSettingReducerBase);

const authPersistConfig: PersistConfig<any> = {
  key: 'authReducer',
  storage,
  whitelist: ['token'],
};
const authReducer = createPersistReducer(authPersistConfig, authReducerBase);

export const store = configureStore({
  reducer: {
    snackbarReducer,
    authReducer,
    settingsReducer,
    webAppReducer,
    pageSettingReducer,
    usersReducer,
    organizationReducer,
    locationOrganizationUserReducer,
    locationReducer,
    notificationSettingReducer,
    apartmentReducer,
    thermexUnitReducer,
    alarmsReducer,
  },
  // Middleware declard here now as old method was deprecated. Auth interceptor no longer used as functionality is handled elsewere.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Needed as Date object is considered un-serializable by redux
      serializableCheck: false,
    }),
});
setStore(store);
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;
